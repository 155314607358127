@import '../../../scss/global/utils/mixins';
@import '../../../scss/global/abstracts/scss-variables';

.paragraph {
  @each $var in $typography-sizes {
    &--#{$var} {
      @include paragraphStyles($var);
    }
  }

  &--no-margin {
    margin: 0;
  }

  &--highlight {
    font-weight: var(--paragraph-highlight-font-weight);
  }
}
