:root {
  // Floating Labels
  --label-floating-color: var(--body-40);
  --label-floating-dark-color: var(--dark-blue-50);
  --label-floating-color-input-focused: var(--body-100);
  --label-floating-dark-color-input-focused: var(--white);
  --label-floating-padding: 0;
  --label-floating-font-family: inherit;
  --label-floating-font-weight: 500;
  --label-floating-font-size: var(--font-size-14);
  --label-floating-line-height: var(--font-size-20);
  --label-floating-top: 0.875rem;
  --label-floating-left: calc(var(--input-padding-left) + var(--input-border-width));
  --label-floating-right: calc(var(--input-padding-right) + var(--input-border-width));
  --label-floating-disabled-opacity: var(--disabled-opacity);
  --label-floating-dark-disabled-opacity: var(--disabled-opacity);
  // Styles applied when label is floated to top of input
  --label-floating-active-font-size: var(--font-size-10);
  --label-floating-active-padding: 0.125rem var(--size-4);
  --label-floating-active-bg-color: var(--white);
  --label-floating-dark-active-bg-color: var(--input-dark-bg-color);
  --label-floating-active-left: var(--size-8);
  --label-floating-active-line-height: var(--font-size-14);
}

.label--floating {
  font-family: var(--label-floating-font-family);
  font-size: var(--label-floating-font-size);
  line-height: var(--label-floating-line-height);
  font-weight: var(--label-floating-font-weight);
  position: absolute;
  pointer-events: none;
  left: var(--label-floating-left);
  top: var(--label-floating-top);
  transition: all 0.3s ease;
  transition-property: left, top, right, font-size, transform, padding, background-color, line-height;
  background-color: transparent;
  z-index: var(--z-index-input-floating-label);
  padding: var(--label-floating-padding);
  color: var(--label-floating-color);
  white-space: nowrap;
  overflow: hidden;

  &--hidden {
    display: none;
  }

  &.label--floating--input-has-left-icon:not(.label--floating--top) {
    left: calc(var(--label-floating-left) + var(--input-left-icon-size) + var(--input-icon-gap));
  }
  &.label--floating--input-has-right-icon:not(.label--floating--top) {
    right: calc(var(--label-floating-right) + var(--input-right-icon-size) + var(--input-icon-gap));
  }

  &.label--floating--input-has-right-icons:not(.label--floating--top) {
    right: calc(
      var(--label-floating-right) + var(--input-right-icon-size) + calc(var(--input-icon-gap) * 2) +
        var(--input-feedback-icon-size)
    );
  }

  &--top {
    font-size: var(--label-floating-active-font-size);
    font-weight: var(--label-floating-active-font-weight);
    top: 0;
    left: var(--label-floating-active-left);
    transform: translateY(-50%);
    line-height: var(--label-floating-active-line-height);
    padding: var(--label-floating-active-padding);
    background-color: var(--label-floating-active-bg-color);
  }

  &.label--floating--input-focused:not(.label--floating--disabled) {
    color: var(--label-floating-color-input-focused);
  }

  &.label--floating--disabled {
    .label__text {
      opacity: var(--label-floating-disabled-opacity);
    }
  }
  &--dark {
    color: var(--label-floating-dark-color);

    &.label--floating--input-focused:not(.label--floating--disabled) {
      color: var(--label-floating-dark-color-input-focused);
    }

    &.label--floating--top {
      background-color: var(--label-floating-dark-active-bg-color);
    }

    &.label--floating--disabled {
      .label__text {
        opacity: var(--label-floating-dark-disabled-opacity);
      }
    }
  }
}
