@import '../../../../scss/global/utils/mixins';

.promotion-progress-bar {
  cursor: pointer;
  padding: var(--size-12);
  padding-left: var(--size-16);
  margin-bottom: var(--size-8);
  border-radius: var(--size-8);
  background-color: var(--white);
  display: flex;
  justify-content: space-between;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  &__summary {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;

    &-heading {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    &-info {
      display: flex;
      flex-direction: column;
    }

    &-title {
      text-align: left;
      font-weight: var(--paragraph-highlight-font-weight);
      font-size: var(--size-16);
      line-height: var(--size-20);
      color: var(--body-100);
    }

    &-amount {
      font-weight: var(--font-weight-regular);
      font-size: var(--size-14);
      line-height: var(--size-24);
      text-align: right;
      color: var(--body-100);
      display: flex;
      align-self: center;
    }

    &-expire {
      text-align: left;
      font-weight: var(--paragraph-font-weight);
      font-size: var(--size-10);
      line-height: var(--size-14);
      color: var(--body-100);
      padding-top: var(--size-4);
    }

    &-expand {
      padding-left: var(--size-8);
      color: var(--body-100);
      z-index: 1;
      font-weight: var(--typography-highlight-font-weight);
      font-size: var(--size-10);
      line-height: var(--size-14);
      display: flex;
      margin: auto 0;
      align-items: flex-end;
      justify-content: flex-end;

      svg {
        width: var(--size-12);
        height: var(--size-12);
      }
    }

    &-subheading {
      text-align: right;
      font-weight: var(--paragraph-font-weight);
      font-size: var(--size-10);
      line-height: var(--size-14);
      color: var(--body-60);
      padding-top: var(--size-4);
    }

    &-amount-wager {
      font-weight: var(--paragraph-highlight-font-weight);
      font-size: var(--size-14);
      line-height: var(--size-20);
      color: var(--body-100);
      padding-left: var(--size-8);
    }
  }
}

.modal-body {
  .promotion-progress-bar {
    &__summary {
      margin-bottom: var(--size-16);
    }
  }
}
