:root {
  // Body
  --body-1: #fafafa;
  --body-5: #f4f4f4;
  --body-10: #e9e9e9;
  --body-15: #f4f4de;
  --body-20: #d3d3d3;
  --body-30: #bcbcbc;
  --body-35: #acac9a;
  --body-40: #a6a6a6;
  --body-50: #909090;
  --body-60: #7a7a7a;
  --body-70: #646464;
  --body-75: #696960;
  --body-80: #4d4d4d;
  --body-90: #373737;
  --body-95: #212121;
  --body-100: #212121;

  // Dark Blue
  --dark-blue-100: #1d1d1b;
  --dark-blue-100-rgb: 0, 10, 45;
  --dark-blue-75: #111111cc;
  --dark-blue-50: #303030;
  --dark-blue-25: #bfc2cb;

  // Blue shades
  --blue-100: #132d69;
  --blue-75: #4e628f;
  --blue-60: #387eea;
  --blue-50: #8996b4;
  --blue-25: #c4cbd9;
  --light-blue: #7581a2;
  --medium-blue: #52618b;

  // Brown shades
  --soft-brown: #c9b4a8;

  // Cyan
  --cyan-100: #0a7484;

  // Yellow shades
  --yellow-100: #ffd130;
  --yellow-75: #ffdc64;
  --yellow-50: #ffe897;
  --yellow-25: #fff3cb;
  --dark-yellow: #ecbc16;
  --medium-yellow: #ffda2d;

  // Orange shaes
  --orange-200: #f65130;
  --orange-100: #fe8a2d;
  --orange-75: #fea762;
  --orange-65: #e49e74;
  --orange-50: #ffc496;
  --orange-25: #ffe2cb;

  // "System colours"
  --success-light: #b9f3c8;
  --success: #62e685;
  --success-dark: #23b34a;
  --info: var(--cyan-100);
  --error: var(--orange-200);
  --warning: #f1ba4e;
  --overlay: #000a2d;

  // BG Gradient
  --gradient-cold: linear-gradient(109.8deg, #1d1d1b 0%, #000a2d 100%);
  --gradient-warm: linear-gradient(109.8deg, #ffd130 0%, #fe8a2d 100%);

  // Backdrop color used for Overlays & TouchDrawer etc.
  --backdrop-bg-color: rgba(0, 14, 46, 0.75);
  --background-account-wrapper: #494944;

  // Primary
  --primary-050: #e0e8f9;
  --primary-100: #bed0f7;
  --primary-200: #98aeeb;
  --primary-300: #7b93db;
  --primary-400: #647acb;
  --primary-500: #696960;
  --primary-600: #8e8e7e;
  --primary-700: #696960;
  --primary-800: #494944;
  --primary-900: #121212;

  // Accent
  --accent-050: #e1fcf8;
  --accent-100: #c1fef6;
  --accent-200: #92fdf2;
  --accent-300: #62f4eb;
  --accent-400: #3ae7e1;
  --accent-500: #1cd4d4;
  --accent-600: #0fb5ba;
  --accent-700: #099aa4;
  --accent-800: #07818f;
  --accent-900: #05606e;

  // White
  --white: #fff;

  // Black
  --black: #000;

  // Greys
  --grey-010: var(--body-1);
  --grey-050: var(--body-5);
  --grey-100: var(--body-10);
  --grey-200: var(--body-20);
  --grey-300: var(--body-30);
  --grey-400: var(--body-40);
  --grey-500: var(--body-50);
  --grey-600: var(--body-60);
  --grey-700: var(--body-70);
  --grey-800: var(--body-80);
  --grey-900: var(--body-90);
  --grey-1000: var(--body-100);

  // State - Success
  --success-050: var(--success);
  --success-100: var(--success);
  --success-200: var(--success);
  --success-300: var(--success);
  --success-400: var(--success);
  --success-500: var(--success);
  --success-600: var(--success);
  --success-700: var(--success);
  --success-800: var(--success);
  --success-900: var(--success);
  // Main success color
  --success-color: var(--success-600);

  // State - Warning
  --warning-050: #fffbea;
  --warning-100: #fff3c4;
  --warning-200: #fce588;
  --warning-300: #fadb5f;
  --warning-400: #f7c948;
  --warning-500: #f0b429;
  --warning-600: #de911d;
  --warning-700: #cb6e17;
  --warning-800: #b44d12;
  --warning-900: #8d2b0b;

  // Main warning color
  --warning-color: var(--warning-500);

  // State - Danger
  --danger-050: #ffe3e3;
  --danger-100: #ffbdbd;
  --danger-200: #ff9b9b;
  --danger-300: #f86a6a;
  --danger-400: #ef4e4e;
  --danger-500: #e12d39;
  --danger-600: #cf1124;
  --danger-700: #ab091e;
  --danger-800: #8a041a;
  --danger-900: #610316;
  --danger-1000: #f65130;

  // Main danger color
  --danger-color: var(--danger-500);

  // Background color for Promo Banner
  --promo-background: var(--gradient-cold);

  //Safer Gambling Tools
  --inner-bar: #c4c4c4;

  // Navigation
  --primary-nav-bg: #b06538;
  --nav-link-hover: #97532a;
  --sub-nav-bg: #494944;
  --sub-nav-pills-color: #b9b9a8;
  --sub-nav-pills-hover: #acac9a;
}
