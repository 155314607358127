:root {
  // Primary
  --primary-050: #e0e8f9;
  --primary-100: #bed0f7;
  --primary-200: #98aeeb;
  --primary-300: #7b93db;
  --primary-400: #647acb;
  --primary-500: #4c63b6;
  --primary-600: #4055a8;
  --primary-700: #35469c;
  --primary-800: #2d3a8c;
  --primary-900: #19216c;

  // Dark Blue
  --dark-blue-100: #000a2d;
  --dark-blue-100-rgb: 0, 10, 45;
  --dark-blue-75: #404762;
  --dark-blue-50: #808496;
  --dark-blue-25: #bfc2cb;
  --dark-blue-15: #2eb4ff;

  // Space Blue
  --space-blue-200: #000E2E;
  --space-blue-100: #000f32;
  --space-blue-transparent: rgba(0,15,50,0.75);

  // Energy Blue
  --energy-blue-400: #385fb1;

  // Green
  --light-green: #89F468;

  // Accent
  --accent-050: #e1fcf8;
  --accent-100: #c1fef6;
  --accent-200: #92fdf2;
  --accent-300: #62f4eb;
  --accent-400: #3ae7e1;
  --accent-500: #1cd4d4;
  --accent-600: #0fb5ba;
  --accent-700: #099aa4;
  --accent-800: #07818f;
  --accent-900: #05606e;

  // White
  --white: #fff;

  // Greys
  --grey-050: #f5f7fa;
  --grey-100: #e4e7eb;
  --grey-150: #ece9e9;
  --grey-200: #cbd2d9;
  --grey-300: #9aa5b1;
  --grey-400: #7b8794;
  --grey-500: #616e7c;
  --grey-600: #52606d;
  --grey-700: #3e4c59;
  --grey-800: #323f4b;
  --grey-900: #1f2933;

  // State - Success
  --success-050: #e3f9e5;
  --success-100: #c1f2c7;
  --success-200: #91e697;
  --success-300: #51ca58;
  --success-400: #31b237;
  --success-500: #18981d;
  --success-600: #0f8613;
  --success-700: #0e7817;
  --success-800: #07600e;
  --success-900: #014807;
  // Main success color
  --success-color: var(--success-600);

  // Orange
  --orange-100: #fe8a2d;

  // Beige
  --beige-15: #ffcdaf;

  // State - Warning
  --warning-050: #fffbea;
  --warning-100: #fff3c4;
  --warning-200: #fce588;
  --warning-300: #fadb5f;
  --warning-400: #f7c948;
  --warning-500: #f0b429;
  --warning-600: #de911d;
  --warning-700: #cb6e17;
  --warning-800: #b44d12;
  --warning-900: #8d2b0b;
  // Main warning color
  --warning-color: var(--warning-500);

  // State - Danger
  --danger-050: #ffe3e3;
  --danger-100: #ffbdbd;
  --danger-200: #ff9b9b;
  --danger-300: #f86a6a;
  --danger-400: #ef4e4e;
  --danger-500: #e12d39;
  --danger-600: #cf1124;
  --danger-700: #ab091e;
  --danger-800: #8a041a;
  --danger-900: #610316;
  // Main danger color
  --danger-color: var(--danger-500);

  // Nebular Palette
  --nebular-50: #f0d7ff;

  // Navigation
  --primary-nav-bg: #B06538;
  --nav-link-hover: #97532A;
  --sub-nav-bg: #494944;
  --sub-nav-pills-color: #B9B9A8;
  --sub-nav-pills-hover: #ACAC9A;
  --background-account-wrapper: #494944;

  // System colors 
  --info: #1bc6df;
}
