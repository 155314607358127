@import '../../../../scss/global/utils/mixins';

:root {
  // Grid & layout
  --balance-drawer-grid-column-phone: 1/5;
  --balance-drawer-grid-column-tab-port: 5/9;
  --balance-drawer-grid-column-desktop: 9/13;
  --balance-drawer-grid-column-extra-big-desktop: 18/25;
  --balance-drawer-padding-phone: 0;
  --balance-drawer-padding-tab-port: 0 var(--size-24);
  --balance-drawer-padding-desktop: 0 var(--size-32);
  --balance-drawer-padding-extra-big-desktop: 0 var(--size-56);
}

.balance-drawer__backdrop {
  --touchdrawer-backdrop-z-index: 99999999;
  top: 0;
  height: 100%;
  margin-top: var(--header-height);
  cursor: default;
  background-color: var(--space-blue-transparent);
  backdrop-filter: none;

  @include respond(phone) {
    margin-top: var(--header-mobile-height);
  }
  
  .touch-drawer {
    &__viewport,
    &__content {
      background: var(--primary-900);
    }

    &__bars {
      top: 70%;
      background-color: var(--beige-15);
    }

    &__touchpad {
      opacity: 1;
    }

    @include respond(phone) {
      &__viewport {
        top: 0;
      }
    }

    @include respond(tab-port) {
      &__viewport {
        top: 0;
      }
    }
  }

  .touch-drawer__container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: var(--grid-tab-port-gutter);
    align-self: flex-end;

    @each $size in 'phone', 'tab-port', 'desktop', 'extra-big-desktop' {
      @include respond($size) {
        grid-template-columns: repeat(var(--grid-#{$size}-columns), 1fr);
        gap: var(--grid-#{$size}-gutter);
        padding: var(--balance-drawer-padding-#{$size});
        .touch-drawer__viewport {
          grid-column: var(--balance-drawer-grid-column-#{$size});
          max-width: unset;
          left: 0;
        }
      }
    }
  }

  .touch-drawer__content {
    padding: 0;
    overflow: hidden;
  }
}

.balance-drawer__notice-setting {
  .touch-drawer {
    @include respond(tab-port) {
      &__viewport {
        top: calc(var(--header-height) + var(--size-45));
      }
    }
    @include respond(phone) {
      &__viewport {
        top: calc(var(--header-mobile-height) + var(--size-32));
      }
    }
  }
}
