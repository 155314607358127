@import '../../../../scss/global/utils/mixins';

:root {
  --menu-border: 1px solid var(--body-10);
  --menu-radius: 0.125rem;
}

.address-search {
  &__selected-address {
    background-color: var(--body-5);
    padding: var(--size-16);
    margin-bottom: var(--size-24);
  }

  &__address {
    background-repeat: no-repeat;
    background-position: right;
  }

  .heading--6 {
    margin-top: 0;
  }

  &__city,
  &__county {
    display: block;
  }

  &__postCode,
  &__county {
    color: var(--body-60);
  }

  &__selected-address-actions {
    text-align: right;
    padding-top: var(--size-16);

    .button {
      color: var(--body-60);

      &:first-child {
        margin-right: var(--size-20);
      }
    }
  }

  .input__icon--right {
    z-index: calc(var(--z-index-input-box) + 1);
  }

  .input--has-right-icon {
    .button {
      text-decoration: underline;
      color: var(--body-60);
    }

    .input__box {
      padding-right: var(--size-96);
    }
  }

  .menu--open {
    margin-top: var(--size-8);

    .menu-item {
      position: relative;
      padding: var(--size-16);

      &::before {
        height: 1px;
        position: absolute;
        top: 0;
        width: auto;
        left: var(--size-16);
        background-color: var(--body-10);
        right: var(--size-16);
      }

      &:first-child {
        &::before {
          height: 0;
        }
      }
    }

    max-height: calc(100vh - var(--header-mobile-height) - 13rem);

    @include respond(tab-port) {
      max-height: calc(100vh - var(--header-height) - 12rem);
    }

    @include respond(desktop) {
      max-height: calc(100vh - 3.75rem - 15rem);
    }
  }
}
