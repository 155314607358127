@import '../../../../scss/global/utils/mixins';

$games-list: 'games-list';

.#{$games-list} {
  &__jackpot-banner {
    &.#{$games-list}__jackpot-banner--dots {
      padding: var(--size-8) 0;

      @include respond(phone) {
        padding: 0;
        max-width: 100%
      }
    }
  }

  .list-component {
    @include respond(phone) {
      margin-left: 0;
    }
  }
}
