@import '../../../../scss/global/utils/mixins';

:root {
  // container
  --game-card-basic-background: var(--white);
  // sizes
  --game-card-basic-box-height: 18.75rem;
  --game-card-basic-tall-height: 25rem;
  --game-card-basic-wide-height: 12.5rem;
  // title
  --game-card-basic-title-font-weight: 700;
  --game-card-basic-title-padding: var(--size-16);
  --game-card-basic-title-font-size: var(--font-size-20);
  --game-card-basic-title-color: var(--white);
}

.game-card-basic {
  &__inner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &__container {
    z-index: var(--game-card-z-index);
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;

    &--box {
      padding-top: 100%; /* 1:1 Aspect Ratio */
    }
    &--tall {
      padding-top: 150%; /* 2:3 Aspect Ratio */
    }
    &--wide {
      padding-top: 66.666%; /* 3:2 Aspect Ratio */
    }
    &--button {
      cursor: pointer;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &--disabled {
      opacity: 0.7;
    }

    &--skeleton {
      @include skeleton;
    }
  }

  &__gradient {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    opacity: 0.5;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 50%
    );
  }

  &__title {
    display: none;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    color: var(--game-card-basic-title-color) !important;
    font-size: var(--game-card-basic-title-font-size);
    font-weight: var(--game-card-basic-title-font-weight);
    padding: var(--game-card-basic-title-padding);
  }
}

.zoomed-game-card {
  .game-card-basic {
    &__image {
      &--disabled {
        opacity: 1;
      }
    }
  }
}
