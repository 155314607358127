:root {
  --accordion-content-padding: var(--accordion-padding);
  --accordion-content-items-margin: var(--accordion-margin);
}
.accordion-content {
  padding: var(--accordion-content-padding);

  > * {
    display: inline-block;
    vertical-align: middle;
    margin: var(--accordion-content-items-margin);
  }

  img {
    max-height: 50vh;
  }
}
