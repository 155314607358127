.account-limits-item-pending-limit {
  display: flex;
  flex-direction: column;

  .badge {
    margin-right: var(--size-8);
  }

  hr {
    border-width: 0;
    margin-bottom: var(--size-20);
    border-top: 0.5px solid var(--body-10);
  }

  &__content {
    display: flex;
    align-items: center;
    padding: 0 var(--size-16) var(--size-20);
  }

  &__icon {
    display: inherit;
    margin-right: var(--size-8);
    color: var(--account-limits-pending-info-color);
    svg {
      height: var(--size-16);
      width: var(--size-16);
    }
  }

  &__value {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: var(--size-8);
    font-size: var(--font-size-12);

    .badge:not(.cancellation-badge) {
      margin-left: var(--size-8);
    }
  }

  &__update {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;

    &-button {
      text-decoration: underline;
    }
  }

  &__pending-value {
    font-weight: var(--text-highlight-weight);
  }

  &__cancellation {
    margin-right: var(--size-8);
  }
}
