// Button styles are stored here in order to share them between the Link component and the Button component
:root {
  // General button styles
  --btn-text-casing: none;
  --btn-font-weight: bold;
  --btn-font-size: var(--font-size-16);
  --btn-font-family: var(--body-font-family);
  --btn-focus-outline-width: var(--focus-outline-width);
  --btn-focus-outline-offset: var(--focus-outline-offset);
  --btn-line-height: 1.5;
  --btn-auto-width: auto;
  --btn-padding: var(--size-8) var(--size-12);
  --btn-border-width: 2px;
  --btn-border-radius: 10px;
  --btn-font-icon-size: var(--font-size-14);
  --btn-icon-margin: 5px;

  // Variables which are configurable by size
  --btn-small-width: auto;
  --btn-medium-width: var(--size-300);
  --btn-large-width: var(--size-300);
  --btn-x-large-width: var(--size-300);
  --btn-small-font-size: var(--btn-font-size);
  --btn-medium-font-size: var(--btn-font-size);
  --btn-large-font-size: var(--font-size-18);
  --btn-x-large-font-size: var(--font-size-20);
  --btn-small-line-height: var(--btn-line-height);
  --btn-medium-line-height: var(--btn-line-height);
  --btn-large-line-height: var(--btn-line-height);
  --btn-x-large-line-height: var(--btn-line-height);
  --btn-small-border-radius: var(--btn-border-radius);
  --btn-medium-border-radius: var(--btn-border-radius);
  --btn-large-border-radius: var(--btn-border-radius);
  --btn-x-large-border-radius: var(--btn-border-radius);
  --btn-small-padding: var(--btn-padding);
  --btn-medium-padding: var(--btn-padding);
  --btn-large-padding: var(--btn-padding);
  --btn-x-large-padding: var(--btn-padding);
  --btn-small-icon-size: var(--btn-small-font-size);
  --btn-medium-icon-size: var(--btn-medium-font-size);
  --btn-large-icon-size: var(--btn-large-font-size);
  --btn-x-large-icon-size: var(--btn-x-large-font-size);
  --btn-small-icon-margin: var(--btn-icon-margin);
  --btn-medium-icon-margin: var(--btn-icon-margin);
  --btn-large-icon-margin: var(--btn-icon-margin);
  --btn-x-large-icon-margin: var(--btn-icon-margin);

  // Primary
  --btn-primary-bg-color: var(--primary-800);
  --btn-primary-color: #fff;
  --btn-primary-border-color: var(--primary-800);
  // Primary-hover
  --btn-primary-hover-bg-color: var(--primary-600);
  --btn-primary-hover-border-color: var(--primary-600);
  --btn-primary-hover-color: #fff;
  // Primary-focus
  --btn-primary-focus-bg-color: var(--primary-600);
  --btn-primary-focus-border-color: var(--primary-600);
  --btn-primary-focus-color: #fff;
  // Primary-pressed
  --btn-primary-pressed-bg-color: var(--primary-900);
  --btn-primary-pressed-border-color: var(--primary-900);
  --btn-primary-pressed-color: #fff;
  // Primary-disabled
  --btn-primary-disabled-bg-color: var(--grey-300);
  --btn-primary-disabled-border-color: var(--grey-300);
  --btn-primary-disabled-color: #fff;

  // Primary Alt - Usually used on dark backgrounds
  --btn-primary-alt-bg-color: var(--accent-100);
  --btn-primary-alt-color: var(--grey-800);
  --btn-primary-alt-border-color: var(--accent-100);
  // Primary Alt-hover
  --btn-primary-alt-hover-bg-color: var(--accent-050);
  --btn-primary-alt-hover-border-color: var(--accent-050);
  --btn-primary-alt-hover-color: var(--grey-800);
  // Primary Alt-focus
  --btn-primary-alt-focus-bg-color: var(--accent-050);
  --btn-primary-alt-focus-border-color: var(--accent-050);
  --btn-primary-alt-focus-color: var(--grey-800);
  // Primary Alt-pressed
  --btn-primary-alt-pressed-bg-color: var(--accent-200);
  --btn-primary-alt-pressed-border-color: var(--accent-200);
  --btn-primary-alt-pressed-color: var(--grey-900);
  // Primary Alt-disabled
  --btn-primary-alt-disabled-bg-color: var(--grey-300);
  --btn-primary-alt-disabled-border-color: var(--grey-300);
  --btn-primary-alt-disabled-color: #fff;

  // Secondary
  --btn-secondary-bg-color: #fff;
  --btn-secondary-color: var(--primary-800);
  --btn-secondary-border-color: var(--primary-800);
  // Secondary-hover
  --btn-secondary-hover-bg-color: var(--primary-600);
  --btn-secondary-hover-border-color: var(--primary-600);
  --btn-secondary-hover-color: #fff;
  // Secondary-focus
  --btn-secondary-focus-bg-color: #fff;
  --btn-secondary-focus-border-color: var(--primary-800);
  --btn-secondary-focus-color: var(--primary-800);
  // Secondary-pressed
  --btn-secondary-pressed-bg-color: var(--primary-900);
  --btn-secondary-pressed-border-color: var(--primary-900);
  --btn-secondary-pressed-color: #fff;
  // Secondary-disabled
  --btn-secondary-disabled-bg-color: #fff;
  --btn-secondary-disabled-border-color: var(--grey-300);
  --btn-secondary-disabled-color: var(--grey-300);

  // Secondary Alt - Usually used on dark backgrounds
  --btn-secondary-alt-bg-color: transparent;
  --btn-secondary-alt-color: #fff;
  --btn-secondary-alt-border-color: #fff;
  // Secondary Alt-hover
  --btn-secondary-alt-hover-bg-color: var(--accent-050);
  --btn-secondary-alt-hover-border-color: var(--accent-050);
  --btn-secondary-alt-hover-color: var(--grey-800);
  // Secondary Alt-focus
  --btn-secondary-alt-focus-bg-color: transparent;
  --btn-secondary-alt-focus-border-color: #fff;
  --btn-secondary-alt-focus-color: #fff;
  // Secondary Alt-pressed
  --btn-secondary-alt-pressed-bg-color: var(--accent-200);
  --btn-secondary-alt-pressed-border-color: var(--accent-200);
  --btn-secondary-alt-pressed-color: var(--grey-900);
  // Secondary Alt-disabled
  --btn-secondary-alt-disabled-bg-color: transparent;
  --btn-secondary-alt-disabled-border-color: var(--grey-300);
  --btn-secondary-alt-disabled-color: var(--grey-300);

  // Success
  --btn-success-bg-color: var(--success-color);
  --btn-success-color: #fff;
  --btn-success-border-color: var(--success-color);
  // Success-hover
  --btn-success-hover-bg-color: var(--success-color);
  --btn-success-hover-border-color: var(--success-color);
  --btn-success-hover-color: var(--grey-800);
  // Success-focus
  --btn-success-focus-bg-color: var(--success-color);
  --btn-success-focus-border-color: var(--success-color);
  --btn-success-focus-color: #fff;
  // Success-pressed
  --btn-success-pressed-bg-color: var(--success-color);
  --btn-success-pressed-border-color: var(--success-color);
  --btn-success-pressed-color: var(--grey-900);
  // Success-disabled
  --btn-success-disabled-bg-color: transparent;
  --btn-success-disabled-border-color: var(--grey-300);
  --btn-success-disabled-color: var(--grey-300);

  // Text buttons
  --btn-text-bg-color: transparent;
  --btn-text-color: var(--link-color);
  --btn-text-border: none;
  --btn-text-hover-color: var(--link-hover-color);
  --btn-text-active-color: var(--link-active-color);
  --btn-text-disabled-color: var(--grey-300);
  --btn-text-font-weight: var(--link-font-weight);

  // Text button for dark backgrounds
  --btn-text-alt-color: var(--white);
  --btn-text-alt-hover-color: var(--white);
  --btn-text-alt-active-color: var(--white);
  --btn-text-alt-disabled-color: var(--grey-300);

  // Warning
  --btn-warning-bg-color: var(--warning-color);
  --btn-warning-color: var(--white);
  --btn-warning-border-color: var(--warning-color);
  // Warning active
  --btn-warning-pressed-bg-color: var(--primary-900);
  --btn-warning-pressed-border-color: var(--primary-900);
  --btn-warning-pressed-color: var(--white);
  // Warning hover
  --btn-warning-hover-bg-color: var(--primary-600);
  --btn-warning-hover-border-color: var(--primary-600);
  --btn-warning-hover-color: var(--white);
  // Warning disabled
  --btn-warning-disabled-bg-color: var(--grey-300);
  --btn-warning-disabled-border-color: var(--grey-300);
  --btn-warning-disabled-color: var(--white);
  // Warning focus
  --btn-warning-focus-bg-color: var(--primary-600);
  --btn-warning-focus-border-color: var(--primary-600);
  --btn-warning-focus-color: var(--white);

  // Important
  --btn-important-bg-color: var(--danger-color);
  --btn-important-color: var(--white);
  --btn-important-border-color: var(--danger-color);
  // Important active
  --btn-important-pressed-bg-color: var(--primary-900);
  --btn-important-pressed-border-color: var(--primary-900);
  --btn-important-pressed-color: var(--white);
  // Important hover
  --btn-important-hover-bg-color: var(--primary-600);
  --btn-important-hover-border-color: var(--primary-600);
  --btn-important-hover-color: var(--white);
  // Important disabled
  --btn-important-disabled-bg-color: var(--grey-300);
  --btn-important-disabled-border-color: var(--grey-300);
  --btn-important-disabled-color: var(--white);
  // Important focus
  --btn-important-focus-bg-color: var(--primary-600);
  --btn-important-focus-border-color: var(--primary-600);
  --btn-important-focus-color: var(--white);

  // Transparent
  --btn-transparent-bg-color: var(--white);
  --btn-transparent-color: var(--green-50);
  --btn-transparent-border-color: var(--green-50);
  // Transparent-hover
  --btn-transparent-hover-bg-color: var(--dark-green);
  --btn-transparent-hover-border-color: var(--dark-green);
  --btn-transparent-hover-color: var(--white);
  // Transparent-focus
  --btn-transparent-focus-bg-color: var(--dark-green);
  --btn-transparent-focus-border-color: var(--dark-green);
  --btn-transparent-focus-color: var(--white);
  // Transparent-pressed
  --btn-transparent-pressed-bg-color: var(--dark-green);
  --btn-transparent-pressed-border-color: var(--dark-green);
  --btn-transparent-pressed-color: var(--white);
  // Transparent-disabled
  --btn-transparent-disabled-bg-color: var(--grey-300);
  --btn-transparent-disabled-border-color: var(--grey-300);
  --btn-transparent-disabled-color: var(--white);

  // Link
  --btn-link-bg-color: var(--white);
  --btn-link-color: var(--energy-blue-700);
  --btn-link-text-decoration: underline;
  --btn-link-border: none;
  --btn-link-font-weight: var(--font-weight-medium);
  // Link-hover
  --btn-link-hover-color: var(--energy-blue-800);
  // Link-focus
  --btn-link-focus-color: var(--energy-blue-800);
  // Link-focus
  --btn-link-focus-box-shadow: none;

  // Rating Prompt scale colors
  --rating-scale-orange: #ffa826;
  --rating-scale-yellow: #ffdc26;
  --rating-scale-green-1: #00c48c;
  --rating-scale-green-2: #00e240;
}

.button {
  box-shadow: none;
  font-size: var(--btn-font-size);
  cursor: pointer;
  text-transform: var(--btn-text-casing);
  font-weight: var(--btn-font-weight);
  font-family: var(--btn-font-family);
  max-width: 100%;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  line-height: var(--btn-line-height);
  position: relative;

  &--full-width {
    width: 100%;
  }

  &__container {
    align-items: center;
    display: inline-flex;
  }

  &--submitting {
    pointer-events: none;

    .button__container {
      opacity: 0;
    }

    .spinner {
      height: var(--size-32);
      margin-top: var(--size-8);
    }
  }

  &:not(.button--auto) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &:focus {
    outline-offset: var(--btn-focus-outline-offset);
    outline: var(--btn-focus-outline-width) solid var(--focus-outline-color);
  }

  &--disabled {
    cursor: default;
  }

  @each $size in small, medium, large, x-large {
    &.button--#{$size} {
      font-size: var(--btn-#{$size}-font-size);
      line-height: var(--btn-#{$size}-line-height);

      .button__icon-right,
      .button__icon-left {
        font-size: var(--btn-#{$size}-icon-size);
        height: var(--btn-#{$size}-icon-size);
        line-height: 1;
      }

      .button__icon-left {
        margin-right: var(--btn-#{$size}-icon-margin);
      }

      .button__icon-right {
        margin-left: var(--btn-#{$size}-icon-margin);
      }
    }
  }

  &:not(.button--text):not(.button--text-alt) {
    // Text buttons don't have padding, a border or size options.
    &.button--auto {
      width: auto;
      padding: var(--btn-medium-padding);
      border-radius: var(--btn-medium-border-radius);
    }
    @each $size in small, medium, large, x-large {
      &.button--#{$size} {
        width: var(--btn-#{$size}-width);
        border-radius: var(--btn-#{$size}-border-radius);
        padding: var(--btn-#{$size}-padding);
      }
    }
  }

  @each $variant in primary, primary-alt, secondary, secondary-alt, success,
    warning, important, lancebet-bet, lancebet-boosted-odds, transparent
  {
    &.button--#{$variant + ''} {
      background-color: var(--btn-#{$variant}-bg-color);
      color: var(--btn-#{$variant}-color);
      border: var(--btn-border-width) solid var(--btn-#{$variant}-border-color);
      box-shadow: var(--btn-#{$variant}-box-shadow);

      &:focus {
        background-color: var(--btn-#{$variant}-focus-bg-color);
        border-color: var(--btn-#{$variant}-focus-border-color);
        color: var(--btn-#{$variant}-focus-color);
        outline: var(--btn-#{$variant}-focus-outline);
      }

      &:hover {
        background-color: var(--btn-#{$variant}-hover-bg-color);
        border-color: var(--btn-#{$variant}-hover-border-color);
        color: var(--btn-#{$variant}-hover-color);
      }

      &:active {
        background-color: var(--btn-#{$variant}-pressed-bg-color);
        border-color: var(--btn-#{$variant}-pressed-border-color);
        color: var(--btn-#{$variant}-pressed-color);
      }

      &.button--disabled {
        background-color: var(--primary-700);
        color: var(--btn-#{$variant}-disabled-color);
        border-color: var(--btn-#{$variant}-disabled-border-color);
      }
      .button__icon-right,
      .button__icon-left {
        display: inline-block;
      }
    }
  }

  @each $variant in text, text-alt, link {
    &.button--#{$variant} {
      background-color: var(--btn-#{$variant}-bg-color);
      color: var(--btn-#{$variant}-color);
      border: var(--btn-#{$variant}-border);
      font-weight: var(--btn-#{$variant}-font-weight);
      text-decoration: var(--btn-#{$variant}-text-decoration);

      &:active {
        color: var(--btn-#{$variant}-active-color);
      }

      &:hover {
        color: var(--btn-#{$variant}-hover-color);
      }

      &:focus {
        box-shadow: var(--btn-#{$variant}-focus-box-shadow);
      }

      &.button--auto {
        font-size: inherit;
        line-height: inherit;
      }

      &.button--disabled {
        color: var(--btn-#{$variant}-disabled-color);
      }
    }
  }

  &--align {
    &-left,
    &-center,
    &-right {
      display: block;
      max-width: fit-content;
    }

    &-left {
      margin-left: 0;
      margin-right: auto;
    }

    &-center {
      margin-left: auto;
      margin-right: auto;
    }

    &-right {
      margin-left: auto;
      margin-right: 0;
    }
  }
}
