@import '../../../scss/global/utils/mixins';

.default-registration-form {
  &-step {
    display: none;
    visibility: hidden;

    &__secure-shield {
      margin-top: var(--size-8);
      display: flex;

      img {
        max-width: var(--size-18);
        object-fit: contain;
      }

      span {
        margin-left: var(--size-4);
        color: var(--green-500);
        font-size: var(--size-10);
        font-weight: bold;
      }
    }

    &--selected {
      visibility: visible;
      display: initial;
    }

    div.hidden {
      display: none;
    }
  }

  &__loading {
    @include lotties-animation();
  }
}
