.limit-confirmation-tiles {
  padding: 1.375rem 0;

  &__tile {
    display: flex;
    padding: var(--size-12) var(--size-16);
    border-radius: var(--size-8);

    &:not(:last-child) {
      margin-bottom: var(--size-8);
    }
  }

  &__main-content {
    flex-grow: 1;
    text-align: left;
  }

  &__auxiliary-content {
    text-align: right;

    p {
      color: var(--body-40);
    }
  }

  &__main-content,
  &__auxiliary-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    p:first-child {
      margin-bottom: 0.25rem;
    }
  }
}
