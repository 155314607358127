@import '../../../scss/global/utils/media-queries';

:root {
  // Strength Indicator
  --password-strength-margin-breakpoint-phone: 0 0 var(--size-8) 0;
  --password-strength-margin-breakpoint-tab-port: 0;
  --password-strength-msg-font-size: var(--font-size-12);
  --password-strength-msg-font-weight: bold;
  --password-strength-invalid-color: var(--danger-700);
  --password-strength-weak-color: var(--danger-color);
  --password-strength-medium-color: var(--warning-color);
  --password-strength-strong-color: var(--success-color);
  --password-strength-bar-width: var(--size-20);
  --password-strength-bar-height: 0.3125rem;
  --password-strength-bar-border-radius: 2.5px;
  --password-strength-bar-gap: 0.125rem;
}

.password-input {
  &--hidden {
    display: none;
  }

  &__strength-indicator {
    display: flex;
    align-items: center;
    margin: var(--password-strength-margin-breakpoint-phone);

    @include respond(tab-port) {
      justify-content: flex-end;
      margin: var(--password-strength-margin-breakpoint-tab-port);
    }
  }

  &__strength-msg {
    margin-right: var(--size-4);
    font-size: var(--password-strength-msg-font-size);
    font-weight: var(--password-strength-msg-font-weight);
  }

  &__strength-bar {
    width: var(--password-strength-bar-width);
    height: var(--password-strength-bar-height);
    border-radius: var(--password-strength-bar-border-radius);
    display: inline-block;

    &:not(:last-child) {
      margin-right: var(--password-strength-bar-gap);
    }
  }

  &__strength-bars {
    display: flex;
    align-items: center;

    &--invalid {
      .password-input__strength-msg {
        color: var(--password-strength-invalid-color);
      }
      .password-input__strength-bar {
        background-color: var(--password-strength-invalid-color);
      }
    }

    &--weak {
      .password-input__strength-msg {
        color: var(--password-strength-weak-color);
      }
      .password-input__strength-bar {
        background-color: var(--password-strength-weak-color);
      }
    }

    &--medium {
      .password-input__strength-msg {
        color: var(--password-strength-medium-color);
      }
      .password-input__strength-bar {
        background-color: var(--password-strength-medium-color);
      }
    }

    &--strong {
      .password-input__strength-msg {
        color: var(--password-strength-strong-color);
      }
      .password-input__strength-bar {
        background-color: var(--password-strength-strong-color);
      }
    }
  }
}
