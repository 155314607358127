@import '../../../../scss/global/utils/mixins';

:root {
  --registration-form-actions-margin: var(--size-36) 0;
}

.registration-form-step-actions {
  display: flex;
  flex-direction: column;
  margin: var(--registration-form-actions-margin);

  &__wrapper {
    display: flex;
    flex-direction: row-reverse;
  }

  &__submit {
    flex: 1;
    text-align: right;
  }

  &__sign-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    @include respond(phone) {
      margin-top: var(--size-24);
    }

    &--top-aligned {
      margin-top: calc(-1 * var(--size-24));
    }

    span {
      font-weight: var(--font-weight-medium);
      line-height: var(--size-24);
      padding-right: var(--size-2);
      height: fit-content;
      color: var(--body-100);
    }
  }
}
