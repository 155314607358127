:root {
  // General Input box styles, applies to text inputs, selects, etc.
  --input-padding: var(--size-12);
  --input-padding-right: var(--input-padding);
  --input-padding-pending: var(--size-36);
  --input-padding-left: var(--input-padding);
  --input-padding-top: var(--input-padding);
  --input-padding-bottom: var(--input-padding);
  --input-border-radius: 4px;
  --input-font-size: var(--font-size-14);
  --input-line-height: normal;
  --input-font-weight: 400;
  --input-color: var(--body-text-color);
  --input-dark-color: var(--grey-100);
  --input-border-width: 2px;
  --input-border: var(--input-border-width) solid var(--grey-500);
  --input-dark-border: var(--input-border-width) solid var(--grey-300);
  --input-focus-border: var(--input-border-width) solid var(--primary-600);
  --input-dark-focus-border: var(--input-border-width) solid var(--primary-100);
  --input-error-border: var(--input-border-width) solid var(--danger-color);
  --input-dark-error-border: var(--input-border-width) solid var(--danger-300);
  --input-warning-border: var(--input-border-width) solid var(--warning-color);
  --input-dark-warning-border: var(--input-border-width) solid
    var(--warning-400);
  --input-success-border: var(--input-border-width) solid var(--success-color);
  --input-dark-success-border: var(--input-border-width) solid
    var(--success-300);
  --input-completed-border: var(--input-border-width) solid var(--primary-600);
  --input-dark-completed-border: var(--input-border-width) solid
    var(--primary-400);
  --input-hover-border: var(--input-focus-border);
  --input-dark-hover-border: var(--input-dark-focus-border);
  --input-font-family: var(--body-font-family);
  --input-label-margin: 0 0 var(--size-4) 0;
}

.disable-outline {
  *:focus,
  .checkbox__icon {
    outline: none !important;
  }
}
