:root {
  --loading-indicator-justify-content: initial;
  --loading-indicator-left: 50%;
  --loading-indicator-position: absolute;
  --loading-indicator-right: auto;
  --loading-indicator-top: 50%;
  --loading-indicator-transform: translate(-50%, -50%);
  --loading-indicator-icon-font-size: var(--size-24);
  --loading-indicator-icon-speed: 1s;
}

.loading-indicator {
  display: flex;
  justify-content: var(--loading-indicator-justify-content);
  left: var(--loading-indicator-left);
  position: var(--loading-indicator-position);
  right: var(--loading-indicator-right);
  top: var(--loading-indicator-top);
  transform: var(--loading-indicator-transform);
}

.loading-indicator__icon {
  animation: Rotate var(--loading-indicator-icon-speed) infinite linear;
  font-size: var(--loading-indicator-icon-font-size);
}

@keyframes Rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
