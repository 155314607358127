.simple-game-card {
  cursor: pointer;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;

  &--box {
    padding-top: 64%; /* 1:1 Aspect Ratio */
  }
  &--tall {
    padding-top: 150%; /* 2:3 Aspect Ratio */
  }
  &--wide {
    padding-top: 66.666%; /* 3:2 Aspect Ratio */
  }

  &__inner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: var(--size-8);
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--size-8);
  }
}
