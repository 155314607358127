@import '../../../../scss/global/utils/mixins';

.header-balance {
  &__sum {
    padding-right: 14px;
  }
  display: flex;
  flex-direction: column;
  text-align: right;
  cursor: pointer;
  border-radius: var(--size-16) var(--size-16) 0 0;
  position: relative;
  padding: var(--size-8) var(--size-5) var(--size-8) 0;

  &__text {
    display: flex;
    align-items: center;
    color: var(--white);
    font-size: var(--font-size-10);

    *:nth-child(n + 1) {
      margin-left: var(--size-4);
    }
  }

  &__text-embedded {
    span {
      color: var(--white);
    }
  }
}
