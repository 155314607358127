@import '../../../../../scss/global/utils/media-queries';
@import '../../../../../scss/global/utils/mixins';

:root {
  --snack-color: var(--grey-800);
  --snack-font-size: var(--font-size-12);
  --snack-line-height: var(--font-size-18);
  --snack-padding: var(--size-16);
  --snack-margin: var(--size-16);
  --snack-background-color: var(--white);
  --snack-border: none;
  --snack-border-radius: 4px;
  --snack-box-shadow: var(--box-shadow-2);
  --snack-icon-margin: 0 var(--size-8) 0 0;
  --snack-info-color: var(--cyan-100);
  --snack-icon-size: var(--font-size-20);
  --snack-dark-color: var(--white);
  --snack-dark-background-color: var(--grey-900);
}

.snack {
  &__container {
    margin: var(--snack-margin);
  }

  align-items: center;
  background-color: var(--snack-background-color);
  border-radius: var(--snack-border-radius);
  border: var(--snack-border);
  bottom: -100%;
  box-shadow: var(--snack-box-shadow);
  color: var(--snack-color);
  cursor: pointer;
  display: flex;
  padding: var(--snack-padding);
  transition: 0;
  user-select: none;

  &--dark {
    color: var(--snack-dark-color);
    background-color: var(--snack-dark-background-color);

    .snack__action {
      button {
        color: var(--snack-dark-color);

        &:hover {
          color: var(--body-30);
        }
      }
    }
  }

  &--light {
    color: var(--snack-color);
    background-color: var(--snack-background-color);

    .snack__action {
      button {
        color: var(--snack-color);

        &:hover {
          color: var(--grey-400);
        }
      }
    }
  }

  &__message {
    text-align: left;
    font-weight: 500;
    font-size: var(--snack-font-size);
    line-height: var(--snack-line-height);
    flex-grow: 2;
  }

  &__icon {
    margin: var(--snack-icon-margin);
    font-size: var(--snack-icon-size);
    line-height: var(--size-16);
    text-align: left;
    @include flex-center-all;

    &--error {
      color: var(--danger-color);
      @include icon-form;
    }

    &--warning {
      color: var(--warning-color);
      @include icon-form;
    }

    &--info {
      color: var(--snack-info-color);
      @include icon-form;
    }

    &--success {
      color: var(--success-color);
      @include icon-form;
    }
  }

  &__action {
    margin-left: var(--size-8);

    button {
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;
      font: inherit;
      font-weight: bold;
      text-decoration: underline;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}