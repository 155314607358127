@import '../../../../../scss/global/utils/mixins';

$my-account-home-banner-height: 17.875rem;
$my-account-home-content-overlap: 1.5rem;

.my-account-home {
  &__banner {
    background-color: var(--dark-blue-100);
    height: $my-account-home-banner-height;
    width: 100%;
    padding: 0 var(--size-20);
    overflow: hidden;
    margin-top: -3.5rem;
  }

  &__heading {
    color: var(--white);
    margin-top: 3.75rem;
  }

  &__content {
    @include rounded-page-content();

    margin-top: (-$my-account-home-content-overlap);
    min-height: calc(
      100% - #{$my-account-home-banner-height} + #{$my-account-home-content-overlap}
    );
    width: 100%;
    padding: var(--size-48) var(--size-16) var(--size-32);
    position: relative;
  }

  &__sections {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 8.75rem 8.75rem 8.75rem;
    row-gap: var(--size-8);
    column-gap: var(--size-8);
    margin-bottom: var(--size-16);

    &-label {
      background-color: var(--orange-200);
      color: var(--white);
      font-weight: var(--heading-font-weight);
      border-radius: var(--size-2);
      padding: var(--size-2);
      height: var(--size-18);
      margin: 0;
      display: flex;
      align-items: center;
      width: max-content;
      position: absolute;
    }
  }

  &__activity-section {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}
