@import '../../../../scss/global/utils/mixins';

:root {
  --category-navigation-gap: var(--size-8);
  --category-navigation-margin: var(--size-16);
}

.promotion-category__navigation {
  @include scroll-container-no-scrollbars;

  @include respond(phone) {
    height: var(--size-64);
  }

  @include respond(tab-port) {
    height: var(--size-72);
  }

  @include respond(desktop) {
    height: auto;
  }

  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  gap: var(--category-navigation-gap);

  .button {
    outline: none;
    &:hover {
      text-decoration: none;
    }
  }
  &-item {
    cursor: pointer;

    .button__container {
      height: var(--size-40);
    }

    .chip {
      color: var(--sub-nav-pills-hover);
      border-color: var(--sub-nav-pills-hover);
      font-weight: var(--font-weight-regular);
      height: 100%;
      &.active,
      &:active,
      &:hover {
        border-color: var(--chip-rounded-hover-background);
        color: var(--chip-rounded-hover-color);
        background-color: var(--sub-nav-pills-hover);
      }
    }

    &-icon {
      height: var(--size-12);
      width: var(--size-12);
      margin-right: var(--size-8);
    }

    @include respond(phone) {
      padding-right: var(--size-8);
    }
  }
}

.chip.promotion-category--medium-chip {
  @include typographySize(m);

  padding: var(--size-10) var(--size-24);
}
