:root {
  --game-card-footer-details-z-index: 2;
  --navigation-z-index: 3;
  --daterange-calender-z-index: 5;
  --navigation-drawer-z-index: 4; // Placing behind My Account overlay & modals
  --z-index-notification-banner: 9; // Above all content to ensure feedback is displayed
  --grecaptcha-badge-z-index: 20; // Above touch drawer backdrop
  --z-index-header: var(--z-index-overlay-content);
  --z-index-game-overlay: var(--z-index-overlay-content);
  --touchdrawer-backdrop-z-index: var(--z-index-overlay); // Above game overlay
}
