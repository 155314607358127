@import '../../../../scss/global/utils/mixins';

.product-tabs {
  padding: 0 var(--size-16);
  display: flex;
  align-items: center;

  @include respond(phone) {
    padding: 0;
  }

  .button {
    &__container {
      color: var(--body-50);
      font-size: var(--size-14);
      font-weight: var(--font-weight-regular);
      padding-bottom: var(--size-8);
    }

    &:hover,
    &:focus {
      background-color: inherit;
    }

    &:active {
      .button__container {
        color: var(--body-10);
        border-bottom: 1px solid var(--body-10);
      }
    }
  }

  &--active {
    .button {
      &__container {
        border-width: 1px;
        border-color: var(--body-10);
        color: var(--body-10);

        @include respond(desktop) {
          color: var(--body-10);
        }
      }
    }
  }
}
