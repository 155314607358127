@import '../../../../scss/global/utils/mixins';

:root {
  // Prompt grid & layout
  --prompt-grid-column-phone: 1/5;
  --prompt-grid-column-phone-landscape: 1/9;
  --prompt-grid-column-tab-port: 5/9;
  --prompt-grid-column-desktop: 9/13;
  --prompt-grid-column-extra-big-desktop: 19/25;
  --prompt-padding-phone: 0;
  --prompt-padding-phone-landscape: 0;
  --prompt-padding-tab-port: 0 var(--size-24);
  --prompt-padding-desktop: 0 var(--size-32);
  --prompt-padding-extra-big-desktop: 0 var(--size-56);

  // Prompt content
  --prompt-content-padding: var(--size-16);
  --prompt-content-img-height: 8.75rem;
  --prompt-content-background-color--dark: var(--primary-900);

  // Close button
  --prompt-closebtn-size: var(--size-16);
  --prompt-closebtn-color: var(--body-40);
  --prompt-closebtn-color--dark: var(--primary-900);

  // Action button
  --prompt-btn-margin-bottom: var(--size-10);
  --prompt-btn-margin-right: 0.3125rem;

  //Text color
  --prompt-text-color--dark: var(--white);
}

.prompt {
  .touch-drawer__container {
    display: grid;
    position: fixed;
    grid-template-columns: repeat(1, 1fr);
    gap: var(--grid-tab-port-gutter);

    @each $size in 'phone', 'tab-port', 'desktop', 'extra-big-desktop' {
      @include respond($size) {
        grid-template-columns: repeat(var(--grid-#{$size}-columns), 1fr);
        gap: var(--grid-#{$size}-gutter);
        padding: var(--prompt-padding-#{$size});
        .touch-drawer__viewport {
          grid-column: var(--prompt-grid-column-#{$size});
          max-width: unset;
          left: 0;
        }
      }
    }
  }

  .touch-drawer__header {
    border-radius: var(--touch-drawer-touchpad-bar-border-radius);
    padding: var(--size-16) var(--size-16) 0 var(--size-16);

    .prompt__close-btn {
      height: var(--prompt-closebtn-size);
      width: var(--prompt-closebtn-size);
      color: var(--prompt-closebtn-color);
      float: right;
      cursor: pointer;
      &--dark {
        color: var(--white);
      }
    }
  }

  .touch-drawer__content {
    text-align: center;
    background: var(--white);
    &--dark {
      background: var(--prompt-content-background-color--dark);
      @include dark-theme-text-color;
    }

    .prompt__image {
      height: var(--prompt-content-img-height);
      object-fit: contain;
      width: 100%;
      margin: 0 auto;
    }
  }

  &__content {
    padding: var(--size-16) 0;
  }

  &__heading {
    margin-bottom: var(--size-10);
  }

  .touch-drawer__footer {
    display: flex;
    align-content: flex-start;
    justify-content: space-evenly;
    gap: 0 var(--prompt-btn-margin-right);
    flex-flow: row wrap;
    margin-bottom: calc(var(--prompt-btn-margin-bottom) * -1);
    padding: var(--size-16) var(--size-16) var(--size-32) var(--size-16);
    .button {
      min-width: calc(50% - (var(--prompt-btn-margin-right) / 2));
      max-width: 100%;
      flex-grow: 1;
      margin-bottom: var(--prompt-btn-margin-bottom);
      &--dark {
        .button {
          span {
            color: var(--prompt-text-color--dark);
          }
        }
      }
    }
  }
}

.mobile-landscape {
  .touch-drawer__container {
    grid-template-columns: repeat(var(--grid-phone-landscape-columns), 1fr);
    gap: var(--grid-phone-landscape-gutter);
    padding: var(--prompt-padding-phone-landscape);
    .touch-drawer__viewport {
      grid-column: var(--prompt-grid-column-phone-landscape);
    }
  }
}
