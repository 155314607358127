.notification-message {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: var(--size-12);
  font-weight: var(--link-font-weight);

  &__button.button {
    color: var(--white);
    padding-left: var(--size-4);
    text-decoration: underline;

    &:hover {
      color: var(--white);
    }
  }

  &__icon {
    font-size: var(--size-16);
    margin-right: var(--size-8);
  }

  span {
    line-height: normal;
  }
}
