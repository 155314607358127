@import '../../../../scss/global/utils/mixins';

.registration-footer {
  background-color: var(--body-80);

  @include respond(desktop) {
    background-color: transparent;
  }

  &__content {
    display: flex;
    padding: var(--size-16);
    align-items: flex-start;
  
    @include respond(desktop) {
      padding: 0;
    }
  }

  &__text-section {
    width: 65%;

    p {
      @include paragraphStyles(xs);

      color: var(--body-10);
      margin-bottom: var(--size-12);

      a {
        color: var(--body-10);
        text-decoration: underline;
      }

      @include respond(desktop) {
        color: var(--body-10);
        margin-bottom: var(--size-10);

        a {
          color: var(--body-10);
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include respond(desktop) {
      width: auto;
    }
  }

  &__image-section {
    flex: 1;
    display: flex;
    margin-right: -0.75rem;
    margin-bottom: -0.625rem;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: flex-end;

    @include respond(desktop) {
      justify-content: flex-start;
      align-items: center;
    }

    // Note: Would prefer to use 'gap' for this but support isn't great for Safari
    > * {
      margin-bottom: var(--size-10);
      margin-right: var(--size-6);
      padding-bottom: var(--size-4);
    }
  }

  &__text-section + &__image-section {
    margin-left: var(--size-2);
  }

  &__image {
    max-height: var(--size-20);
    display: block;
  }

  &__sub-text {
    color: var(--body-10);
    padding: var(--size-10) var(--size-16) var(--size-16);

    @include respond(desktop) {
      padding: var(--size-16) 0 0;
    }
  }
}
