:root {
  --accordion-header-padding: var(--accordion-padding);
  --accordion-header-focus-background-color: inherit;
  --accordion-header-label-margin: 0 var(--size-8) 0 0;
  --accordion-header-background-color: var(--grey-050);
  --accordion-header-font-size: inherit;
  --accordion-header-font-weight: inherit;
  --accordion-header-font-color: var(--black);
  --accordion-header-cross-color: var(--black);
  --accordion-header-min-height: inherit;
  --accordion-chevron-color: var(--grey-400);
  --accordion-chevron-expanded-color: var(--black);
}
.accordion-header {
  background: var(--accordion-header-background-color);
  min-height: var(--accordion-header-min-height);
  color: var(--accordion-header-font-color);

  &__inner {
    display: flex;
    padding: var(--accordion-header-padding);
    cursor: pointer;
    outline: none;
    font-size: var(--accordion-header-font-size);
    font-weight: var(--accordion-header-font-weight);

    &:focus {
      background-color: var(--accordion-header-focus-background-color);
    }
  }

  &__label {
    flex: 1;
  }

  &__icon {
    display: flex;
    align-items: center;
    color: var(--accordion-header-cross-color);
    &--rotated {
      transform: rotateX(180deg);
    }

    svg {
      color: var(--accordion-chevron-color);
    }
  }

  &--expanded {
    .accordion-header {
      &__icon {
        svg {
          color: var(--accordion-chevron-expanded-color);
        }
      }
    }
  }

  &--align-left,
  &--align-right {
    .accordion-header {
      &__inner {
        display: inline-flex;
      }
      &__label {
        flex: initial;
        margin: var(--accordion-header-label-margin);
      }
    }
  }

  &--align-right {
    text-align: right;
  }
}
