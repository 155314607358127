@import '../../../../scss/global/utils/mixins';

.recommended-game-carousel {
  .carousel-wrapper {
    .carousel {
      .carousel-wrapper {
        &__slide-wrapper {
          .carousel-wrapper {
            &__buttons {
              &--back {
                padding-left: var(--size-6);
              }
            }
          }
        }
      }
    }
  }
}
// Desktop & Tablet
.modal-body {
  .game-search-spine-zone {
    &__inner {
      .game-search-bar {
        display: block;
        width: 100%;
        padding-right: var(--size-24);
      }
    }
  }
}

// Mobile
.touch-drawer {
  &__content {
    .game-search-spine-zone {
      &__inner {
        .game-search-bar {
          display: block;

          @include respond(phone) {
            width: 100%;
          }
        }
      }
    }
  }

  &__close-btn {
    display: none;
  }
}
