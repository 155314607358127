:root {
  // Global starterkit variable overrides
}

.gradient-cold {
  background-color: var(--primary-900);
}

.gradient-warm {
  background: var(--gradient-warm);
}

.padding-2 {
  padding: var(--size-2);
}

.padding-4 {
  padding: var(--size-4);
}

.padding-8 {
  padding: var(--size-8);
}

.padding-12 {
  padding: var(--size-12);
}

.padding-16 {
  padding: var(--size-16);
}

.padding-20 {
  padding: var(--size-20);
}

.padding-24 {
  padding: var(--size-24);
}

.padding-28 {
  padding: var(--size-28);
}

.padding-32 {
  padding: var(--size-32);
}

.padding-40 {
  padding: var(--size-40);
}

.padding-48 {
  padding: var(--size-48);
}

.padding-56 {
  padding: var(--size-56);
}

.padding-64 {
  padding: var(--size-64);
}

.padding-72 {
  padding: var(--size-72);
}

.padding-80 {
  padding: var(--size-80);
}
