@import '../../../../scss/global/utils/mixins';

.game-info {
  @include respond(tab-port) {
    padding-right: var(--size-24);
  }

  @include respond(tab-land) {
    padding-right: 0;
  }

  @include respond(desktop) {
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    column-gap: var(--size-16);
  }

  // Will most likely be removed in Game Specs ticket
  &__specs-content {
    margin: 0 -0.75rem -0.75rem 0;

    > * {
      margin: 0 var(--size-12) var(--size-12) 0;
    }
  }

  &__col-1,
  &__col-2 {
    > * {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: var(--size-16);
      }
    }
  }

  &__col-1 {
    @include respond(desktop) {
      grid-column: 1/17;
      // Fix firefox issue which enables HeroBannerCarousel to exceed grid column boundary
      overflow: hidden;
    }
  }

  &__col-2 {
    @include respond(desktop) {
      grid-column: 17/25;
    }
  }

  &__banner {
    .hero-carousel-slide__sub-heading {
      display: block;
      color: var(--white);
      text-shadow: 0 0 var(--black);
      font-size: var(--font-size-14);
      font-weight: var(--font-weight-medium);
    }
  }

  &__description {
    > *:not(:last-child) {
      margin-bottom: var(--size-24);
    }
  }

  .hero-banner-carousel--mobile {
    margin-left: auto;

    .carousel__slider-tray--horizontal {
      left: 0;
    }
  }

  .hero-carousel-slide__link {
    .button {
      @include respond(phone) {
        display: none;
      }
    }
  }
}
