@import '../../../../../scss/global/utils/mixins';

$game-menu-balance-width: 12.5rem;
$game-menu-balance-with: 10.25rem;
$game-menu-balance-height: 7.75rem;

.game-menu-balance {
  margin-top: var(--size-28);

  @include respond(phone) {
    margin-top: var(--size-20);
  }

  &__cash,
  &__bonus {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 0.5rem;
    @include icon_large();

    .balance__details {
      display: flex;
      flex-direction: column;

      .balance__details__title {
        font-size: 0.5rem;
        line-height: 0.75rem;
        font-weight: 500;
        color: var(--body-50);
      }

      .balance__details__amount {
        color: var(--body-90);
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.875rem;
      }
    }
  }

  &__cash {
    svg {
      margin-right: 1rem;
      fill: var(--cyan-100);
    }
  }

  &__bonus {
    svg {
      margin-left: 0.5rem;
      fill: var(--cyan-100);
    }

    .icon-button {
      @include icon_small();

      svg {
        margin-left: 0;
      }
    }
  }

  &__content-wrap {
    display: flex;
    align-items: center;
  }

  &__flex-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;

    .balance-details {
      &__item {
        background-color: var(--primary-700);
      }

      &__card-body {
        svg {
          margin: 0;
        }

        &::-webkit-scrollbar-track {
          background-color: var(--body-80); 
        }
      }

      &__item-expand-btn {
        svg {
          fill: var(--body-5)
        }
      }
    }
  }

  &__text-tooltip-wrap {
    display: flex;
    align-items: flex-end;
  }

  &__balance-type {
    color: var(--body-5);
    margin-top: var(--size-8);
    margin-bottom: var(--size-2);
    margin-right: var(--size-8);
    font-size: var(--font-size-12);

    @include respond(phone) {
      font-size: var(--font-size-10);
    }

    &--secondary {
      margin-top: 0;
      color: var(--white);
      font-size: var(--font-size-14);

      @include respond(phone) {
        font-size: var(--font-size-12);
      }
    }

    >span {
      color: var(--light-green);
    }
  }

  &__balance {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--white);
    font-size: var(--size-20);

    @include respond(phone) {
      font-size: var(--font-size-16);
    }
  }

  &__button.button {
    // Fix for not working button hover scope
    z-index: var(--z-index-overlay);
    text-transform: uppercase;
    font-size: var(--font-size-14);
    line-height: var(--font-size-20);

    @include respond(phone) {
      font-size: var(--font-size-12);
    }

    svg {
      fill: var(--dark-blue-100);
    }
  }
}
