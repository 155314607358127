@import '../../../../../scss/global/utils/mixins';

:root {
  --balance-summary-height: var(--size-56);
  --balance-transition: all 0.5s ease;
}

* {
  -webkit-tap-highlight-color: transparent;
}

.balance-details {
  &__summary {
    z-index: 2;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: var(--balance-summary-height);
    margin: 0 var(--size-16);
    padding: 0 var(--size-16);
    color: var(--white);
    font-weight: 700;
    font-size: var(--size-16);
    line-height: var(--size-24);
    background: var(--primary-900);
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 0;
    transition: var(--balance-transition);

    &.show {
      opacity: 1;
    }

    &-cash,
    &-bonus {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr auto;
      gap: var(--grid-tab-port-gutter);
    }
  }

  &__bonus {
    overflow: hidden;

    &__card {
      padding-right: 0;
    }
  }

  &__details {
    max-height: inherit;
    overflow-y: scroll;
    scroll-behavior: smooth;
    padding-top: var(--size-16);
    padding-left: var(--size-16);
    color: var(--white);
    transition: var(--balance-transition);

    @include styled-scrollbar();

    &::-webkit-scrollbar-track {
      background: var(--primary-900);
    }

    .balance-details__cash {
      transition: var(--balance-transition);
    }
  }

  &__details-embedded {
    position: absolute;
    top: var(--size-50);
    right: 0;
    padding: 0 var(--size-24);
    color: var(--white);
    transition: var(--balance-transition);
    background-color: var(--body-95);
    z-index: 3;
    border-radius: var(--size-16) 0 var(--size-16) var(--size-16);
    width: 23.4375rem;

    @include respond(phone) {
      width: 100vw;
    }
  }

  &__card {
    margin: 0 0 var(--size-16) 0;
    padding: var(--size-16);
    border-radius: var(--size-8);
    background-color: var(--body-70);

    &-header {
      display: grid;
      align-items: center;
      grid-template-columns: [icon] 42px [text] 1fr [amount] auto;
      gap: var(--grid-tab-port-gutter);
      opacity: 1;
      height: fit-content;
      transition: var(--balance-transition);

      &-icon {
        grid-area: 'icon';
        display: grid;
        align-self: center;
        justify-self: start;
      }

      &-text {
        grid-area: 'text';
        justify-self: start;
        font-style: normal;
        font-weight: 600;
        font-size: var(--size-14);
        line-height: var(--size-20);
        color: var(--body-5);
      }

      &-amount {
        grid-area: 'amount';
        font-style: normal;
        font-weight: 700;
        font-size: var(--size-16);
        line-height: var(--size-24);
        color: var(--body-5);
      }
    }

    &-body {
      overflow-y: auto;
      margin-top: var(--size-8);
      max-height: var(--size-192);
      transition: var(--balance-transition);

      @include styled-scrollbar();

      &::-webkit-scrollbar-track {
        background: var(--body-70);
        border-radius: 0 var(--size-8) var(--size-8) 0;
      }

      &::-webkit-scrollbar {
        width: var(--size-10);
      }
    }
  }

  .balance-details__item {
    padding: var(--size-16);
    margin-bottom: var(--size-16);
    border-radius: var(--size-8);
    background-color: var(--dark-blue-50);
    cursor: pointer;

    &-summary {
      display: grid;
      grid-template-columns: 60% 40%;
      row-gap: var(--size-4);
      grid-template-areas:
        'title amount'
        'expire expand';

      &-title,
      &-expire {
        text-align: left;
      }

      &-amount,
      &-expand {
        text-align: right;
      }

      &-title {
        grid-area: 'title';
        font-weight: 600;
        font-size: var(--size-14);
        line-height: var(--size-20);
      }

      &-amount {
        grid-area: 'amount';
        font-weight: 700;
        font-size: var(--size-14);
        line-height: var(--size-20);
      }

      &-expire {
        grid-area: 'expire';
        font-weight: 500;
        font-size: var(--size-10);
        line-height: var(--size-14);
      }

      &-expand {
        z-index: 1;
        grid-area: 'expand';
        font-weight: 600;
        font-size: var(--size-10);
        line-height: var(--size-14);
        cursor: pointer;
      }
    }

    &-details {
      height: 0;
      overflow: hidden;
      opacity: 0;
      transition: var(--balance-transition);

      .balance-details__item-divider {
        margin: 0;

        &::after {
          height: 1px;
          content: '';
          width: 100%;
          background-color: var(--body-10);
          display: block;
          margin: 0 auto;
        }
      }

      &.open {
        opacity: 1;
        height: fit-content;

        .balance-details__item-divider {
          margin: var(--size-16) 0;
        }
      }

      &-row {
        display: grid;
        grid-template-columns: [text] 60% [amount] 40%;
        row-gap: var(--size-8);
      }

      &-text {
        font-weight: 500;
        font-size: var(--size-10);
        line-height: var(--size-14);
        justify-self: start;
      }

      &-amount {
        font-weight: 600;
        font-size: var(--size-12);
        line-height: var(--size-18);
        justify-self: end;
      }
    }
  }

  .show + .balance-details__details {
    .balance-details__cash {
      opacity: 0;
      overflow: hidden;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .balance-details__bonus {
      .balance-details__card-header {
        height: 0;
        opacity: 0;
        overflow: hidden;
        margin-bottom: 0;
      }
    }
  }
}
