@import '../../../../scss/global/utils/mixins';

:root {
  --inbox-message-margin: var(--size-2) 0 0;
}

.product-tabs {
  @include respond(desktop) {
    min-height: var(--size-72);

    & + .navigation__children {
      padding-top: 0;
    }
  }
  min-height: var(--size-54);
  padding: 0 var(--size-12);
  display: flex;
  align-items: center;

  @include respond(desktop) {
    background-color: var(--primary-900);
  }

  &--active {
    .button {
      &__container {
        border-bottom: 2px solid var(--black);
        color: var(--black);
      }
    }
  }

  .button {
    outline: none;
    padding: 0 var(--size-16);
    margin-top: var(--size-8);

    &__container {
      color: var(--black);
    }
    &:hover {
      text-decoration: none;
    }
  }
}
