@import '../../../../../scss/global/utils/mixins';

$cards-in-row-2: calc((100% - 2rem) / 2);
$auto: 1rem;

.promotion-card {
  border-radius: 0.35rem;
  overflow: hidden;
  background-color: var(--white);
  margin-top: var(--size-16);

  &:nth-child(odd) {
    margin-right: 0;
  }

  @include respond(phone) {
    width: 100%;
  }

  @include respond(tab-port) {
    width: 100%;
  }

  @include respond(desktop) {
    width: $cards-in-row-2;

    &:nth-child(odd) {
      margin-right: var(--size-16);
    }
  }

  @include respond(big-desktop) {
    width: $cards-in-row-2;

    &:nth-child(odd) {
      margin-right: var(--size-16);
    }
  }

  &--without-margin {
    &.promotion-card--specific {
      margin: 0;
    }
  }

  &__short-terms {
    margin-top: var(--size-12);
    padding-top: var(--size-10);
    font-size: var(--size-10);
    display: block;
    border-top: 1px solid #e9e9e9;
    font-weight: var(--text-highlight-weight-normal);
    color: var(--body-100);
  }

  .promotion-card-active {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;
    min-height: 5.25rem;

    button {
      flex-grow: 1;
    }

    &__cta-section {
      padding-top: var(--size-12);
    }

    &__progress-info {
      flex: 4;
      display: grid;
      align-content: space-around;
      height: 100%;
      width: 100%;
    }

    &__progress-values {
      display: flex;
      justify-content: space-between;
      div {
        display: flex;
        flex-direction: column;
      }
      div:first-of-type {
        margin-right: var(--size-10);
      }
      div:nth-of-type(2) {
        align-items: flex-end;
      }
      b,
      span {
        color: var(--body-100);
      }
      b {
        font-weight: bold;
      }
      span {
        font-size: var(--font-size-10);
      }
    }

    &__message-block {
      width: 100%;
      height: var(--size-40);
      display: flex;
      flex: 4;
      justify-content: flex-end;
      margin-right: var(--size-20);
    }
    .input-feedback-icon {
      margin-left: var(--size-4);
      z-index: 0;
    }
    &__opted-in-message {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: var(--font-size-12);
      color: var(--dark-blue-100);
      font-weight: var(--paragraph-highlight-font-weight);
    }
  }

  &__description-block {
    min-height: var(--size-36);
    padding-bottom: 0;
    flex: 1;
  }

  &__description {
    font-size: var(--font-size-12);
    color: var(--body-100);
  }

  &__banner-block {
    width: 100%;
    position: relative;
    overflow: hidden;
    padding-bottom: 33%;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
    }
  }

  &__banner-title {
    @include respond(phone) {
      font-size: var(--paragraph-l-font-size);
    }

    left: var(--size-16);
    font-weight: 700;
    font-size: var(--size-16);
  }

  &__banner-img {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__info-block {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__more-info-block {
    padding: var(--size-8) var(--size-16);
    background-color: var(--grey-050);
    max-height: var(--size-80);
  }

  .link,
  &__more-info-text {
    font-size: var(--font-size-10);
    line-height: var(--font-size-12);
    color: var(--blue-60);
    font-weight: var(--text-highlight-weight-bold);
    text-decoration: underline;
  }

  .link {
    display: inline;
  }

  &__more-info-text {
    position: absolute;
    bottom: 0;
    padding: var(--size-2) var(--size-10);
  }

  &__buttons-block {
    display: flex;
    justify-content: flex-end;
    padding: var(--size-16) var(--size-12);
    .button--primary {
      border: var(--size-2) solid var(--btn-primary-bg-color);
    }

    &--full-width {
      justify-content: flex-start;
      .button:not(.button--text):not(.button--text-alt).button--medium {
        width: 100%;
        margin-right: 0;
      }
    }
  }

  &__secondary-btn {
    margin-right: var(--size-8);
  }
}
// Quickfix for responsive promotions grid
.promotions-block {
  .promotion-card {
    min-width: auto;
    // height: var(--size-460);
  }
}
