:root {
  --form-actions-padding: var(--size-16) 0;
}

.form {
  &__actions {
    padding: var(--form-actions-padding);
    display: flex;
  }
}
