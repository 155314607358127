@import '../../../../scss/betzone-uk/theme/forms.betzone-uk';

:root {
  --input-width: 100%;
  --input-bg-color: var(--white);
  --input-dark-bg-color: var(--primary-900);
  --input-disabled-opacity: var(--disabled-opacity);
  --input-dark-disabled-opacity: var(--disabled-opacity);
  --input-focus-outline: var(--focus-outline);
  --input-focus-outline-offset: var(--focus-outline-offset);
  --input-icon-color: var(--body-40);
  --input-dark-icon-color: var(--dark-blue-50);
  --input-right-icon-size: var(--font-size-20);
  --input-left-icon-size: var(--font-size-20);
  --input-placeholder-color: inherit;
  --input-currency-font-size: inherit;
  --input-pending-size: var(--font-size-12);
  // Gap between multiple icons + icon and input text
  --input-icon-gap: var(--size-8);
  --input-currency-gap: var(--size-12);
  --input-complex-currency-gap: var(--size-18);
  --input-auto-fill-bg-color: transparent;
  --input-auto-fill-box-shadow: 0 0 0 50px var(--input-bg-color) inset;
  --input-dark-auto-fill-box-shadow: 0 0 0 50px var(--input-dark-bg-color) inset;
}

.input {
  position: relative;

  &--fill {
    width: 100%;
  }

  &__label {
    display: block;
    text-align: left;
    margin: var(--input-label-margin);
  }

  &__box-container {
    position: relative;
    background-color: var(--input-bg-color);
    border-radius: var(--input-border-radius);
  }

  &__box {
    position: relative;
    border: var(--input-border);
    border-radius: var(--input-border-radius);
    padding: var(--input-padding-top) var(--input-padding-right)
      var(--input-padding-bottom) var(--input-padding-left);
    width: var(--input-width);
    font-size: var(--input-font-size);
    font-weight: var(--input-font-weight);
    line-height: var(--input-line-height);
    color: var(--input-color);
    background-color: transparent;
    z-index: var(--z-index-input-box);
    font-family: var(--input-font-family);

    &:focus {
      outline: var(--input-focus-outline);
      outline-offset: var(--input-focus-outline-offset);
    }

    &::placeholder {
      color: var(--input-placeholder-color);
    }
  }

  // Specific styles for the fields on the second step
  #givenName,
  #familyName {
    text-transform: capitalize;
  }

  &--has-right-icon {
    .input__box {
      padding-right: calc(
        var(--input-padding-right) + var(--input-right-icon-size) +
          var(--input-icon-gap)
      );
    }
  }

  &--has-right-icons {
    .input__box {
      padding-right: calc(
        var(--input-padding-right) + var(--input-right-icon-size) +
          var(--input-feedback-icon-size) + calc(var(--input-icon-gap) * 2)
      );
    }
  }

  &--has-left-icon {
    .input__box {
      padding-left: calc(
        var(--input-padding-left) + var(--input-left-icon-size) +
          var(--input-icon-gap)
      );
    }
  }

  .input__box {
    font-size: var(--input-currency-font-size);
    line-height: var(--input-line-height);
  }

  &__currency {
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    top: 0;
    bottom: 0;
    font-size: var(--input-currency-font-size);
    line-height: var(--input-line-height);

    &--left {
      left: calc(var(--input-padding-left) + var(--input-border-width));
    }

    &--right {
      right: calc(var(--input-padding-right) + var(--input-border-width));
    }
  }

  &--has-currency {
    .input__box {
      padding-left: calc(var(--input-padding-left) + var(--input-currency-gap));

      &--complex-currency {
        padding-left: calc(
          var(--input-padding-left) + var(--input-complex-currency-gap)
        );
      }
    }
  }

  &__icon {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    color: var(--input-icon-color);

    &--right {
      right: calc(var(--input-padding-right) + var(--input-border-width));
      font-size: var(--input-right-icon-size);
    }

    &--left {
      left: calc(var(--input-padding-left) + var(--input-border-width));
      font-size: var(--input-left-icon-size);
    }
    &--pending {
      font-size: var(--input-pending-size);
      right: calc(
        var(--input-padding-pending) * 1.15 + var(--input-border-width)
      );
    }

    // If the input icon is not interactive, place it below input box so clicking will still focus the input
    & > svg {
      z-index: var(--z-index-input-icon);
    }

    // If the input icon is an interactive button, place it above the input box so it's clickable
    & > .input-icon-button {
      z-index: var(--z-index-input-icon-button);
    }
  }

  &--disabled {
    .input__box,
    .input__icon {
      opacity: var(--input-disabled-opacity);
    }
  }

  &--error .input__box {
    border: var(--input-error-border);
  }

  &--warning .input__box {
    border: var(--input-warning-border);
  }

  &--success .input__box {
    border: var(--input-success-border);
  }

  &:not(.input--disabled) {
    &.input--has-value .input__box {
      border: var(--input-completed-border);
    }

    .input__box:focus {
      border: var(--input-focus-border);
    }

    &.input--hover .input__box {
      border: var(--input-hover-border);
    }

    &.input--error .input__box {
      border: var(--input-error-border);
    }

    &.input--warning .input__box {
      border: var(--input-warning-border);
    }

    &.input--success .input__box {
      border: var(--input-success-border);
    }

    &.input--has-value,
    &.input--warning,
    &.input--success {
      .input__box:focus {
        border: var(--input-focus-border);
      }
      &.input--hover .input__box {
        border: var(--input-hover-border);
      }
    }
  }

  // Safari credentials icon
  input::-webkit-credentials-auto-fill-button {
    background-color: var(--input-color);
  }

  // Hide arrows from number type input
  input::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  // Needed to prevent chrome from adding a blue background when autofill is activated
  input:-webkit-autofill {
    background-color: var(--input-auto-fill-bg-color) !important;
    box-shadow: var(--input-auto-fill-box-shadow) !important;
    -webkit-text-fill-color: var(--input-color);
  }

  &--dark {
    /* stylelint-disable no-descending-specificity */
    .input__box-container {
      background-color: var(--input-dark-bg-color);
    }

    .input__box {
      border: var(--input-dark-hover-border);
      color: var(--input-dark-color);
    }

    .input__icon {
      color: var(--input-dark-icon-color);
    }

    &.input--success .input__box {
      border: var(--input-dark-success-border);
    }

    &.input--warning .input__box {
      border: var(--input-dark-warning-border);
    }

    &.input--error .input__box {
      border: var(--input-dark-error-border);
    }

    &.input--disabled {
      .input__box,
      .input__icon {
        opacity: var(--input-dark-disabled-opacity);
      }
    }

    &:not(.input--disabled) {
      &.input--has-value .input__box {
        border: var(--input-dark-hover-border);
      }

      .input__box:focus {
        border: var(--input-dark-focus-border);
      }

      &.input--hover .input__box {
        border: var(--input-dark-hover-border);
      }

      &.input--success .input__box {
        border: var(--input-dark-hover-border);
      }

      &.input--warning .input__box {
        border: var(--input-dark-warning-border);
      }

      &.input--error .input__box {
        border: var(--input-dark-error-border);
      }

      &.input--has-value,
      &.input--error,
      &.input--warning,
      &.input--success {
        .input__box:focus {
          border: var(--input-dark-focus-border);
        }
        &.input--hover .input__box {
          border: var(--input-dark-hover-border);
        }
      }
    }

    // Safari credentials icon
    input::-webkit-credentials-auto-fill-button {
      background-color: var(--input-dark-color);
    }

    input:-webkit-autofill {
      box-shadow: var(--input-dark-auto-fill-box-shadow) !important;
      -webkit-text-fill-color: var(--input-dark-color);
    }
    /* stylelint-enable no-descending-specificity */
  }
}
