@import '../../../scss/global/utils/media-queries';

:root {
  --react-daterange-background: white;
  --react-daterange-boxshadow: var(--box-shadow-1);
  --react-daterange-maxwidth: 31rem;
  // input
  --react-daterange-input-gap: var(--size-10);
  --react-daterange-input-margin: 0 0 var(--size-10) 0;
  // buttons
  --react-daterange-button-gap: var(--size-10);
  --react-daterange-button-padding: var(--size-10) var(--size-10) var(--size-20) var(--size-10);
  --react-daterange-button-alignment: flex-end;
  --react-daterange-calendar-padding: var(--navigation-mobile-padding-left) - var(--navigation-mobile-padding-right);
}

.daterange {
  width: 100%;
  margin: auto;

  &__input-container {
    display: flex;
    gap: var(--react-daterange-input-gap);
    margin: var(--react-daterange-input-margin);

    label {
      border-radius: 4px;
      font-weight: var(--text-highlight-weight);
      color: var(--dark-blue-100);
    }

    input,
    label {
      color: var(--sub-nav-pills-color);
      background-color: var(--white);
    }
  }

  &__calendar {
    z-index: 1;
    position: absolute;
    border-radius: var(--size-8);
    width: calc(100% - var(--react-daterange-calendar-padding));
    background: var(--react-daterange-background);
    box-shadow: var(--react-daterange-boxshadow);

    @include respond(tab-port) {
      max-width: var(--react-daterange-maxwidth);
    }

    &--show {
      position: relative;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    gap: var(--react-daterange-button-gap);
    padding: var(--react-daterange-button-padding);

    @include respond(desktop) {
      max-width: calc(var(--react-daterange-maxwidth) + var(--size-56));
    }
  }

  &__warning-text {
    font-size: var(--size-10);
    line-height: var(--size-20);
    margin-left: var(--size-16);
    color: var(--body-30);
  }
}
