@import '../../../../scss/global/utils/mixins';

:root {
  // select
  --select-padding-reduced-height: var(--size-9);
  --select-border-radius-reduced-height: var(--size-4);

  // dropbox container
  --select-dropbox-padding: var(--size-10);
  --select-dropbox-overflow-threshold: var(--size-200);
  --select-dropbox-border: none;
  --select-dropbox-border-radius: var(--size-8);
  --select-dropbox-boxshadow: 0 0 var(--size-8) var(--grey-500);
  --select-dropbox-boxshadow-dark: 0 0 var(--size-8) var(--grey-800);
  --select-dropbox-position-top-reduced-height: var(--size-48);
  --select-dropbox-position-top: var(--size-4);
  --select-dropbox-background-color: var(--white);
  --select-dropbox-disabled-background-color: var(--grey-300);
  --select-dropbox-disabled-color: var(--grey-500);
  --select-dropbox-focus: none;

  // dropbox items
  --select-dropbox-items-height: var(--size-48);
  --select-dropbox-items-border-radius: var(--size-4);
  --select-dropbox-items-image-size: var(--size-32);
  --select-dropbox-items-text-indent: var(--size-10);
  --select-dropbox-items-font-size: inherit;
  --select-dropbox-items-color: inherit;
  --select-dropbox-items-padding-reduced-height: var(--size-6) var(--size-12);

  // dropbox active/inactive
  --select-dropbox-items-active-background-color: var(--grey-200);
  --select-dropbox-items-active-color: var(--primary-900);
  --select-dropbox-items-active-indent: 0;
  --select-dropbox-items-inactive-indent: var(--size-10);

  // scrollbar
  --select-dropbox-overflow-scrollbar-width: var(--size-4);
  --select-dropbox-overflow-scrollbar-track: var(--grey-100);
  --select-dropbox-overflow-scrollbar-thumb: var(--grey-300);
  --select-dropbox-overflow-scrollbar-thumb-hover: var(--grey-500);
}

.select-non-native {
  position: relative;

  &__container {
    background-color: var(--select-background);
    border-radius: var(--select-border-radius);
    position: relative;
  }

  &__chevron {
    @include select-chevron;

    transition: 0.3s;
    cursor: pointer;

    &.inverted {
      transform: scaleY(-1);
    }
  }

  &__input {
    position: relative;
    appearance: none;
    background-color: transparent;
    cursor: pointer;
    width: var(--select-width);
    padding: var(--select-padding);
    min-width: var(--select-min-width);
    border-radius: var(--select-border-radius);
    border: var(--select-border);
    z-index: var(--z-index-select-input);
    font-family: var(--select-font-family);
    font-size: var(--select-font-size);
    font-weight: var(--select-font-weight);
    line-height: var(--select-line-height);
    color: var(--select-input-color);
    outline: var(--select-dropbox-focus);

    &--has-value {
      color: var(--select-input-color-has-value);
      border: var(--select-completed-border);
    }

    &--has-image {
      color: var(--select-input-color-has-value);
      border: var(--select-completed-border);
      text-indent: var(--select-has-image-padding);
    }

    &--reduced-height {
      padding: var(--select-padding-reduced-height);
      border-radius: var(--select-border-radius-reduced-height);
    }

    &--is-placeholder {
      color: var(--select-placeholder-color);
    }

    &:hover {
      color: var(--select-border-hover-color);
      border: var(--select-border-hover-border);
    }

    &:focus {
      outline: var(--select-dropbox-focus);
    }
  }

  &__dropdown {
    position: absolute;
    width: 100%;
    left: 0;
    padding: var(--select-dropbox-padding);
    box-shadow: var(--select-dropbox-boxshadow);
    border: var(--select-dropbox-border);
    border-radius: var(--select-dropbox-border-radius);
    font-size: var(--select-dropbox-font-size);
    background-color: var(--select-dropbox-background-color);
    z-index: var(--z-index-custom-select-dropdown);

    &--dark {
      color: var(--white) !important;
      box-shadow: var(--select-dropbox-boxshadow-dark);
      background: var(--select-dark-bg-color-accent);
    }

    &--top {
      top: 0;
    }

    &--bottom {
      top: var(--select-dropbox-position-top);
      margin-bottom: var(--size-32);
      padding: 0;

      &--reduced-height {
        top: var(--select-dropbox-position-top-reduced-height);
      }
    }
  }

  &__ul {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    width: 100%;
    padding-right: var(--select-dropbox-padding);
    height: var(--select-dropbox-overflow-threshold);
    z-index: var(--z-index-select-options);

    &::-webkit-scrollbar {
      width: var(--select-dropbox-overflow-scrollbar-width);
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--select-dropbox-overflow-scrollbar-track);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--select-dropbox-overflow-scrollbar-thumb);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: var(--select-dropbox-overflow-scrollbar-thumb-hover);
    }
  }

  &__li {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    min-height: var(--select-dropbox-items-height);
    color: var(--select-dropbox-items-color);
    border-radius: var(--select-dropbox-items-border-radius);
    font-size: var(--select-dropbox-items-font-size);
    padding-left: var(--select-dropbox-items-inactive-indent);

    &--dark {
      color: var(--select-dark-color);
    }

    &--active-light {
      background: var(--select-dropbox-items-active-background-color);
      color: var(--select-dropbox-items-active-color);
      padding-left: var(--select-dropbox-items-active-indent);
    }

    &--active-dark {
      background: var(--select-dark-bg-color-highlight);
      color: var(--white);
    }

    &--disabled {
      color: var(--select-dropbox-disabled-color);
    }

    &--reduced-height {
      min-height: inherit;
      padding: var(--select-dropbox-items-padding-reduced-height) !important;
    }

    &--selected-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      & > span {
        color: var(--energy-blue-500);
      }
    }
  }

  &__span {
    margin-left: var(--select-dropbox-items-text-indent);
  }

  &__img {
    margin-left: var(--select-dropbox-items-text-indent);
    width: var(--select-dropbox-items-image-size);
    height: var(--select-dropbox-items-image-size);
  }

  &--disabled {
    opacity: var(--select-disabled-opacity);
    background: var(--select-dropbox-disabled-background-color);
    border: none;

    .select-non-native__input {
      color: var(--select-dropbox-disabled-color);
      cursor: not-allowed;
    }

    .select-non-native__chevron {
      color: var(--select-disabled-chevron-color);
    }
  }

  &--error {
    .select-non-native__input {
      border: var(--select-error-border);
    }

    .select-non-native__chevron {
      color: var(--select-error-chevron-color) !important;
    }
  }

  &--warning {
    .select-non-native__input {
      border: var(--select-warning-border);
    }

    .select-non-native__chevron {
      color: var(--select-warning-chevron-color) !important;
    }
  }

  &--success {
    .select-non-native__input {
      border: var(--select-success-border);
    }

    .select-non-native__chevron {
      color: var(--select-success-chevron-color) !important;
    }
  }

  &--dark {
    .select-non-native__input {
      color: var(--select-dark-color);
    }

    .select-non-native__chevron {
      color: var(--white);
    }
  }
}
