@import '../../global/utils/media-queries';
@import '../../global/utils/mixins';
@import '../abstracts/variables.betzone-uk';

@mixin switch-list {
  .checkbox {
    display: flex;
    border-bottom: 1px solid var(--body-5);
    padding-bottom: var(--size-16);

    &__label {
      flex: 1;
      font-size: var(--size-12);
      margin: 0;
    }

    &__container {
      flex-direction: row-reverse;
    }

    &__inline-icon {
      margin: 0 var(--size-8) 0 0;
    }
  }
}

@mixin circular-carousel-dots($selectedColor: var(--yellow-100)) {
  .carousel-wrapper .carousel__dot {
    height: var(--size-8);
    width: var(--size-8);
    margin-right: var(--size-8);
    background: var(--white);

    &--selected {
      background: $selectedColor;
    }
  }
}

@mixin grid {
  @each $size in $breakpoint-names {
    @include respond($size) {
      padding: var(--modal-#{$size}-vertical-margin) var(--grid-#{$size}-horizontal-margin);
      grid-template-columns: repeat(var(--grid-#{$size}-columns), 1fr);
      gap: var(--grid-#{$size}-gutter);
    }
  }
}

@mixin rounded-page-content {
  border-top-right-radius: var(--size-16);
  border-top-left-radius: var(--size-16);
  background-color: var(--body-30);
  padding: 0 var(--size-16);
}

@mixin align-vertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin align-by-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin align-by-center-with-dimensions($dimension) {
  $dimension-centered: calc(50% - #{$dimension} / 2);

  position: absolute;
  width: $dimension;
  height: $dimension;
  top: $dimension-centered;
  left: $dimension-centered;
}

@mixin styled-scrollbar {
  &::-webkit-scrollbar {
    width: var(--size-16);
  }

  &::-webkit-scrollbar-track {
    background: var(--white);
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 var(--size-10) var(--size-10) var(--grey-200);
    border: solid 3px transparent;
    border-radius: 16px;
  }
}

@mixin modal-item-bold {
  font-weight: var(--paragraph-font-weight-bolder);
  color: var(--body-100);
}

@mixin succes-confirmation-icon {
  display: inline-block;
  vertical-align: sub;
  margin-left: var(--size-16);
  background-image: url($assets-url + '/images/prompts/success.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: var(--size-24);
  height: var(--size-24);
  content: '';
}

@mixin promotions-block-responsive {
  $padding-default: 1rem;

  background-color: var(--body-5);

  @include respond(phone) {
    padding: 1rem 1rem 1rem;
  }

  @include respond(tab-port) {
    padding: 1rem 1.5rem 1rem;
  }

  @include respond(desktop) {
    padding: 1rem 2rem 2.3rem;
  }

  @include respond(extra-big-desktop) {
    padding: 3rem 3.3rem 2rem;
  }

  &__title {
    width: 100%;
    margin-bottom: calc(#{$padding-default} / 4);
    @include respond(desktop) {
      margin-bottom: calc(#{$padding-default} / 2);
    }
  }
}

@mixin cashier-iframe {
  overflow: auto;

  @include respond(phone) {
    height: auto;
  }

  &__iframe {
    height: 99%;
    width: 100%;
    border-radius: var(--size-16) var(--size-16) 0 0;
    overflow-y: auto;

    @include respond(phone) {
      height: 75vh;
    }

    @media #{$phone-landscape} {
      height: 92vh;
    }
  }

  .error-messages {
    position: absolute;
  }
}

@mixin cashier-prompt {
  &__modal,
  &__drawer {
    width: 45rem;
    &-smallFontSize {
      width: 60rem;

      .deposit-content {
        height: 56rem;
      }

      .withdrawal-content {
        height: 30rem;
      }
    }
  }

  &__modal {
    place-self: center;
  }

  &__drawer {
    .touch-drawer {
      &__container,
      &__content {
        width: 100%;

        // Fix for low drawer on phones with big height
        @include respond(phone) {
          min-height: 75vh;
          max-height: 90vh;
        }
      }
    }
  }
}

@mixin scroll-container-no-scrollbars {
  overflow-x: scroll;
  white-space: nowrap;
  -ms-overflow-style: none; /* Internet Explorer, Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }
}

// Safari on iOS < 15 does not support flexbox grid
// https://ppuzio.medium.com/flexbox-gap-workaround-for-safari-on-ios-14-13-and-lower-ffcae589eb69
// https://ishadeed.com/article/flexbox-gap/
@mixin safari-grid {
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    @content;
  }
}

@mixin icon_large {
  img {
    height: 2.5rem;
    width: auto;
    display: block;
    margin: 0.5rem;
  }
}
@mixin icon_small {
  img {
    height: 1rem;
    width: auto;
    display: block;
  }
}
@mixin typography_list($line-height-value, $font-weight-value, $font-size-value) {
  font-size: $font-size-value;
  font-weight: $font-weight-value;
  line-height: $line-height-value;
  &::before {
    content: counters(item, '.');
    counter-increment: item;
    margin-left: calc(-1 * var(--size-24));
    font-size: var(--font-size-12);
    font-weight: 600;
  }
}
