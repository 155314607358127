.account-tile {
  padding: var(--size-16);
  background-color: var(--white);
  border-radius: var(--size-8);

  &--with-box-shadow {
    box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.05);
  }

  &--clickable {
    cursor: pointer;
  }

  &--no-padding {
    padding: 0;
  }
}
