@import '../../../../scss/global/utils/mixins';

.registration-sign-in {
  &__link {
    background-color: var(--white);

    &.button.button--medium.button--link {
      font-weight: var(--font-weight-medium);
      height: fit-content;
      padding: 0;
      color: var(--body-80);
      background-color: var(--primary-900);
      
      @include respond(desktop){
        background-color: var(--white)  
      }
    }
  }
}
