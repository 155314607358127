:root {
  // Switch background
  --switch-background-height: var(--size-24);
  --switch-background-width: 2.75rem;
  --switch-background-border-radius: var(--size-20);
  --switch-background-color: var(--grey-300);
  --switch-background-color-checked: var(--success-color);
  // Switch handle
  --switch-handle-height: var(--size-20);
  --switch-handle-width: var(--size-20);
  --switch-handle-border-radius: var(--size-20);
  --switch-handle-color: var(--white);
}

.switch {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin: 0;
  padding: 0;
  width: var(--switch-background-width);
  height: var(--switch-background-height);

  &__background {
    height: 100%;
    width: 100%;
    background-color: var(--switch-background-color);
    border-radius: var(--switch-background-border-radius);
    box-sizing: border-box;
    display: block;
    flex: 0 0 auto;
    margin: 0;
    position: absolute;
    top: 0;
  }
  &__handle {
    height: var(--switch-handle-height);
    width: var(--switch-handle-width);
    background-color: var(--switch-handle-color);
    border-radius: var(--switch-handle-border-radius);
    display: block;
    margin: 0.125rem;
    padding: 0;
    position: absolute;
    top: 0;
    transition: transform 0.2s ease-in-out;
  }

  &--checked {
    .switch__handle {
      transform: translateX(100%);
    }
    .switch__background {
      background-color: var(--switch-background-color-checked);
    }
  }
}

input.checkbox__input {
  cursor: pointer;
}
