.prompt {
  &--default {
    .touch-drawer {
      &__content {
        .prompt__image {
          width: 100%;
        }
      }
    }
  }

  &--cancellation {
    .touch-drawer {
      &__footer {
        flex-flow: row nowrap;
      }

      &__header {
        padding-bottom: var(--size-12);
      }
    }
  }
}
