@import '../../../../../scss/global/utils/mixins';

.takeabreak-form {
  &__select {
    select,
    .select,
    .select-non-native__input,
    .select__control {
      width: 100%;
    }

    .select-non-native__ul {
      max-height: 100% !important;
    }
  }

  &__duration-options {
    @include scroll-container-no-scrollbars;
  }

  &__duration {
    &:not(:last-child) {
      margin-right: 0.5625rem;
    }
  }

  &__reason-label {
    margin-top: var(--size-24);
    margin-bottom: var(--size-8);
  }
}
