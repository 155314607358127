.reality-checks-section {
  .heading,
  &__subtext,
  &__link span {
    color: var(--white);
  }

  &__subtext {
    margin-bottom: var(--size-12);
    .button {
      padding: 0 var(--size-5);
    }
  }

  &__link {
    vertical-align: baseline;

    span {
      font-size: var(--size-10);
      line-height: var(--size-14);
      font-weight: var(--paragraph-font-weight);
      text-decoration: underline;
    }
  }

  .account-tile-list-item__content {
    justify-content: space-between;

    svg {
      width: var(--size-20);
      height: var(--size-20);
      fill: var(--black)
    }

    .account-tile-list-item__icon {
      margin-right: var(--size-4);
      font-size: var(--font-size-20);
    }

    .paragraph {
      color: var(--body-80);
      font-size: var(--font-size-14);
    }
  }
}
