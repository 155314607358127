@import '../../../../../scss/global/utils/mixins';
$rotateAngle: 45deg;

.hamburger-logo {
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 3;

  &__inner {
    position: relative;
  }

  &__logo {
    padding-top: 6px;
    &--no-click {
      pointer-events: none;
    }
  }

  &__pip {
    position: absolute;
    top: var(--size-2);
    right: 0.438rem;
    z-index: 4;
    outline: 1px solid var(--header-background-color);

    &--limit {
      width: var(--size-16);
      display: flex;
      justify-content: center;
    }

    &--hidden {
      display: none;
    }
  }

  &__hamburger {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    border: none;
    outline: none;
    position: relative;
    max-width: var(--size-24);
    margin: 0 var(--size-16) 0 var(--size-8);
    z-index: 3;

    @include respond(double-extra-small-phone) {
      margin: 0 var(--size-6) 0 0;
    }

    @include respond(extra-small-phone) {
      margin: 0 var(--size-6) 0 0;
    }

    &-image {
      height: 100%;
    }

    .piece {
      background-color: var(--white);
      height: var(--size-2);
      margin: 1px 0;
      transition: 0.2s ease-out;
      border-radius: var(--size-2);
    }

    .top-piece {
      width: 100%;
    }

    .middle-piece {
      width: 83.333%;
    }

    .bottom-piece {
      width: 66.666%;
    }

    &--open {
      &-tablet {
        .top-piece {
          transform-origin: bottom;
          transform: rotateZ($rotateAngle)
            translate(var(--size-4), var(--size-4));
        }

        .middle-piece {
          width: 50%;
          transform-origin: top;
          transform: rotateZ(-$rotateAngle) translate(var(--size-12), 0.188rem);
        }

        .bottom-piece {
          width: 50%;
          transform-origin: bottom;
          transform: translate(0.188rem, -0.375rem) rotateZ(-$rotateAngle);
        }
      }
      &-desktop {
        .middle-piece {
          width: 100%;
          transform-origin: top;
        }

        .bottom-piece {
          width: 100%;
          transform-origin: bottom;
        }
      }
    }
  }
}
