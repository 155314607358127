.withdrawal-check {
  &__heading {
    &.heading--6 {
      margin-bottom: var(--size-8);
      font-weight: var(--heading-font-weight);
    }
  }

  .link {
    display: block;
    margin-bottom: var(--size-8);
  }
}
