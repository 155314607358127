@import '../../../../../scss/global/utils/mixins';

$drawerContentWidth: 32rem;

.game-launcher-menu {
  display: flex;
  flex-direction: column;
  height: 100%;

  .game-session-duration__duration {
    min-width: var(--size-64);
  }

  &__right {
    display: flex;
    > * {
      padding-left: var(--size-8);
      padding-right: var(--size-8);

      .session-net-position__value {
        padding-right: var(--size-8);
      }

      @include respond(tab-land) {
        padding: 0;
      }

      @include respond(phone) {
        padding-right: 0;
      }
      + * {
        &:last-child {
          padding-right: 0;
        }
      }
    }

    .game-session--wrapper {
      display: flex;

      .session-net-position {
        padding: 0 var(--size-16);

        @include respond(tab-port) {
          border-right: 1px solid var(--white);
        }

        @include respond(desktop) {
          border-right: 1px solid var(--white);
        }

        + * {
          padding: 0 var(--size-16);
        }
        &--tablet {
          display: flex;
          justify-content: center;
          border-right: none;
        }

        &--phone {
          display: flex;
          justify-content: center;
          border-right: none;
        }
      }
    }
  }
  &--device {
    &-portrait {
      flex-direction: column;
    }
    &-landscape {
      flex-direction: row;

      span {
        display: flex;
      }

      .game-launcher-menu__right {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .session-net-position__value {
          border-right: none;
        }

        .panic-break-button {
          transform: rotate(270deg);
          position: relative;
          left: 0.5rem;

          @include respond(tab-land) {
            position: relative;
            left: 0;
          }
        }

        .game-session--wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: var(--size-32);

          @include respond(tab-port) {
            padding: 0;
          }
        }
      }
    }
  }
}

.desktop-game-menu {
  .game-launcher-menu {
    &__right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.desktop-game-menu,
.device-game-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--dark-blue-100);
  padding: var(--size-16) var(--size-24);

  &--landscape {
    flex-direction: column;
    padding: var(--size-16) var(--size-8);
  }

  &__menu-button {
    @include icon_small();

    .button__container {
      align-items: baseline;
      text-transform: capitalize;
    }

    &.button--text {
      display: flex;
      align-items: baseline;
      color: var(--white);
      font-size: var(--size-16);

      @include respond(phone) {
        font-size: var(--size-12);
      }

      &:hover {
        color: var(--white);
        text-decoration: none;
      }

      svg {
        width: 0.875rem;
        height: 0.875rem;
        fill: var(--white);

        @include respond(phone) {
          width: 0.625rem;
          height: 0.625rem;
        }
      }
    }
  }

  &__drawer {
    &--mobile {
      .touch-drawer {
        &__container--top {
          width: 100%;
        }

        &__viewport,
        &__content {
          background-color: var(--primary-900);
        }

        &__content {
          max-height: 85%;
        }

        &__bars {
          background-color: var(--beige-15);
        }
      }
    }

    &--landscape,
    &--desktop {
      .touch-drawer {
        &__container--left {
          height: 100%;
        }

        &__content {
          overflow: auto;
          overflow-x: hidden;
          max-width: $drawerContentWidth;
          min-width: $drawerContentWidth;
          background-color: var(--primary-900);
          padding-left: 0;
          margin-left: var(--size-20);

          /* Hide scrollbar for IE, Edge and Firefox */
          -ms-overflow-style: none;
          scrollbar-width: none;

          /* Hide scrollbar for Chrome, Safari and Opera */
          &::-webkit-scrollbar {
            display: none;
          }
        }
        &__bars {
          &--left {
            display: none;
          }
        }
        &__viewport {
          background-color: var(--primary-900);
          border-radius: 0 0 var(--modal-border-radius) var(--modal-border-radius);
          height: auto;
        }
      }
    }
  }

  &__links {
    position: relative;
    padding: 0;
  }
}
