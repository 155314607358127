:root {
  --icon-btn-size-md: 0.8125rem;
  --icon-btn-color-default: var(--grey-500);
  --icon-btn-focus-outline-width: var(--focus-outline-width);
  --icon-btn-focus-outline-offset: var(--focus-outline-offset);
  --icon-btn-disabled-opacity: var(--disabled-opacity);
  // Sizes
  --icon-btn-size-sm: var(--font-size-12);
  --icon-btn-size-md: var(--font-size-16);
  --icon-btn-size-lg: var(--font-size-20);
  --icon-btn-size-xl: var(--font-size-24);
  // Colours
  --icon-btn-color-default: var(--grey-600);
  --icon-btn-color-primary: var(--primary-900);
  --icon-btn-color-accent: var(--accent-600);
}

.icon-button {
  border: 0;
  cursor: pointer;
  background-color: transparent;
  height: 1em;
  width: 1em;
  line-height: 1;

  &--disabled {
    opacity: var(--icon-btn-disabled-opacity);
  }

  &--small {
    font-size: var(--icon-btn-size-sm);
  }

  &--medium {
    font-size: var(--icon-btn-size-md);
  }

  &--large {
    font-size: var(--icon-btn-size-lg);
  }

  &--x-large {
    font-size: var(--icon-btn-size-xl);
  }

  &--inherit {
    font-size: inherit;
  }

  &--color-default {
    color: var(--icon-btn-color-default);
  }

  &--color-primary {
    color: var(--icon-btn-color-primary);
  }

  &--color-accent {
    color: var(--icon-btn-color-accent);
  }

  &--color-inherit {
    color: inherit;
  }
}
