@import '../../../scss/global/utils/mixins';

:root {
  --accordion-card-border-radius: 4px;
  --accordion-card-border: none;
  --accordion-card-box-shadow: var(--box-shadow-1);
  --accordion-next-margin: var(--size-8) 0 0 0;
  --accordion-expanded-next-margin: var(--size-16) 0 0 0;
  --accordion-next-margin-transition: margin 0.1s;
  // Below used as default in sub components
  --accordion-padding: var(--size-10);
  --accordion-margin: var(--size-10);
  // dark theme
  --accordion-dark-header-background: var(--dark-blue-100);
  --accordion-dark-header-color: var(--white);
  --accordion-dark-content-background: var(--dark-blue-75);
  --accordion-dark-content-color: var(--white);
}

.accordion {
  + .accordion {
    margin: var(--accordion-next-margin);
    transition: var(--accordion-next-margin-transition);
  }

  &--expanded {
    + .accordion {
      margin: var(--accordion-expanded-next-margin);
    }
  }

  &--style-card {
    border: var(--accordion-card-border);
    border-radius: var(--accordion-card-border-radius);
    box-shadow: var(--accordion-card-box-shadow);
  }

  &--style-rule {
    .accordion-header {
      background: none;

      &__label {
        flex: none;
      }

      &__inner {
        align-items: center;

        &::before,
        &::after {
          flex: 1;
          content: ' ';
          border-top: var(--default-horizontal-rule);
          height: 0;
        }

        &::before {
          margin-right: var(--size-10);
        }

        &::after {
          margin-left: var(--size-10);
        }
      }

      &--align-left,
      &--align-right {
        .accordion-header__inner {
          display: flex;
        }
      }

      &--align-left {
        .accordion-header__inner {
          &::before {
            display: none;
          }
        }
      }

      &--align-right {
        .accordion-header__inner {
          &::after {
            display: none;
          }
        }
      }

      &--standard {
        .accordion-header__icon {
          margin-left: var(--size-10);
        }
      }
    }
  }

  &--dark-theme {
    background: var(--accordion-dark-content-background);
    color: var(--accordion-dark-header-color);

    .accordion-header {
      background: var(--accordion-dark-header-background);
      color: var(--accordion-dark-header-color);
    }
  }
}
