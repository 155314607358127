:root {
  // shared in native and non-native components
  --select-label-margin: var(--input-label-margin);
  --select-font-size: var(--input-font-size);
  --select-font-weight: var(--input-font-weight);
  --select-focus-outline: var(--focus-outline);
  --select-min-width: var(--size-224);
  --select-padding: var(--input-padding-top) var(--input-padding-right) var(--input-padding-bottom)
    var(--input-padding-left);
  --select-border-radius: var(--input-border-radius, var(--size-4));
  --select-border: var(--input-border);
  --select-border-hover-border: var(--input-border-width) solid var(--grey-500);
  --select-border-hover-color: var(--grey-500);
  --select-disabled-opacity: 0.5;
  --select-has-image-padding: var(--size-32);
  --select-error-border: var(--input-border-width) solid var(--danger-color);
  --select-warning-border: var(--input-border-width) solid var(--warning-color);
  --select-completed-border: var(--input-border-width) solid var(--grey-500);
  --select-success-border: var(--input-border-width) solid var(--success-color);
  --select-input-color: var(--grey-300);
  --select-has-value: var(--body-text-color);
  --select-line-height: var(--input-line-height);
  --select-font-family: var(--input-font-family);
  --select-background: transparent;
  --select-disabled-opacity: var(--disabled-opacity);
  --select-error-chevron-color: var(--danger-color);
  --select-warning-chevron-color: var(--warning-color);
  --select-disabled-chevron-color: var(--accent-900);
  --select-success-chevron-color: var(--success-color);
  --select-placeholder-color: var(--select-input-color);
  --select-width: var(--input-width);

  // dark theme colors
  --select-dark-bg-color: var(--primary-900);
  --select-dark-bg-color-accent: var(--grey-700);
  --select-dark-bg-color-highlight: var(--grey-500);
  --select-dark-color: var(--white);

  // select native
  --select-native-input-color: var(--body-text-color);
  --select-native-dropbox-disabled-background-color: transparent;
  --select-native-dropbox-disabled-color: var(--grey-500);

  // select non-native
  // dropbox container
  --select-dropbox-padding: var(--size-10);
  --select-dropbox-overflow-threshold: var(--size-278);
  --select-dropbox-border-radius: var(--size-4);
  --select-dropbox-boxshadow: 0 0 var(--size-8) var(--grey-200);
  --select-dropbox-boxshadow-dark: 0 0 var(--size-8) var(--grey-800);
  --select-dropbox-position-top: 3.5rem;
  --select-dropbox-background-color: var(--white);
  --select-dropbox-disabled-background-color: transparent;
  --select-dropbox-disabled-color: var(--grey-900);
  --select-dropbox-focus: none;
  --select-dropbox-hover-border: 1px solid var(--grey-900);
  --select-dropbox-width: 16.25rem;

  // dropbox items
  --select-dropbox-items-height: var(--size-48);
  --select-dropbox-items-border-radius: var(--size-4);
  --select-dropbox-items-image-size: var(--size-32);
  --select-dropbox-items-text-indent: 0;
  --select-dropbox-items-font-size: inherit;
  --select-dropbox-items-color: inherit;

  // dropbox active/inactive
  --select-dropbox-items-active-background-color: var(--body-5);
  --select-dropbox-items-active-color: inherit;
  --select-dropbox-items-active-indent: 1.625rem;
  --select-dropbox-items-inactive-indent: 2.25rem;
  --select-dropbox-items-padding: var(--size-8) var(--size-12);

  // scrollbar
  --select-dropbox-overflow-scrollbar-width: 2px;
  --select-dropbox-overflow-scrollbar-track: var(--white);
  --select-dropbox-overflow-scrollbar-thumb: var(--body-10);
  --select-dropbox-overflow-scrollbar-thumb-hover: var(--body-20);
}

.select {
  &--dark {
    background-color: var(--select-dark-bg-color);
  }

  &__control {
    position: relative;
    display: inline-flex;
    width: var(--select-width);
    flex-direction: column;
  }

  &__dropdown {
    position: relative;
  }

  &__alerts {
    z-index: var(--z-index-select-input);
  }

  &__label {
    margin: var(--select-label-margin);
  }
}

.select-non-native__ul {
  max-height: var(--select-dropbox-overflow-threshold);
  height: auto;
  padding: 0;
}

.select-non-native__li {
  padding: var(--select-dropbox-items-padding);

  .select-non-native__span {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.select-non-native,
.select-native {
  border-radius: var(--select-border-radius);
}

.select-non-native__input,
.select-native__select {
  font-family: var(--input-font-family);
}

.select-non-native__input:hover {
  border: var(--select-dropbox-hover-border);
}

.select-non-native__chevron svg,
.select-native__chevron svg {
  color: var(--body-40);
}

.select-non-native {
  &--error {
    .select-non-native__dropdown {
      margin-top: var(--size-20);
    }
  }
}
