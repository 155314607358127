:root {
  --modal-header-padding: var(--size-16) var(--size-24) var(--size-8);
  --modal-heading-align: left;
}

.modal-header {
  padding: var(--modal-header-padding);

  &__heading {
    text-align: var(--modal-heading-align);
  }
}
