:root {
  // Add button specific style overrides here
  --btn-font-weight: 700;
  --btn-border-radius: 4px;
  --btn-x-large-line-height: var(--font-size-24);
  --btn-x-large-font-size: var(--font-size-16);
  --btn-large-font-size: var(--font-size-14);
  --btn-medium-font-size: var(--font-size-12);
  --btn-small-font-size: var(--font-size-10);
  --btn-x-large-width: auto;
  --btn-large-width: auto;
  --btn-medium-width: auto;
  --btn-small-line-height: var(--font-size-14);
  --btn-large-line-height: var(--font-size-20);

  // 15,23 so that height is matching figma + border
  --btn-large-padding-y: 0.9375rem;
  --btn-x-large-padding: var(--btn-large-padding-y) 1.4375rem;
  // 13, 19
  --btn-large-padding: 0.8125rem 1.1875rem;
  // 10, 15
  --btn-medium-padding: var(--size-10) 0.9375rem;
  // 8, 11
  --btn-small-padding: var(--size-8) 0.6875rem;
  --btn-border-width: 1px;

  // Extra Large Icons
  --btn-x-large-icon-size: var(--font-size-16);
  --btn-x-large-icon-margin: var(--size-8);

  // Large Icons
  --btn-large-icon-size: var(--font-size-14);
  --btn-large-icon-margin: var(--size-4);

  // Medium Icons
  --btn-medium-icon-size: var(--font-size-12);
  --btn-medium-icon-margin: var(--size-4);

  // Small Icons
  --btn-small-icon-size: var(--font-size-10);
  --btn-small-icon-margin: var(--size-4);

  // Calculations
  --btn-large-height: calc(
    var(--btn-large-line-height) + (2 * var(--btn-large-padding-y)) + (2 * var(--btn-border-width))
  );
  
  // Primary
  --btn-primary-bg-color: #09fb02;
  --btn-primary-color: var(--body-100);
  --btn-primary-border-color: #58fa26;

  // Primary-hover
  --btn-primary-hover-bg-color: var(--body-50);
  --btn-primary-hover-border-color: var(--body-50);
  --btn-primary-hover-color: var(--body-1);
  // Primary-focus
  --btn-primary-focus-bg-color: #74f64b;
  --btn-primary-focus-border-color: #74f64b;
  --btn-primary-focus-color: var(--body-100);
  // Primary-pressed
  --btn-primary-pressed-bg-color: var(--body-70);
  --btn-primary-pressed-border-color: var(--body-70);
  --btn-primary-pressed-color: var(--body-5);
  // Primary-disabled
  --btn-primary-disabled-bg-color: rgba(87, 87, 87, 0.7);
  --btn-primary-disabled-border-color: rgba(87, 87, 87, 0.5);
  --btn-primary-disabled-color: rgba(194, 194, 194, 0.5);

  // Secondary
  --btn-secondary-bg-color: transparent;
  --btn-secondary-color: var(--dark-blue-100);
  --btn-secondary-border-color: var(--dark-blue-100);
  // Secondary-hover
  --btn-secondary-hover-bg-color: var(--dark-blue-100);
  --btn-secondary-hover-border-color: var(--dark-blue-100);
  --btn-secondary-hover-color: var(--white);
  // Secondary-focus
  --btn-secondary-focus-bg-color: var(--dark-blue-100);
  --btn-secondary-focus-border-color: var(--dark-blue-100);
  --btn-secondary-focus-color: var(--white);
  // Secondary-pressed
  --btn-secondary-pressed-bg-color: var(--dark-blue-100);
  --btn-secondary-pressed-border-color: var(--dark-blue-100);
  --btn-secondary-pressed-color: var(--white);
  // Secondary-disabled
  --btn-secondary-disabled-bg-color: var(--dark-blue-25);
  --btn-secondary-disabled-border-color: var(--dark-blue-25);
  --btn-secondary-disabled-color: var(--dark-blue-25);

  // Secondary Alt - Usually used on dark backgrounds
  --btn-secondary-alt-bg-color: transparent;
  --btn-secondary-alt-color: var(--white);
  --btn-secondary-alt-border-color: var(--white);
  // Secondary Alt-hover
  --btn-secondary-alt-hover-bg-color: var(--white);
  --btn-secondary-alt-hover-border-color: var(--white);
  --btn-secondary-alt-hover-color: var(--dark-blue-100);
  // Secondary Alt-focus
  --btn-secondary-alt-focus-bg-color: var(--white);
  --btn-secondary-alt-focus-border-color: var(--white);
  --btn-secondary-alt-focus-color: var(--dark-blue-100);
  // Secondary Alt-pressed
  --btn-secondary-alt-pressed-bg-color: var(--white);
  --btn-secondary-alt-pressed-border-color: var(--white);
  --btn-secondary-alt-pressed-color: var(--dark-blue-100);
  // Secondary Alt-disabled
  --btn-secondary-alt-disabled-bg-color: var(--dark-blue-50);
  --btn-secondary-alt-disabled-border-color: var(--dark-blue-25);
  --btn-secondary-alt-disabled-color: var(--dark-blue-25);

  // Success
  --btn-success-bg-color: var(--success-color);
  --btn-success-color: var(--dark-blue-100);
  --btn-success-border-color: var(--success-color);
  // Success-hover
  --btn-success-hover-bg-color: var(--success-dark);
  --btn-success-hover-border-color: var(--success-dark);
  --btn-success-hover-color: var(--dark-blue-100);
  // Success-focus
  --btn-success-focus-bg-color: var(--success-dark);
  --btn-success-focus-border-color: var(--success-dark);
  --btn-success-focus-color: var(--dark-blue-100);
  // Success-pressed
  --btn-success-pressed-bg-color: var(--success-dark);
  --btn-success-pressed-border-color: var(--success-dark);
  --btn-success-pressed-color: var(--dark-blue-100);
  // Success-disabled
  --btn-success-disabled-bg-color: var(--success-light);
  --btn-success-disabled-border-color: var(--success-light);
  --btn-success-disabled-color: var(--dark-blue-50);

  // Text styles
  --btn-text-color: var(--dark-blue-100);
  --btn-text-border: none;
  --btn-text-hover-color: var(--dark-blue-100);
  --btn-text-active-color: var(--dark-blue-100);
  --btn-text-disabled-color: var(--dark-blue-50);

  // Text button for dark backgrounds
  --btn-text-alt-color: var(--white);
  --btn-text-alt-hover-color: var(--white);
  --btn-text-alt-active-color: var(--white);
  --btn-text-alt-disabled-color: var(--dark-blue-50);
}

.button {
  &--full-width {
    width: 100% !important;
  }
}
