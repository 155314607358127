@import '../../../../../scss/global/utils/mixins';

.game-search__popup {
  .modal-header {
    padding-left: var(--size-24);

    .heading {
      text-align: left;
    }
  }
  .modal-body__content {
    padding-right: 0;
  }
}

.game-search-spine-zone {
  &__inner {
    .game-search {
      max-width: 100%;

      &-bar {
        display: flex;
        margin-bottom: var(--size-20);

        div.input {
          width: 100%;
          @include respond(tab-port) {
            margin-right: var(--size-24);
          }
        }
      }

      .carousel-wrapper {
        &__title {
          color: var(--white);
        }
      }

      .recommended-game-carousel {
        margin-left: 0;
      }
    }
  }

  &__content {
    margin-top: 1rem;

    .carousel-wrapper__title {
      color: var(--dark-blue-100);
      font-size: var(--font-size-16);
      line-height: var(--font-size-24);

      @include respond(desktop) {
        font-size: var(--font-size-20);
        line-height: var(--font-size-30);
      }
    }

    .carousel-wrapper__subtitle {
      font-size: var(--font-size-10);
      line-height: var(--font-size-14);
      color: var(--dark-blue-100);

      @include respond(desktop) {
        font-size: var(--font-size-14);
        line-height: var(--font-size-20);
      }
    }
  }

  &__result {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include respond(tab-port) {
      justify-content: flex-start;
    }

    @include respond(desktop) {
      justify-content: flex-start;
    }
  }

  &__card {
    min-width: 48%;
    max-width: 49%;
    margin-bottom: 1%;
    height: 7rem;

    &:nth-child(3n + 2) {
      margin-right: 0;
      margin-left: 0;
    }

    @include respond(tab-port) {
      min-width: 32%;
      max-width: 32%;
      height: 7rem;

      &:nth-child(3n + 2) {
        margin-right: 1%;
        margin-left: 1%;
      }
    }

    @include respond(desktop) {
      min-width: 32%;
      max-width: 32%;
      height: 8.5rem;

      &:nth-child(3n + 2) {
        margin-right: 1%;
        margin-left: 1%;
      }
    }
  }

  &__popup {
    .modal-header {
      .heading {
        font-size: var(--size-16);
        line-height: var(--size-24);
        color: var(--body-100);
      }
    }
  }
}
