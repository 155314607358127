:root {
  --input-helper-text-color: var(--grey-700);
  --input-helper-text-color-dark: var(--grey-200);
}

.input-helper-text {
  color: var(--input-helper-text-color);
  &--dark {
    color: var(--input-helper-text-color-dark);
  }
  &--disabled {
    opacity: var(--disabled-opacity);
  }
}
