@import '../../../../scss/global/utils/mixins';

:root {
  --account-details-form-margin: 0;
  --account-details-form-actions-margin: 0;
}

.account-details-edit-modal {
  &__field-sets {
    @include switch-list();

    .field-set {
      margin-bottom: 0;

      &:first-of-type {
        .paragraph--s {
          margin-bottom: 1.625rem;
        }
      }
    }

    .grid>.grid-item:last-of-type {
      .checkbox {
        border-bottom: 0;
      }
    }

    .radio-group {
      margin-top: 0.125rem;
    }

    .field-set:last-of-type>.grid>.grid-item:last-of-type {
      .form-control-spacing {
        margin: 0 0 1.125rem;
      }
    }
  }

  &.touch-drawer {
    .touch-drawer {
      &__content {
        height: auto;
        overflow: visible;
        min-height: var(--size-112);
      }

      &__container {
        .touch-drawer__viewport {
          height: auto;
          justify-content: space-between;
        }

        .account-details-form {
          &__actions {
            justify-content: flex-end;

            .button:last-of-type {
              margin-left: var(--size-8);
            }
          }
        }
      }
    }
  }
}

.contact-details-edit-modal {
  .modal-body__content {
    overflow: visible;
  }
}

.account-details-form {
  &__actions {
    justify-content: flex-end;

    .button:last-of-type {
      margin-left: var(--size-8);
    }
  }
}

.modal-body__content {
  overflow-y: visible;
  min-height: var(--size-151);
}