.tddi-container {
  display: flex;
  gap: var(--size-8);
  margin-bottom: var(--size-4);
}

.tddi {
  display: flex;
  align-items: stretch;
  justify-items: flex-start;
  gap: var(--size-8);

  &__number {
    display: flex;
    align-self: center;
    width: var(--size-4);
    font-weight: var(--paragraph-highlight-font-weight);
    font-size: var(--size-10);
  }

  &__marker {
    border-radius: var(--size-6);
    width: var(--size-3);

    $marker-colors: (
      'lost': var(--error),
      'open': var(--warning),
      'won': var(--success-dark),
      'default': var(--body-50)
    );

    @each $status, $color in $marker-colors {
      &--#{$status} {
        background-color: $color;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    font-size: var(--size-10);
    color: var(--body-100);
  }

  &__title {
    font-weight: var(--paragraph-highlight-font-weight);
  }

  &__sublabel {
    font-size: var(--size-8);
  }

  &__coupon {
    align-self: flex-end;
  }

  &__coupon-details {
    display: block;
    font-size: var(--size-10);
    font-weight: var(--text-highlight-weight-tabular);
    color: var(--body-90);
  }
}
