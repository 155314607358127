:root {
  // item
  --tdl-item-padding: var(--size-16);
  --tdl-item-padding-small: var(--size-8);
  --tdl-item-background: var(--white);
  --tdl-item-margin-bottom: var(--size-8);
  --tdl-item-border-radius: var(--size-10);
  --tdl-item-focus-outline: var(--primary-100);
  // content
  --tdl-item-content-margin-top: var(--size-2);
  --tdl-item-content-padding: var(--size-8) var(--size-16) var(--size-16);
}

.tdl-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--tdl-item-background);
  margin-bottom: var(--tdl-item-margin-bottom);
  border-radius: var(--tdl-item-border-radius);
  border: none;
  outline: none;

  &:focus {
    border: none;
    outline: 1px solid var(--tdl-item-focus-outline);
  }

  &--clickable {
    cursor: pointer;
  }

  &--last {
    margin-bottom: 0;
  }

  &__block {
    width: 100%;
    display: flex;
    padding: var(--tdl-item-padding);

    &--show {
      padding-bottom: var(--tdl-item-padding-small);
    }
  }

  &__panel {
    flex: 1 1 auto;
    font-weight: var(--text-highlight-weight-tabular);
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0 0 0 var(--tdl-item-padding);

    &:focus {
      border: none;
      outline: none;
    }
  }

  &__content {
    width: 100%;
    display: none;
    padding: var(--tdl-item-content-padding);
    margin-top: var(--tdl-item-content-margin-top);
    padding-top: var(--tdl-item-content-margin-top);

    &--show {
      display: flex;
    }
  }

  &__cancel-withdraw {
    display: grid;
    padding: var(--tdl-item-content-padding);
    padding-top: 0;
  }
}
