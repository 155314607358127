:root {
  --content-preview-overlay-background: linear-gradient(0deg, var(--white) 0%, rgba(255, 255, 255, 0) 100%);
  --content-preview-overlay-height: 3.75rem;
}

.content-preview {
  &__content {
    overflow: hidden;
    position: relative;

    &--preview {
      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: var(--content-preview-overlay-height);
        background: var(--content-preview-overlay-background);
        pointer-events: none;
      }
    }
  }

  &__buttons {
    text-align: center;
  }
}
