.promotion-available-block {
  position: absolute;
  display: flex;
  align-items: center;
  top: var(--size-16);
  left: var(--size-16);
  background-color: var(--white);
  padding: var(--size-4);
  border-radius: 0.35rem;

  > span {
    color: var(--dark-blue-100);
    font-weight: var(--paragraph-highlight-font-weight);
    font-size: var(--paragraph-xs-font-size);
    margin-right: var(--size-4);
  }

  svg {
    margin-bottom: var(--size-2);

    circle {
      color: var(--white);
    }
  }

  &__badge:not(.any-class) {
    width: auto;
    height: auto;
    position: relative;
  }
}
