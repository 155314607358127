@import '../../../../scss/global/utils/mixins';

.welcome-bonus {
  // styles for mobile app only
  &.drawer {
    &--native-app {
      .touch-drawer {
        &__viewport {
          max-height: 100%;
          flex-direction: column;
        }

        &__container {
          height: 100%;
        }

        &__content {
          max-height: fit-content;
          min-height: fit-content;
        }

        &__footer {
          padding-bottom: 0;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-evenly;
    text-align: right;
    margin-top: var(--size-5);

     .button.button--secondary {
       border: none;
     }

    @include respond(phone) {
      text-align: center;

      .button.button--large {
        &.button--secondary,
        &.button--primary {
          padding-right: var(--size-16);
          padding-left: var(--size-16);
        }
      }
    }

    @include respond(desktop) {
      margin-bottom: 0;
    }

    button:first-child {
      margin-right: var(--size-27);
    }
  }

  &__image-container {
    overflow: hidden;
    margin: auto;
    margin-bottom: var(--size-20);

    img {
      border-radius: var(--size-10);
      max-width: 100%;
    }
  }

  &__step {
    display: flex;
    align-items: center;
    margin: var(--size-20) 0 var(--size-12);

    & p {
      font-weight: var(--paragraph-highlight-font-weight);
    }

    @include respond(phone) {
      margin:  var(--size-10) 0 var(--size-12);
    }
  }

  &__circle {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: var(--size-36);
    height: var(--size-36);
    background: linear-gradient(
      90deg,
      var(--yellow-75) 50%,
      var(--yellow-100) 50%
    );
    margin-right: var(--size-16);
    font-size: var(--font-size-16);
    font-weight: 800;
    padding: var(--size-16);
  }

  &__TnC-button {
    margin: var(--size-20) 0 var(--size-8);
    border: none;
    border-bottom: 1px solid var(--body-100);
    background: transparent;
    cursor: pointer;

    @include respond(phone) {
      margin: 0 0 var(--size-8);
    }
  }

  &-TnC {
    width: 90%;
    margin: var(--size-10) auto;

    .aem-text {
      li {
        &::before {
          margin-left: calc(-1 * var(--size-24));
        }
        li {
          margin-left: var(--size-28);
          &::before {
            margin-left: calc(-1 * var(--size-32));
          }
        }
      }
    }

    a {
      color: var(--black);
      font-weight: var(--paragraph-highlight-font-weight);
      text-decoration: underline;
    }
  }

  .welcome-bonus-TnC-back {
    &.button {
      &.button--primary {
        width: 100%;
        margin-bottom: var(--size-10);

        @include respond(desktop) {
          margin-bottom: 0;
        }
      }
    }
  }

  &__min-max-amount {
    font-size: var(--size-10);
    line-height: var(--size-14);

    div {
      color: var(--body-50);
      display: inline-block;

      &:nth-child(2) {
        float: right;
        text-align: right;
      }

      span {
        display: block;
      }
    }
  }
}
