@import '../../../scss/global/utils/mixins';

.game-category-options {
  padding: var(--size-8) var(--size-16);
  @include scroll-container-no-scrollbars;

  @include respond(desktop) {
    padding: 0 0 var(--size-8);
  }

  &__icon {
    height: var(--size-12);
    width: var(--size-12);
    margin-right: var(--size-8);
  }

  &__button {
    margin-right: var(--size-8);

    @include respond(phone) {
      --btn-secondary-medium-padding: var(--size-6) 1.4375rem;
    }
  }
}
