.content-page {
  color: var(--body-10);

  .heading {
    color: var(--body-10);
  }

  li {
    margin-left: 2rem;
    list-style: circle;
  }

  a {
    font-weight: bold;
    color: var(--body-10);
    text-decoration: underline;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    min-height: 5px;
    margin-bottom: 10px;
  }
}
