.my-account-activity-panel {
  display: flex;
  flex-direction: column;

  &__activity-header {
    display: flex;
    align-items: center;
    gap: var(--size-4);
    font-weight: var(--text-highlight-weight);
    font-size: var(--font-size-14);
    line-height: var(--size-20);
    margin-bottom: var(--size-8);

    .pill {
      --pill-default-span-color: var(--body-60);
      --pill-default-text-transform: uppercase;
      --pill-small-font-size: var(--size-8);
      --pill-small-height: var(--size-18);

      background-color: var(--body-5);
      margin-left: var(--size-8);
    }
  }

  &__title {
    display: flex;
    align-items: center;
  }

  &__amount {
    text-align: right;
    flex: 1;
  }

  &__transaction-date {
    align-self: flex-end;
    font-size: var(--font-size-10);
    color: var(--body-40);
    margin-right: auto;
  }

  &__subheading {
    display: flex;
    justify-content: space-between;
    font-size: var(--font-size-10);
    line-height: var(--size-14);
    color: var(--body-40);
  }

  &__balances {
    display: flex;
    flex-direction: column;
    text-align: right;
  }

  &__activity-details {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: var(--size-4);
  }
}
