@import '../../../scss/global/utils/mixins';

.gambling-controls {
  background-color: var(--primary-900);

  &__header {
    padding: var(--size-4) var(--size-16) var(--size-40);
    background-color: var(--background-account-wrapper);

    h3 {
      color: var(--white);
    }
  }

  &__section-heading,
  &__section-description {
    color: var(--white);
  }

  &__section-heading {
    margin: var(--size-20) 0 var(--size-12);
  }

  &__section-description {
    margin-bottom: var(--size-16);
  }

  &__content {
    @include rounded-page-content();

    width: 100%;
    padding: var(--size-24) var(--size-16) var(--size-24);
    margin-top: -1rem;
    // 120px is a header height
    min-height: calc(100% - 120px);

    .account-closure {
      .heading,
      &__subtext,
      &__link {
        color: var(--body-60);
      }

      &__subtext {
        margin-bottom: var(--size-12);
      }

      &__link {
        vertical-align: baseline;
        font-size: var(--size-10);
        line-height: var(--size-14);
        font-weight: var(--paragraph-font-weight);
        text-decoration: underline;
        padding: 0 var(--size-5);

        &:hover {
          color: var(--body-60);
          text-decoration: underline;
        }
      }

      .account-tile {
        margin-bottom: var(--size-8);

        &-list-item__content {
          justify-content: space-between;

          svg {
            font-size: var(--size-12);
          }
        }
      }
    }
  }

  &__link {
    cursor: pointer;
    font-family: var(--body-font-family);
    font-size: var(--paragraph-xs-font-size);
    color: var(--paragraph-color);
    font-weight: var(--heading-font-weight);
    text-decoration: underline;
    outline: none;
    background: none;
    border: none;
  }

  &__close-heading {
    margin-top: var(--size-24);
    color: var(--body-60);
    margin-bottom: var(--size-8);
  }
}
