@import '../../../scss/global/utils/media-queries';

:root {
  --cta-panel-border-radius-s: var(--size-8);
  --cta-panel-border-radius-m: var(--size-16);
  --cta-panel-border-radius-l: var(--size-24);
  --cta-panel-paragraph-color: var(--yellow-100);
  --cta-panel-heading-color: var(--white);
  --cta-panel-heading-margin: 0 0 0.1875rem;
  --cta-panel-fixed-width-portrait: 23rem;
  --cta-panel-fixed-width-landscape: 48rem;
  --cta-panel-background-color: var(--grey-1000);
}

.cta-panel {
  padding: var(--size-24);
  display: flex;
  flex-direction: column;
  text-align: center;

  &__text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: var(--size-16);

    .heading {
      color: var(--cta-panel-heading-color);
      margin: var(--cta-panel-heading-margin);
    }
    .paragraph {
      color: var(--cta-panel-paragraph-color);
      margin-bottom: 0;
      font-weight: bold;
    }
  }

  &--orientation-auto {
    @include respond(tab-port) {
      flex-direction: row;
      text-align: initial;
      align-items: center;
    }

    .cta-panel__text {
      @include respond(tab-port) {
        margin-bottom: 0;
      }
    }
  }

  &--orientation-landscape {
    flex-direction: row;
    text-align: initial;

    .cta-panel__text {
      margin-bottom: 0;
    }
  }

  &--width-fixed {
    width: var(--cta-panel-fixed-width-landscape);

    &.cta-panel--orientation-portrait {
      width: var(--cta-panel-fixed-width-portrait);
    }
  }

  &--width-fill {
    width: 100%;
  }

  &--border-radius {
    &-s {
      border-radius: var(--cta-panel-border-radius-s);
    }

    &-m {
      border-radius: var(--cta-panel-border-radius-m);
    }

    &-l {
      border-radius: var(--cta-panel-border-radius-l);
    }
  }
}
