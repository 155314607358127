:root {
  --radio-label-margin: 0 0 0 var(--size-4);
  --radio-icon-size: var(--font-size-24);
  --radio-label-size: var(--label-size);
  --radio-label-font-family: inherit;
  --radio-label-font-weight: inherit;
  --radio-icon-color: var(--grey-900);
  --radio-icon-color-checked: var(--grey-900);
  --radio-label-color: var(--label-color);
  --radio-disabled-opacity: var(--disabled-opacity);
  --radio-focus-outline: var(--focus-outline);
}

.radio {
  position: relative;
  display: inline-flex;
  align-items: center;

  &--disabled {
    opacity: var(--radio-disabled-opacity);
  }

  &__icon {
    display: flex;
    padding: 1px;
    font-size: var(--radio-icon-size);
    color: var(--radio-icon-color);
    border: var(--focus-outline-width) solid transparent;
    border-radius: 100%;
    transition: 0.1s;

    &--checked {
      color: var(--radio-icon-color-checked);
    }
  }

  &__input {
    top: 0;
    left: 0;
    width: 100%;
    cursor: pointer;
    height: 100%;
    margin: 0;
    opacity: 0;
    padding: 0;
    z-index: var(--z-index-radio-input);
    position: absolute;
  }

  &__label {
    margin: var(--radio-label-margin);
    font-family: var(--radio-label-font-family);
    font-size: var(--radio-label-size);
    font-weight: var(--radio-label-font-weight);
    color: var(--radio-label-color);
  }
}
