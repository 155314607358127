// Reassigned styles
.limit-confirmation {
  .prompt {
    &__content {
      text-align: left;
      margin-top: var(--size-8);

      .heading {
        margin-bottom: var(--size-16);
      }
    }
  }
}
