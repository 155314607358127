.account-details-summary {
  font-size: var(--size-14);
  line-height: var(--size-20);

  .label {
    font-weight: var(--paragraph-font-weight);
    font-size: var(--font-size-10);
    line-height: var(--font-size-14);
    margin-bottom: 0.125rem;
  }

  &__detail-grid-item {
    text-decoration: none;
    
    span:last-of-type {
      font-weight: var(--text-highlight-weight);
    }
  }

  &__detail-grid-item-value {
    display: flex;
    align-items: center;

    svg {
      height: var(--size-16);
      margin-right: var(--size-8);
    }
  }

  &__marketing-subscriptions,
  &__contact-subscriptions {
    .label {
      margin-bottom: var(--size-8);
    }
    .paragraph:last-of-type {
      margin-bottom: 0;
    }

    + div {
      &.grid {
        margin-top: var(--size-16);
      }
    }
  }

  &__marketing-subscription,
  &__contact-subscription {
    margin-right: var(--size-24);
    display: inline-flex;
    align-items: center;
    font-weight: var(--text-highlight-weight);

    svg {
      color: var(--body-40);
      margin-right: var(--size-8);
      font-size: var(--size-16);
      max-height: var(--size-16);
      max-width: var(--size-16);
    }
  }
}
