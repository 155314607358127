@import '../../../../../scss/global/utils/mixins';

.registration-abandon-prompt {
  &__message {
    a {
      @include paragraphStyles(s);

      text-decoration: underline;
      margin-bottom: 0;
    }
  }
}
