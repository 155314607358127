:root {
  --telephone-input-select-width: var(--size-128);
  --telephone-filterable-label-floating-left: var(--country-code-toggle-width);
  --telephone-filterable-input-padding-left: var(--country-code-toggle-width);
}

.telephone-input {
  --loading-indicator-left: auto;
  --loading-indicator-right: var(--size-8);
  --loading-indicator-top: var(--size-10);
  --loading-indicator-transform: none;
  --loading-indicator-icon-font-size: var(--size-28);

  display: flex;
  position: relative;

  &__select {
    width: var(--telephone-input-select-width);
    margin-right: var(--size-12);

    .select {
      width: 100%;
    }

    .select__control {
      display: flex;
    }

    .select-native__select {
      width: 100%;
      min-width: var(--telephone-input-select-width);
    }

    .select-non-native__input {
      min-width: var(--telephone-input-select-width);
      width: var(--telephone-input-select-width);
    }

    .select-non-native__dropdown {
      width: fit-content;
      z-index: 3;
      top: var(--size-48)
    }

    .select-non-native__ul {
      max-height: var(--size-151);
    }
  }

  &__input {
    flex: 1;
  }

  &--filterable {
    --label-floating-left: var(--telephone-filterable-label-floating-left);

    .telephone-input__input {
      --input-padding-left: var(--telephone-filterable-input-padding-left);
    }
  }

  &--validating {
    pointer-events: none;

    .telephone-input__input {
      .input__box {
        padding-right: var(--size-40);
      }
    }
  }
}