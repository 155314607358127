.cookie-details-item {
  margin-bottom: var(--size-8);
  border-radius: var(--size-8);
  padding: var(--size-20) var(--size-16);

  &__field {
    color: var(--body-80);
    margin-bottom: var(--size-8);

    * {
      display: inline;
    }
  }

  &:last-child,
  &__field:last-child {
    margin-bottom: 0;
  }

  &__key {
    color: var(--body-60);
    margin-right: var(--size-4);
  }
}
