@import '../../../scss/global/utils/mixins';
@import '../../../scss/global/abstracts/scss-variables';

.typography {
  color: var(--typography-color);

  @each $size in $typography-sizes {
    &--#{$size} {
      @include typographySize($size);
    }
  }

  &--highlight {
    font-weight: var(--paragraph-highlight-font-weight);
  }
}
