:root {
  // carousel
  --carousel-zindex: var(--z-index-carousel);
  --carousel-active-zindex: var(--z-index-carousel-active);
  --carousel-button-zindex: var(--z-index-carousel-button);
  // header
  --carousel-header-color: var(--dark-blue-100);
  --carousel-header-padding: var(--size-10) 0 var(--size-16) var(--size-10);
  // title
  --carousel-title-size: var(--font-size-20);
  --carousel-title-weight: 700;
  // empty
  --carousel-empty-color: var(--white);
  --carousel-empty-padding: var(--size-16) 0;
  // subtitle
  --carousel-subtitle-size: var(--font-size-10);
  --carousel-subtitle-weight: 600;
}

.carousel-wrapper {
  position: relative;
  overflow: visible;
  z-index: var(--carousel-zindex);
  width: 100%;

  .carousel__slide-inner {
    position: unset;
    display: block;
    margin-bottom: 0;
  }


  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }

  &:hover {
    z-index: var(--carousel-active-zindex);
  }

  &--active {
    z-index: var(--carousel-active-zindex);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--carousel-header-padding);
    color: var(--carousel-header-color);
  }

  &__title {
    margin: 0;
    padding: 0;
    font-size: var(--carousel-title-size);
    font-weight: var(--carousel-title-weight);
  }

  &__subtitle {
    margin: 0;
    padding: 0;
    font-size: var(--carousel-subtitle-size);
    font-weight: var(--carousel-subtitle-weight);
  }

  &__slide-wrapper {
    position: relative;
  }

  &__empty {
    color: var(--carousel-empty-color);
    padding: var(--carousel-empty-padding);
  }

  section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    .button--text {
      outline: none;
      color: var(--btn-primary-bg-color);
      font-size: 16px;
      &:hover {
        color: var(--btn-primary-hover-bg-color);
      }
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    width: 100%;

    &-item {
      cursor: pointer;
      transition: transform 0.2s ease;

      &:hover {
        transform: scale(1.02);
      }
    }
  }
}