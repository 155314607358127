.game-session-duration {
  min-width: 5rem;
  display: flex;
  align-items: center;
  padding-left: var(--size-8);

  &__duration {
    color: var(--white);
    margin-left: var(--size-6);
  }

  svg {
    fill: var(--white);
  }
}
