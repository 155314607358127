@import '../../../../scss/global/utils/mixins';

:root {
  --registration-form-actions-margin: 0 0 var(--size-16);
}

.registration-form-step {
  &--submission {
    .button.button--success .button__icon-right {
      display: none;
    }

    #registration-details{
      .telephone-input{
        &__input{
          .input__box {
            background-color: var(--white);
            color: var(--input-color);
          }
        }
      }
    }
  }

  &__enter {
    font-size: var(--size-14);
    line-height: var(--size-20);
    font-weight: 500;
    text-align: right;
    color: var(--space-blue-100);

    @include respond(desktop) {
      display: block;
    }
  }

  &__enter-icon {
    color: var(--grey-600);
    vertical-align: middle;
    display: inline-block;
    margin-left: 0.25rem;
  }

  .address-form-step {
    &--address-fields-hidden {
      fieldset[id*='address-details'] {
        display: none;
      }
    }

    &--address-menu-open {
      .registration-form-step-actions,
      + .registration-form-step__enter {
        display: none;
      }

      .field-set {
        display: none;
      }
    }
  }

  &__manual-edit-address {
    display: flex;
    margin-top: var(--size-24);

    .heading--6 {
      flex: 1;
      color: var(--white);

      @include respond(desktop) {
        color: var(--body-100);
      }
    }
  }

  .form {
    margin-top: var(--size-24);

    .select-non-native__input {
      background-color: var(--white);
      color: var(--input-color);
    }
  }

  #personal-details-form,
  #marketing-details-form,
  #address-details-form {
    .registration-form-step-actions {
      margin-top: var(--size-24);

      &__wrapper{
        
        .button:not(.button--primary) {
          color: var(--white);
          width: var(--btn-small-width);
          border-radius: var(--btn-large-border-radius);
          padding: var(--btn-large-padding);

          @include respond(desktop) {
            color: var(--btn-text-color);
          }
        }

        .button{
          &.button--success{
            background-color: var(--btn-primary-bg-color); 
          }

          &.button--disabled {
            color: var(--white);
            border-color: var(--primary-700);
            background-color: var(--primary-700);
          }       
        }
      }
    }

    .field-set:first-child {
      @include switch-list();

      .checkbox {
        background: var(--body-5);
        border-bottom: unset;
        border-radius: 10px;
        padding: var(--size-16);
      }
    }

    .field-set:last-of-type {
      margin-top: var(--size-32);

      > .grid > .grid-item:last-of-type {
        .form-control-spacing {
          margin-bottom: 0;
        }
      }

      .checkbox {
        &__label {
          font-weight: 500;
        }
      }
    }
  }

    #address-details-form {
      .address-search {
        &__selected-address {
          .address-search__address {
            display: flex;
            justify-content: space-between;
            background-position: top right;
            height: 100%;
  
            svg {
              width: 3.25rem;
              height: 3.25rem;
            }
          }
        }
      }
  
      #registration-accept-marketing-fieldset,
      #registration-terms-details-fieldset {
        margin: 0;
  
        .checkbox {
          &__container {
            display: flex;
            align-items: flex-start;
  
            .label {
              &__text {
                p {
                  color: var(--white);
                  font-size: var(--size-12);
                  line-height: var(--size-18);
                  font-weight: 500;
  
                  @include respond(desktop) {
                    color: var(--body-100);
                  }
  
                  .button-link {
                    color: var(--dark-blue-15);
                    padding-bottom: var(--size-4);
                  }
                }
              }
            }
          }
        }
      }
    }

  .default-registration-form-step {
    display: block;
    visibility: visible;
    font-size: var(--size-12);

    .heading--5:first-of-type {
      margin: var(--size-4) 0 var(--size-8);
      color: var(--white);

      @include respond(desktop) {
        color: var(--body-100);
      }
    }

    .field-set:last-of-type {
      margin: 0;
    }
    .button-link {
      text-decoration: underline;
      color: var(--body-80);
      padding: 0;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    &__intro-text {
      font-size: var(--size-12);
      color: var(--white);

      @include respond(desktop) {
        color: var(--body-80);
        font-weight: var(--font-weight-medium);
      }
    }

    &__icon {
      padding-top: var(--size-8);
    }
  }

  .select-non-native__dropdown {
    z-index: 3;
  }
}
