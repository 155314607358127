@import '../../../../scss/global/utils/mixins';

.list-component {
  position: relative;
  overflow: hidden;
  width: 100%;

  &--banner {
    li:first-child {
      width: calc(25% + var(--size-16)) !important;
    }
  }
  &--wide-banner,
  &--square-banner {
    li:first-child {
      width: calc(27.5% + 0.7rem) !important;
    }

    .game-card {
      scroll-snap-align: start;
      flex-shrink: 0;
      margin: var(--size-8) var(--size-8) var(--size-8) 0;
      transform-origin: center center;
      transform: scale(1);
      transition: transform 0.5s;
      position: relative;
      padding-right: var(--size-8);
      z-index: var(--game-card-z-index);
    }

    .game-cards {
      flex-shrink: 0;
      width: 60%;
      display: block;
      &--headed-slide {
        margin-right: calc(-1 * var(--size-5));
      }
      &--banner:first-child {
        width: 100%;
      }
      &--card-banner {
        margin: 0;

        .game-card {
          padding-right: 0;
          width: 100%;
          padding-bottom: 1rem;
        }

        .game-card-basic__container--wide:first-child {
          padding-top: 33%;
        }
      }

      &--under-banner {
        display: flex;
        width: 100%;

        .game-card {
          margin: 0;
          width: 100%;
        }
      }
    }
  }

  &--square-banner {
    .game-cards {
      &--card-banner {
        .game-card-basic__container--wide:first-child {
          padding-top: 73.5%;
        }
      }
    }
  }

  &__multi-carousel {
    padding-bottom: var(--size-20);
    width: 100%;
  }

  .carousel__dot-group {
    display: none;
  }

  @include respond(tab-port) {
    .carousel__dot-group {
      display: block;
    }
  }

  &--promotion-padding {
    background-color: var(--body-5);
    @include respond(phone) {
      padding: 0 var(--size-16);
    }
    @include respond(tab-port) {
      padding: 0 var(--size-50);
    }
    @include respond(desktop) {
      padding: var(--size-16) var(--size-16) calc(var(--size-2) + var(--size-20)) var(--size-24);
    }
  }

  & + .games-list__footer {
    padding: var(--size-6) 0;

    @include respond(phone) {
      padding: var(--size-2) 0;
    }
  }

  &--recent + .games-list__footer {
    padding: 0;

    .notification {
      display: none;
    }
  }

  .carousel-wrapper__buttons {
    padding: 0;
  }
}

.carousel-wrapper__header {
  padding: var(--size-8) 0;

  @include respond(phone) {
    .carousel-wrapper__title {
      font-size: var(--size-16);
      padding: 0;
    }
  }
}

.games-list {
  width: 100%;

  &__wrapper {
    display: flex;
    margin: 0;

    @include respond(desktop) {
      margin-bottom: var(--size-5);
    }
  }

  &__jackpot-banner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    padding: 0;
    height: 9.375rem;
    margin-bottom: var(--size-10);

    &-img {
      &--skeleton {
        @include skeleton;
      }
    }

    @include respond(phone) {
      margin-top: var(--size-10);
    }

    @include respond(tab-port) {
      height: auto;
      padding: var(--size-40) 0 var(--size-24) 0;
      max-width: 23rem;
      margin-right: var(--size-16);
    }

    @include respond(tab-port) {
      padding: var(--size-38) 0 var(--size-24) 0;
    }

    @include respond(desktop) {
      padding: var(--size-38) 0 1.938rem 0;
    }

    @include respond(extra-big-desktop) {
      padding: var(--size-38) 0 1.938rem 0;
    }

    & + .list-component {
      margin: 0;
    }

    &--dots {
      padding: 0;

      @include respond(tab-port) {
        padding: 0 0 var(--size-24) 0;
      }

      @include respond(tab-land) {
        padding: 0 0 var(--size-24) 0;
      }

      @include respond(desktop) {
        padding: var(--size-10) 0 2rem 0;
      }

      @include respond(extra-big-desktop) {
        padding: 0 0 2rem 0;
      }
    }

    img {
      border-radius: var(--size-10);
      height: 100%;
      width: inherit;
      object-fit: cover;
    }

    // Wrapper Complete
    .ticker-wrapper {
      position: relative;
      bottom: 19.063rem;
      z-index: 0;

      &__text-complete {
        position: absolute;
        top: 11.725rem;
        width: 100%;

        p {
          color: var(--white);
          font-size: calc(var(--size-16) - var(--size-2));
          line-height: var(--size-20);
          text-align: center;
        }

        @include respond(tab-port) {
          top: 13.5rem;
        }

        @include respond(desktop) {
          top: 12.5rem;
        }
      }

      @include respond(phone) {
        position: relative;
        bottom: 16.875rem;
      }

      &__ticker {
        display: flex;
        justify-content: center;
        position: absolute;
        width: 100%;
        height: var(--size-32);
        top: 13rem;

        &.ticker--completed {
          top: 14rem;

          @include respond(tab-port) {
            top: 16.5rem;
          }

          @include respond(desktop) {
            top: 14.5rem;
          }
        }

        &.ticker--mobile {
          @include respond(desktop) {
            top: 15.5rem;
          }
        }

        @include respond(tab-port) {
          top: 15rem;
        }

        @include respond(desktop) {
          top: 14rem;

          &--completed {
            top: 16rem;
          }
        }

        &::before {
          content: ' ';
          position: absolute;
          color: var(--white);
          width: 100%;
          background: var(--gradient-warm);
          z-index: 2;
          top: var(--size-4);
          height: var(--size-16);
        }

        &--trapezoid {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 17.2rem;
          position: relative;
          background: var(--gradient-cold);
          transform-style: preserve-3d;
          z-index: 2;
          transform: perspective(17.5rem) rotateX(125deg) translateZ(0.938rem) translateY(0);
          height: calc(var(--size-40) + var(--size-8));

          @include respond(desktop) {
            transform: perspective(17.5rem) rotateX(135deg) translateZ(0.938rem);
            height: calc(var(--size-40) + var(--size-4));
          }

          &-text {
            color: var(--white);
            position: absolute;
            z-index: 3;
            font-size: var(--size-20);
            line-height: var(--size-20);
            font-family: var(--body-font-family);
            font-style: normal;
            font-weight: var(--input-font-weight);

            @include respond(desktop) {
              font-size: var(--size-24);
            }
          }
        }
      }

      &__timer {
        display: flex;
        justify-content: center;
        position: absolute;
        width: 100%;
        height: var(--size-24);
        top: 15rem;
        background: var(--gradient-cold);

        @include respond(tab-port) {
          top: 16.725rem;
        }

        &--mobile {
          @include respond(desktop) {
            top: 17rem;
          }
        }

        &--text {
          padding: var(--size-2) var(--size-8) var(--size-2) 0;
          line-height: var(--size-20);
          background-color: var(--gradient-warm);
          background-image: var(--gradient-cold);
          -webkit-background-clip: text;
          -moz-background-clip: text;
          background-clip: text;
          color: var(--white);
          font-size: calc(var(--size-16) - var(--size-2));
        }

        &--time {
          color: var(--white);
          font-size: var(--size-16);
          line-height: var(--size-24);
        }
      }
    }
  }
}

@media only screen and (min-width: 64.0625em) {
  .games-list__jackpot-banner {
    max-width: 32%;

    &_wide-tile {
      max-width: 18%;
    }
  }

  .games-list__jackpot-banner_single-row {
    max-width: 20%;

    &_wide-tile {
      max-width: 10%;
    }
  }
}

@media only screen and (max-width: 64.0625em) {
  .games-list__jackpot-banner {
    max-width: 50%;

    &_wide-tile {
      max-width: 40%;
    }
  }

  .games-list__jackpot-banner_single-row {
    max-width: 40%;

    &_wide-tile {
      max-width: 27%;
    }
  }
}

@media only screen and (max-width: 37.5rem) {
  .games-list__jackpot-banner {
    max-width: 100%;

    &_wide-tile {
      max-width: 100%;
    }
  }
}

@media only screen and (min-width: 120rem) {
  .games-list__jackpot-banner {

    &_wide-tile {
      max-width: 15%;
    }
  }
}
