:root {
  --grid-gap-s: var(--size-4);
  --grid-gap-m: var(--size-10);
  --grid-gap-l: var(--size-16);
  --grid-gap-xl: var(--size-24);
}

.grid {
  display: grid;
  box-sizing: border-box;
  grid-template-columns: repeat(1, 1fr);

  > * {
    overflow: hidden;
    box-sizing: border-box;
  }

  @for $cols from 2 through 12 {
    &--columns-#{$cols} {
      grid-template-columns: repeat($cols, 1fr);
    }
  }

  &--gap-s {
    gap: var(--grid-gap-s);
  }

  &--gap-m {
    gap: var(--grid-gap-m);
    margin: var(--grid-gap-m) 0;
  }

  &--gap-l {
    gap: var(--grid-gap-l);
  }

  &--gap-xl {
    gap: var(--grid-gap-xl);
  }
}
