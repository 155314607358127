@import '../../../../scss/global/utils/mixins';

:root {
  --category-navigation-gap: var(--size-8);
  --category-navigation-margin: var(--size-16);
}

.category__navigation {
  @include scroll-container-no-scrollbars;

  width: 100%;
  display: flex;
  gap: var(--category-navigation-gap);
  padding: var(--size-12) 0;
  cursor: pointer;

  &:not(:empty) {
    min-height: var(--navigation-subnav-min-height);

    @include respond(tab-port) {
      min-height: var(--navigation-subnav-min-height-tab-port);
    }
  }

  &-item {
    text-decoration: none;
    user-select: none;
    height: var(--size-40);

    .chip {
      background: var(--primary-500);
      color: var(--sub-nav-pills-color);
      border-color: var(--sub-nav-pills-color);
      height: 100%;

      &.active,
      &:active,
      &:hover {
        background-color: var(--primary-500);
        color: var(--white);
        border-color: var(--chip-rounded-hover-background);
      }
    }

    &-icon {
      height: var(--size-12);
      width: var(--size-12);
      margin-right: var(--size-8);
    }

    @include respond(phone) {
      padding-right: var(--size-8);
    }
  }
}

.chip.category--medium-chip {
  @include typographySize(m);

  padding: var(--size-10) var(--size-24);
}
