// Typography overrides
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600&display=swap');
@import '../utils/mixins.betzone-uk';

:root {
  // Default font options, e.g. Article Text
  --body-font-family: 'Montserrat', sans-serif;
  --body-secondary-font-family: 'Nunito', sans-serif;
  --body-text-color: var(--grey-800);
  --body-font-size: var(--font-size-14);
  --body-line-height: 1.5; // Warning: changing this may affect spacing/sizing of elements which inherit line-height
  --text-highlight-weight: 600;
  --text-highlight-weight-thin: 500;
  --text-highlight-weight-normal: normal;
  --text-highlight-weight-bold: bold;
  // e.g. Headline
  --primary-content-color: var(--primary-800);
  // e.g. Article date
  --secondary-content-color: var(--grey-500);
  // e.g. Copyright notice
  --tertiary-content-color: var(--grey-100);

  // Heading styles. Can be applied by using the Heading component or the headingStyles mixin
  // - all variants are heading-line-height(1.5) other than overrode values
  --heading-font-family: 'Montserrat', sans-serif;
  --heading-font-weight: 700;
  --heading-color: var(--grey-1000);
  --heading-text-transform: none;
  --heading-margin: 0.3em 0;
  --h1-font-size: var(--font-size-40);
  --h2-font-size: var(--font-size-32);
  --h3-font-size: var(--font-size-24);
  --h4-font-size: var(--font-size-20);
  --h5-font-size: var(--font-size-16);
  --h6-font-size: var(--font-size-14);
  --h6-line-height: var(--font-size-20);

  // Paragraph style overrides - all variants are body-line-height(1.5) other than overrode values
  --paragraph-font-weight: 500;
  --paragraph-highlight-font-weight: 600;
  --paragraph-font-weight-bolder: 700;
  --paragraph-color: var(--grey-1000);
  --paragraph-margin: 0 0 var(--size-16);
  --paragraph-xl-font-size: var(--font-size-20);
  --paragraph-l-font-size: var(--font-size-16);
  --paragraph-m-font-size: var(--font-size-14);
  --paragraph-s-font-size: var(--font-size-12);
  --paragraph-xs-font-size: var(--font-size-10);
  --paragraph-xxs-font-size: var(--font-size-8);
  --paragraph-m-line-height: var(--font-size-20);
  --paragraph-xs-line-height: var(--font-size-14);

  // URL link colours. Placed here so they can be used for Text Buttons as well.
  --link-color: #2b85ec;
  --link-hover-color: #2b85ec;
  --link-active-color: #0e54a4;
  --prompt-heading__header-padding: 22px;
}

// Styles for nested ordered added by class nested in components.
ol.nested,
ol.nested ol {
  counter-reset: item;
  padding-left: var(--size-24);
  word-break: normal;
  li {
    @include typography_list(var(--font-size-18), 600, var(--font-size-14));
    li {
      @include typography_list(var(--font-size-18), 500, var(--font-size-12));
    }
  }
}
ol.nested li:not(ul li) {
  display: block;
}
ol.nested li:not(ul li)::before {
  content: counters(item, '.') ' ';
  counter-increment: item;
}

ol.nested li > ol {
  padding-left: var(--size-24);
  font-size: var(--font-size-12);
  line-height: var(--font-size-18);
}

ol.nested ul {
  li {
    &::before {
      display: none;
    }

    list-style-type: disc;
  }
}

// Styles for link in policy page
.policy-link {
  color: var(--black);
  font-weight: var(--paragraph-highlight-font-weight);
  text-decoration: underline;
  .button {
    text-decoration: none;
  }
}
