@import '../../../../scss/global/utils/mixins';

.authenticated-header-wrapper {
  height: var(--header-mobile-height);

  @include respond(tab-port) {
    height: var(--header-height);
  }

  &--notification {
    height: var(--size-100);

    @include respond(phone) {
      height: var(--size-88);
    }

    @include respond(tab-port) {
      height: var(--size-116);
    }

    .header-notification-bar {
      background-color: var(--body-80);
      height: var(--size-32);
      color: var(--white);
      padding-left: var(--size-16);

      @include respond(tab-port) {
        height: var(--size-45);
        padding-left: var(--size-24);
      }

      &__icon {
        margin-right: var(--size-8);

        @include respond(tab-port) {
          margin-right: var(--size-12);
        }

        &--warning {
          color: var(--warning);
        }
      }

      .header-notifications {
        &__link {
          text-decoration: none;
          &:hover {
            color: var(--white);
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.authenticated-header {
  .button {
    height: var(--size-40);

    &.authenticated-header__deposit-btn {
      margin-right: var(--size-4);

      @include respond(tab-port) {
        margin-right: 0;
      }
    }
  }

  .hamburger-logo {
    @include respond(tab-port) {
      margin-right: var(--size-24);
    }

    @include respond(desktop) {
      margin-right: var(--size-64);
    }
  }

  .header-balance {
    @include respond(tab-port) {
      margin-right: var(--size-24);
    }

    @include respond(tab-land) {
      margin-left: var(--size-32);
    }
  }
}
