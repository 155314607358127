@import '../../../../../scss/global/utils/mixins';

:root {
  // footer
  --game-card-details-background-color: var(--white);
  // information icon
  --game-card-details-info-size: var(--size-24);
  // actions
  --game-card-details-actions-padding: var(--size-8) var(--size-8);
  --game-card-details-actions-gap: var(--size-10);
  // info
  --game-card-details-info-padding: var(--size-16);
  // details
  --game-card-details-details-transition-duration: 0.3s;
}

.game-card__wrapper {
  display: flex;
  background: var(--black);
  margin-top: calc(-1 * var(--size-3));
  padding: var(--size-3) 0;
  position: relative;
  flex-wrap: wrap;

  @media #{$phone-landscape} {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .game-card-details {
    display: flex;
    flex-basis: 100%;
    order: -1;

    @include respond(desktop){
      flex-direction: column;
    }

    &__info {
      display: flex;
      flex-direction: space-between;
      flex-wrap: none;
      padding: var(--game-card-details-info-padding);
      padding-bottom: 0;
      flex-basis: 100%;
      align-items: center;

      @include respond(desktop){
        order: -1;
        align-items: flex-start;
      }

      &-tags {
        flex-wrap: wrap;
        display: flex;
        flex: 1 1 auto;
        gap: var(--size-4);
        text-transform: uppercase;

        .chip {
          background-color: var(--info);
          color: var(--white);

          @include respond(desktop){
            font-size: var(--size-10);
          }
        }

        @include safari-grid {
          .chip {
            margin: 0 var(--size-4) var(--size-4) 0;
          }
        }
      }

      &-icon {
        margin-left: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: none;
        outline: none;
        width: var(--game-card-details-info-size);
        height: var(--game-card-details-info-size);

        svg {
          fill: var(--body-30);
        }
      }
    }

    &__actions {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: var(--game-card-details-actions-gap);
      padding: var(--game-card-details-actions-padding);
      order: -1;
      color: var(--white);

      .button {
        min-width: var(--size-100);
        
        &.button--secondary {
          background-color: var(--white);

          &:hover {
            color: var(--primary-900);
          }
        }
      }

      @include safari-grid {
        .button {
          margin-right: var(--game-card-details-actions-gap);

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
    &__link {
      word-break: break-word;
      min-width: 40%;
    }
  }
}
