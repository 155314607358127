.games-filter-form {
  &__filter-container {
    margin: 0 0 var(--size-24) 0;

    .checkbox {
      display: inline-block;
      min-width: var(--size-192);
    }
  }

  &__fieldset-title {
    margin: 0 0 var(--size-16) 0;
  }
}
