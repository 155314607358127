.content-column {
  &--sticky {
    position: sticky;
    top: 0;
    z-index: var(--z-index-header);
  }
  &--over-prior-content {
    z-index: var(--z-index-over-all-second);
  }
  &--over-prior-bg {
    z-index: var(--z-index-over-all-second);
    height: 100%;
    width: 100%;
    position: absolute;
  }
}
