@import '../../../scss/global/utils/mixins';

.welcome-bonus-banner {
  background-repeat: no-repeat;
  background-size: cover;

  &__image {
    display: none;
  }

  .button--text {
    color: var(--white);
    outline: none;
    padding: 0;
    margin-top: var(--size-12);

    &:hover {
      color: var(--white);
    }
  }

  &__title,
  &__subtitle {
    line-height: 1;
    color: var(--white);
    font-size: var(--size-28);
    font-weight: var(--font-weight-bold);
  }

  &__subtitle {
    margin: 0;
  }

  &__title-wrapper {
    &--mobile {
      display: flex;
      flex-direction: column;
    }
  }

  &--mobile {
    display: flex;
    align-items: center;
    padding: var(--size-16) var(--size-20);

    .button {
      font-size: var(--font-size-12);
      margin-left: auto;
      margin-top: 0;
    }
    
    .welcome-bonus-banner {     
      &__title,
      &__subtitle {
        font-size: var(--size-12);
        line-height: var(--size-18);
        font-weight: var(--font-weight-bold);
      }

      &__subtitle {
        padding-bottom: 0;
      }

      &__image {
        display: block;
        width: var(--size-24);
        height: var(--size-24);
        margin-right: var(--size-16);
      }
    }
  }
}

// Temporary fix to get rid of inline styles usage
.modal-body,
.touch-drawer {
  .button {
    &.button--x-large {
      &.terms-back-btn {
        width: 100%;
        margin: var(--size-16) 0;
      }
    }
  }
}
