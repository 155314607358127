.iframe-page {
    color: var(--body-10);
    height: 100%;

    .heading {
        color: var(--body-10);
    }
    iframe {
        width: 100%;
        height: 80vh;
    }
}