:root {
  // specific variables overrides
  --size-9: 0.563rem;
  --size-40: 2.54rem;
  --size-176: 11rem;
  --font-size-40: 2.5rem;
  --font-size-32: var(--size-32);
  --font-size-8: var(--size-8);
  --size-56: 3.5rem;
  --border-radius-right-rounded: 0 var(--size-16) var(--size-16) 0;

  // Grid System Variables - named to match starterkit breakpoint names

  // Phone
  // < 600px
  --grid-phone-horizontal-margin: var(--size-16);
  --grid-phone-gutter: var(--size-8);
  --grid-phone-columns: 4;

  //Phone Landscape
  // 320px - 992px
  --grid-phone-landscape-horizontal-margin: var(--size-24);
  --grid-phone-landscape-columns: 8;
  --grid-phone-landscape-gutter: var(--size-8);

  // Tab Portrait
  // < 960px
  --grid-tab-port-horizontal-margin: var(--size-24);
  --grid-tab-port-gutter: var(--size-12);
  --grid-tab-port-columns: 8;

  // Tab Landscape
  // < 1025px
  --grid-tab-land-horizontal-margin: var(--size-24);
  --grid-tab-land-gutter: var(--size-16);
  --grid-tab-land-columns: 12;

  // Desktop
  // < 1920px
  --grid-desktop-horizontal-margin: var(--size-24);
  --grid-desktop-gutter: var(--size-16);
  --grid-desktop-columns: 12;

  // Large Desktop
  // < 1920px
  --grid-big-desktop-horizontal-margin: var(--size-56);
  --grid-big-desktop-gutter: var(--size-16);
  --grid-big-desktop-columns: 24;

  // Larger Desktop
  // 1920px >
  --grid-extra-big-desktop-horizontal-margin: var(--size-24);
  --grid-extra-big-desktop-gutter: var(--size-16);
  --grid-extra-big-desktop-columns: 24;

  // font-weight
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-bolder: 900;
}
