@import '../../../../scss/global/utils/mixins';

.mobile-games-carousel {
  @include respond(phone) {
    padding: 0;
    margin: 0;
  }

  img {
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: var(--size-10);
  }

  &__slider {
    display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;     /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */
    display: flex;
    flex-direction: row;
    overflow-x: scroll; /* Ensure smooth scrolling on mobile */
    overflow-y: hidden; /* Prevent vertical overflow if not needed */
    scroll-behavior: smooth;
    max-width: 100%;
    z-index: 0;
    scroll-snap-type: x mandatory;
    
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
      display: none;  /* Safari and Chrome */
    }

    .game-card {
      scroll-snap-align: start;
      flex-shrink: 0;
      padding-top: var(--size-8);
      transition: transform 0.5s;
      position: relative;
      padding-right: var(--size-8);
      z-index: var(--game-card-z-index);
      width: auto; /* Ensure game-card does not exceed container */
      will-change: transform; /* Enable hardware acceleration */
    }
    .game-cards {
      flex-shrink: 0;
      width: 50%;
      display: block;
      &--headed-slide {
        margin-right: calc(-1 * var(--size-5));
      }
      &--banner:first-child {
        width: 100%;
      }
      &--card-banner {
        height: 50%;
        margin: 0;
        .game-card-basic__container--wide:first-child {
          padding-top: 34%;
        }
      }
      &--under-banner {
        display: flex;
        width: 49%;
        .game-card {
          margin: 0;
        }
      }

      @include respond(tab-port) {
        width: 25.5%;
      }
    }
  }
  section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    .button--text {
      padding-top: 16px;
      outline: none;
      color: var(--btn-primary-bg-color);
      font-size: 12px;
      &:hover {
        color: var(--btn-primary-hover-bg-color);
      }
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); // Smaller minimum width for mobile
    gap: 12px; // Reduced gap for mobile
    width: 100%;
  }

  &__grid-item {
    cursor: pointer;
    transition: transform 0.2s ease;

    &:active {
      transform: scale(0.98); // Use scale down for active state on mobile
    }
  }
}

.carousel-wrapper__title {
  @include respond(phone) {
    padding-left: 0;
    padding-top: var(--size-16);
    font-size: var(--font-size-16);
  }
}
