@import '../../../../../scss/global/utils/mixins';

.self-exclusion-form {
  &__duration-options {
    @include scroll-container-no-scrollbars;
  }

  &__duration {
    &:not(:last-child) {
      margin-right: 0.5625rem;
    }
  }

  &__reason-label {
    margin-top: var(--size-24);
    margin-bottom: var(--size-8);
  }
}
