.gaming-performance-prompt {
  &__metrics {
    display: grid;
    gap: var(--size-8);
    grid-template-columns: 1fr 1fr;
    margin: var(--size-20) 0;
  }

  &__metric {
    text-align: left;
    padding: var(--size-8) var(--size-16);
  }
  .touch-drawer__content .prompt__content {
    padding-bottom: 0;
  }

  .touch-drawer__viewport--bottom .touch-drawer__content {
    padding-bottom: 0;
  }
}
