.logo-spinner {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.small {
    svg {
      width: 24px;
      height: 24px;
    }
    p {
      font-size: 12px;
    }
  }

  &.medium {
    svg {
      width: 48px;
      height: 48px;
    }
    p {
      font-size: 14px;
    }
  }

  &.large {
    svg {
      width: 64px;
      height: 64px;
    }
    p {
      font-size: 16px;
    }
  }

  .logo-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    p {
      color: #fff;
      margin: 0;
    }
  }

  /* Target Animation */
  .target svg path:first-child {
    animation: targetOuter 1.5s ease-in-out infinite;
  }

  .target svg path:last-child {
    animation: targetInner 1.5s ease-in-out infinite;
  }

  @keyframes targetOuter {
    0%, 100% {
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
  }

  @keyframes targetInner {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
  }
}