:root {
  --default-grey-border: 1px solid var(--grey-300);
  --default-horizontal-rule: 1px solid var(--grey-200);

  // Base Outline
  --focus-outline-width: 2px;
  --focus-outline-color: #5ba1f1;
  --focus-outline: var(--focus-outline-width) solid var(--focus-outline-color);
  --focus-outline-offset: 1px;

  // Box shadows
  --box-shadow-1: 0 1px 3px hsla(0, 0%, 0%, 0.12),
    0 1px 2px hsla(0, 0%, 0%, 0.24);
  --box-shadow-2: 0 3px 6px hsla(0, 0%, 0%, 0.15),
    0 2px 4px hsla(0, 0%, 0%, 0.12);
  --box-shadow-3: 0 10px 20px hsla(0, 0%, 0%, 0.15),
    0 3px 6px hsla(0, 0%, 0%, 0.1);
  --box-shadow-4: 0 15px 25px hsla(0, 0%, 0%, 0.15),
    0 5px 10px hsla(0, 0%, 0%, 0.05);
  --box-shadow-5: 0 20px 40px hsla(0, 0%, 0%, 0.2);
  --box-shadow-6: 0 1px 2px rgba(0, 0, 0, 0.2);
  --box-shadow-7: 3px 3px 5px hsla(0, 0%, 0%, 0.1);
  --disabled-opacity: 0.5;
}
