@import '../../../scss/global/utils/media-queries';

:root {
  --action-panel-margin: var(--size-10) 0 0 0;
  --action-panel-mobile-background-color: var(--white);
  --action-panel-mobile-box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.2);
  --action-panel-mobile-padding: var(--size-16);
  --action-panel-inline-margin: 0 var(--size-10);
}

.action-panel {
  position: relative;
  display: flex;

  &--column {
    flex-direction: column;

    &.action-panel {
      &--center {
        align-items: center;
      }

      &--right {
        align-items: flex-end;
      }

      &--space-between {
        align-items: space-between;
      }
    }

    > * {
      margin: var(--action-panel-margin);
    }
  }

  &--row {
    flex-direction: row;

    > * {
      margin: var(--action-panel-inline-margin);
    }

    &.action-panel {
      &--center {
        justify-content: center;
      }

      &--right {
        justify-content: flex-end;
      }

      &--space-between {
        justify-content: space-between;
      }
    }
  }

  &--sticky-actions {
    @include respond(phone) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      flex-direction: row-reverse;
      padding: var(--action-panel-mobile-padding);
      background-color: var(--action-panel-mobile-background-color);
      box-shadow: var(--action-panel-mobile-box-shadow);
      z-index: var(--z-index-fixed-action-panel);
      > * {
        margin: var(--action-panel-inline-margin);
        flex: 1;
      }
    }
  }
}
