:root {
  --my-account-home-icon-max-height: 6.75rem;
}

.my-account-home-tile {
  display: flex;
  height: 100%;
  justify-content: space-between;

  &--column {
    flex-direction: column;

    .my-account-home-tile__icon {
      display: flex;
      justify-content: flex-end;
      img {
        width: var(--size-40);
        height: var(--size-40);
      }
    }
  }

  &--row {
    flex-direction: row-reverse;
    align-items: flex-end;
    .my-account-home-tile__icon img {
      max-height: var(--my-account-home-icon-max-height);
      height: 100%;
      width: 100%;
      object-fit: fill;
    }
  }

  &__heading {
    margin-bottom: var(--size-4);
  }

  &__description {
    color: var(--body-90);
  }
}
