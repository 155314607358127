@import '../../../../../scss/global/utils/mixins';

.active-promotions-list {
  color: var(--white);
  padding-top: var(--size-24);
  height: 100%;

  &__heading {
    color: var(--white);
    margin-bottom: var(--size-16);
  }

  &__card {
    margin: 0;
    padding: var(--size-16);
    border-radius: var(--size-8);
    background-color: var(--body-20);

    &-header {
      display: grid;
      align-items: center;
      grid-template-columns: [icon] 42px [text] 1fr [amount] auto;

      &--icon {
        grid-area: 'icon';
        display: grid;
        align-self: center;
        justify-self: start;
      }

      &--text {
        display: flex;
        flex-direction: column;
        grid-area: 'text';
        justify-self: start;
        font-weight: var(--paragraph-highlight-font-weight);
        font-size: var(--size-14);
        line-height: var(--size-20);
        color: var(--body-100);
      }

      &--subtext {
        font-size: var(--font-size-10);
        line-height: var(--size-14);
        margin-top: var(--size-8);
        color: var(--body-100);
      }

      &--amount {
        grid-area: 'amount';
        font-weight: var(--paragraph-font-weight-bolder);
        font-size: var(--size-20);
        line-height: var(--size-24);
        color: var(--body-100);
      }
    }

    &-body {
      margin-top: var(--size-16);

      .promotion-progress-bar__summary-heading {
        padding-top: 0;
      }
    }
  }

  .promotion-bonus-balance-item__summary-block-button {
    display: flex;
    justify-content: space-between;
  }
}
