@import '../../../scss/global/utils/mixins';

.games-filter-grid {
  &__categories-container {
    margin: 0 var(--size-16) var(--size-16);

    @include respond(tab-port) {
      margin: 0 var(--size-24) var(--size-16);
    }
  }

  &__grid-container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: var(--size-16);
    background-color: var(--body-5);

    @include respond(tab-port) {
      border-top-left-radius: var(--size-16);
      padding: var(--size-24);
    }

    .games-grid {
      padding: 0;
    }
  }
}

// Prevents infinite height increase
html {
  .games-filter-grid {
    &__grid-container {
      --options-height: 5rem;

      min-height: calc(100vh - var(--header-mobile-height) - var(--navigation-mobile-height) - var(--options-height));

      @include respond(tab-port) {
        min-height: calc(100vh - var(--header-height) - var(--navigation-tab-port-padding-top) - var(--options-height));
      }
    }
  }
}
