@import '../../../../scss/global/utils/media-queries';

.session-net-position {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &__value {
    color: var(--white);
    border-right: 1px solid var(--white);
  }

  &__tooltip {
    position: absolute;
    top: var(--size-32);
    left: calc(-1 * var(--size-160));

    @include respond(tab-port) {
      right: 0;
      left: unset;
    }

    &--hidden {
      display: none;
    }
  }

  &__icon {
    vertical-align: middle;
    transition: transform 0.2s;

    &--positive {
      color: var(--success-color);
      transform: scale(0.7);
    }

    &--negative {
      color: var(--danger-color);
      transform: rotate(180deg) scale(0.7);
    }
  }
}
