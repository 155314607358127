:root {
  // Variants
  // Default
  --pill-default-bg-color: var(--body-5);
  --pill-default-border-radius: var(--size-8);
  --pill-default-span-color: var(--body-50);
  --pill-default-strong-color: #000;
  --pill-default-text-transform: none;
  //Sizes
  //Large
  --pill-large-height: var(--size-48);
  --pill-large-padding: var(--size-12) var(--size-16);
  --pill-large-span-line-height: var(--size-20);
  --pill-large-strong-font-size: var(--size-16);
  --pill-large-strong-line-height: var(--size-24);
  --pill-large-strong-margin-left: var(--size-8);
  //Small
  --pill-small-font-size: var(--size-8);
  --pill-small-height: var(--size-18);
  --pill-small-padding: 0 var(--size-4);
  --pill-small-span-line-height: normal;
}

.pill {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: var(--pill-large-height);
  width: auto;
  background-color: var(--pill-default-bg-color);
  border-radius: var(--pill-default-border-radius);
  padding: var(--pill-large-padding);
  color: var(--pill-default-span-color);
  text-transform: var(--pill-default-text-transform);

  span {
    line-height: var(--pill-large-span-line-height);
  }
  strong {
    font-size: var(--pill-large-strong-font-size);
    line-height: var(--pill-large-strong-line-height);
    color: var(--pill-default-strong-color);
    &:not(:first-child) {
      margin-left: var(--pill-large-strong-margin-left);
    }
  }

  &--small {
    --pill-default-border-radius: var(--size-2);

    padding: var(--pill-small-padding);
    height: var(--pill-small-height);
    font-size: var(--pill-small-font-size);
    line-height: var(--pill-small-span-line-height);

    span {
      line-height: var(--pill-small-span-line-height);
    }
  }
}
