.my-profile {
  overscroll-behavior: contain;

  &__customer-care-link {
    color: var(--black);
    text-decoration: underline;
  }

  &__header {
    padding: 0 var(--size-16) var(--size-16);
    background-color: var(--background-account-wrapper);

    h3 {
      color: var(--white);
    }
  }

  &__help-text {
    padding-top: var(--size-8);
  }

  &__content {
    background-color: var(--primary-900);
    border-radius: var(--size-16) var(--size-16) 0 0;
    color: var(--body-90);
    // 108px is a header height
    min-height: calc(100% - 108px);
    padding: var(--size-8) var(--size-16) var(--size-32);
  }

  .account-tile {
    position: relative;
    margin: 0 0 var(--size-8) 0;
  }

  &__update-info {
    margin: var(--size-16) 0 0;
  }

  .grid--gap-m {
    margin: 0;
  }

  .heading--6 {
    color: var(--white);
    margin: var(--size-24) 0 var(--size-8) 0;

    &:first-of-type {
      margin: var(--size-16) 0 var(--size-8) 0;
    }
  }

  .account-details {
    &__actions {
      position: absolute;
      display: flex;
      right: var(--size-16);
      top: var(--size-16);

      .account-details__button-text {
        display: none;
      }

      .button > svg {
        color: var(--body-40);
      }
    }
  }

  &__sof-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__badge {
    color: var(--white);
    font-weight: var(--heading-font-weight);
    border-radius: var(--size-2);
    padding: var(--size-2);
    height: var(--size-18);
    margin: 0;
    display: flex;
    align-items: center;
    width: max-content;

    &--required {
      background-color: var(--orange-200);
    }

    &--in-review {
      background-color: var(--accent-500);
    }

    &--verified {
      background-color: var(--success);
    }
  }

  &__sof-lists {
    border-radius: var(--size-12);

    .account-tile-list-item {
      &__content {
        display: flex;
        justify-content: space-between;
        border: 1px var(--body-40) solid;
        height: var(--size-56);
        border-radius: var(--size-8);
        margin-bottom: var(--size-8);
      }

      &__text {
        color: var(--body-40);
      }
    }
  }

  &__sof-description {
    margin-top: var(--size-12);
    color: var(--body-40);
    display: flex;

    &--icon {
      width: var(--size-40);
    }
  }
}
