:root {
  --tooltip-padding: var(--size-10);
  --tooltip-box-shadow: var(--box-shadow-1);
  --tooltip-border-radius: var(--size-4);
  --tooltip-width: var(--size-300);
  --tooltip-transition: opacity 0.2s, transform 0.2s;
  --tooltip-line-height: var(--body-line-height);
  --tooltip-font-size: var(--body-font-size);

  // Light theme
  --tooltip-color: var(--grey-900);
  --tooltip-background-color: white;
  --tooltip-border-color: var(--grey-500);
  --tooltip-border: 1px solid var(--tooltip-border-color);
  --tooltip-icon-color: var(--icon-btn-color-default);
  --tooltip-open-icon-color: var(--icon-btn-color-default);

  // Dark theme
  --tooltip-dark-color: white;
  --tooltip-dark-background-color: var(--grey-800);
  --tooltip-dark-border-color: var(--grey-900);
  --tooltip-dark-border: 1px solid var(--tooltip-dark-border-color);
  --tooltip-dark-icon-color: var(--icon-btn-color-default);
  --tooltip-dark-open-icon-color: var(--icon-btn-color-default);
}

.tooltip {
  display: inline-block;
  line-height: 1;
  color: var(--tooltip-icon-color);

  &__popup {
    opacity: 0;
    transition: var(--tooltip-transition);
    position: relative;
    z-index: var(--z-index-tooltip);
  }

  &__popup-inner {
    position: absolute;
    display: block;
    line-height: var(--tooltip-line-height);
    font-size: var(--tooltip-font-size);
    color: var(--tooltip-color);
    border: var(--tooltip-border);
    border-radius: var(--tooltip-border-radius);
    background-color: var(--tooltip-background-color);
    padding: var(--tooltip-padding);
    box-shadow: var(--tooltip-box-shadow);
    width: var(--tooltip-width);
    text-transform: none;
  }

  &--open {
    color: var(--tooltip-open-icon-color);

    .tooltip {
      &__popup {
        opacity: 1;
      }
    }
  }

  &--trigger-hover {
    .tooltip__popup-inner {
      pointer-events: none;
    }
  }

  &--dark-theme {
    color: var(--tooltip-dark-icon-color);

    &.tooltip--open {
      color: var(--tooltip-dark-open-icon-color);
    }

    .tooltip__popup-inner {
      color: var(--tooltip-dark-color);
      border: var(--tooltip-dark-border);
      background-color: var(--tooltip-dark-background-color);
    }
  }
}
