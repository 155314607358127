@import '../../../../../scss/global/utils/mixins';

.promotion-main-wrapper {
  padding: 0 var(--size-16);
  &__not-found,
  &__regular {
    color: var(--white);
  }
  &__text-block {
    margin-bottom: var(--size-32);
  }
  &__info-block {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    grid-gap: var(--size-16);
    padding-bottom: var(--size-20);
    padding-top: var(--size-4);

    .promotion-card {
      &__description-block {
        padding-bottom: var(--size-16);
      }
    }
  }
  @include respond(phone) {
    padding-top: var(--size-16);
  }
  .spinner {
    margin: 0 auto;
    width: 10%;
    height: auto;
  }
  .button-left {
    padding: var(--size-16) var(--size-8);
    background-color: var(--white);
    margin: 0 calc(-1 * var(--size-24));
    a {
      color: var(--primary-900);
      font-size: var(--size-12);

      &:hover {
        color: var(--body-30);
      }
    }

    @include respond(desktop) {
      background-color: var(--primary-900);

      a {
        color: var(--white);

        &:hover {
          color: var(--body-60);
        }
      }
    }

    &-terms {
      @include respond(phone) {
        margin: var(--size-64) 0 0 var(--size-16);
      }
    }

    span {
      margin-left: var(--size-16);
    }
  }

  .carousel-wrapper__title {
    font-size: var(--size-20);

    @include respond(phone) {
      padding-top: var(--size-24);
    }
  }

  // Align carousel straight by left page border
  .recommended-game-carousel {
    margin-left: 0;
    .carousel-wrapper {
      .carousel {
        padding-left: 0;
        margin-left: 0;
      }
    }
  }

  @include respond(desktop) {
    padding: var(--size-24) var(--size-24) var(--size-50);
    flex-wrap: nowrap;
  }

  @include respond(extra-big-desktop) {
    padding: var(--size-24) 3.5625rem 4.5625rem;
    flex-wrap: nowrap;
  }

  .info-block {
    border-radius: 0.375rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: calc(50% - var(--size-8));

    @include respond(phone) {
      margin: var(--size-16) var(--size-16) 0 var(--size-16);
      width: 100%;
    }

    @include respond(desktop) {
      width: calc(65% - var(--size-8));
    }

    @include respond(extra-big-desktop) {
      width: calc(50% - var(--size-8));
    }

    &__heading-block {
      display: flex;
      position: relative;
      max-height: 9.5rem;

      @include respond(desktop) {
        max-height: 17rem;
      }

      .heading {
        line-height: normal;
      }
    }

    &__image {
      width: 100%;
      object-fit: cover;
    }

    &__title {
      position: absolute;
      bottom: var(--size-4);
      left: var(--size-4);
      margin-left: var(--size-12);

      @include respond(desktop) {
        font-size: var(--font-size-24);
      }
    }

    &__terms {
      font-size: var(--font-size-10);
      line-height: var(--font-size-12);
      padding: var(--size-8) var(--size-12) var(--size-8) 0;
      border-bottom: 1px solid var(--body-5);
      margin-bottom: var(--size-16);
    }

    &__description-text {
      p {
        margin-bottom: var(--size-16);
        font-size: var(--font-size-14);
        line-height: var(--size-20);
      }
    }

    &__description-block {
      padding: var(--size-16);

      h6 {
        margin-bottom: var(--size-10);
        line-height: var(--size-20);
      }
    }

    &__description-title {
      font-size: var(--font-size-12);
      margin-bottom: var(--size-16);
      font-weight: var(--paragraph-highlight-font-weight);

      @include respond(desktop) {
        margin-bottom: var(--size-20);
      }
    }
  }

  .opt-in-block {
    display: flex;
    flex-direction: column;
    border-radius: 0.375rem;
    overflow: hidden;
    padding: var(--size-16);
    background-color: var(--white);
    width: 100%;

    @include respond(extra-big-desktop) {
      width: calc(50% - var(--size-8));
      padding: var(--size-16) var(--size-16) var(--size-32);
    }

    @include respond(desktop) {
      width: 50%;
    }

    &__list {
      display: flex;
      flex-direction: column;
      margin: var(--size-28) 0;
      padding-left: var(--size-16);
    }

    &__circle {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 2.25rem;
      height: 2.25rem;
      margin-right: var(--size-16);
      color: var(--white);
      font-size: var(--size-18);
      font-weight: var(--label-font-weight);
      padding: var(--size-16);
      background: linear-gradient(90deg, var(--body-35) 50%, var(--body-75) 50%);
    }

    &__item {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: var(--size-32);
      }

      > p {
        font-size: var(--size-14);
        line-height: var(--size-20);
        font-weight: var(--paragraph-highlight-font-weight);
      }
    }
  }

  .carousel-wrapper {
    &__title {
      color: var(--white);
    }
  }
}
