@import '../../../../scss/global/utils/mixins';

:root {
  --header-height: 4.5rem;
  --header-mobile-height: var(--size-56);
  --header-background-color: var(--dark-blue-100);
  --header-color: var(--white);
  --header-logo-width: 8.813rem;
  --header-btn-large-padding: 0.75rem 1rem;
}

.header {
  width: 100%;
  height: var(--header-mobile-height);
  background: var(--primary-900);
  color: var(--header-color);

  @include respond(tab-port) {
    height: var(--header-height);
  }

  &--sticky {
    display: block;
    z-index: 5;
    top: 0;
  }

  &__nav {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    padding: 0 var(--size-8);

    @include respond(tab-port) {
      padding: 0 var(--size-24);
    }
  }

  &__left,
  &__right {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--header-color);
    height: 100%;
  }

  @include respond(desktop) {
    &__left {
      justify-content: flex-start;

      :not(.button--text):not(.button--text-alt) {
        &.button--large {
          padding: var(--header-btn-large-padding);
        }
      }
    }

    &__right {
      justify-content: flex-end;

      :not(.button--text):not(.button--text-alt) {
        &.button--large {
          padding: var(--header-btn-large-padding);
        }
      }
    }
  }
}
