:root {
  // form variable overrides
  --input-padding-right: 0.6875rem;
  --input-padding-left: 0.6875rem;
  --input-padding-top: 0.8125rem;
  --input-padding-bottom: 0.8125rem;
  --input-border-radius: 4px;
  --input-font-size: var(--font-size-14);
  --input-line-height: var(--font-size-20);
  --input-font-weight: 500;
  --input-color: var(--grey-1000);
  --input-dark-color: var(--white);
  --input-border-width: 1px;
  --input-border: var(--input-border-width) solid var(--grey-200);
  --input-dark-border: var(--input-border-width) solid var(--dark-blue-75);
  --input-focus-border: var(--input-border-width) solid var(--grey-1000);
  --input-dark-focus-border: var(--input-border-width) solid var(--white);
  --input-error-border: var(--input-border-width) solid var(--error);
  --input-dark-error-border: var(--input-error-border);
  --input-warning-border: var(--input-border);
  --input-dark-warning-border: var(--input-dark-border);
  --input-success-border: var(--input-border);
  --input-dark-success-border: var(--input-dark-border);
  --input-completed-border: var(--input-border);
  --input-dark-completed-border: var(--input-dark-border);
  --input-hover-border: var(--input-focus-border);
  --input-dark-hover-border: var(--input-dark-focus-border);
  --input-font-family: var(--body-font-family);
}

.disable-outline {
  *:focus,
  .checkbox__icon {
    outline: none !important;
  }
}
