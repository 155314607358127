.account-tile-list-item {
  &__content {
    padding: var(--size-24) var(--size-16);
    display: flex;
    align-items: center;
  }

  &__time-zone-content {
    padding: 0 var(--size-16) var(--size-24);
    display: flex;
    align-items: center;
  }

  &__icon {
    font-size: var(--font-size-20);
    margin-right: var(--size-8);
    color: var(--body-60);
  }

  &__text {
    color: var(--body-80);
  }

  &__subtext {
    font-size: var(--font-size-12);
    font-weight: var(--inbox-message-from-font-weight-read);
  }

  &:not(:last-child)::after {
    height: 0.0625rem;
    content: '';
    width: calc(100% - var(--size-32));
    background-color: var(--body-10);
    display: block;
    margin: 0 auto;
  }

  &--clickable {
    cursor: pointer;
  }
}
