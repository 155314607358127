:root {
  --checkbox-size: var(--font-size-24);
  --checkbox-label-size: var(--label-font-size);
  --checkbox-label-font-family: inherit;
  --checkbox-label-font-weight: inherit;
  --checkbox-checked-color: var(--primary-700);
  --checkbox-indeterminate-color: var(--primary-700);
  --checkbox-color: var(--grey-700);
  --checkbox-label-color: inherit;
  --checkbox-disabled-opacity: var(--disabled-opacity, 0.5);
  --checkbox-label-margin: 0 0 0 var(--size-12);
  --checkbox-focus-outline: var(--focus-outline);
  --checkbox-inline-icon-color: var(--checkbox-label-color);
  --checkbox-inline-icon-margin: var(--checkbox-label-margin);
  --checkbox-inline-icon-size: var(--checkbox-label-size);
}

.checkbox {
  display: inline-flex;
  flex-direction: column;

  &__container {
    position: relative;
    display: inline-flex;
    align-items: center;
  }

  &--disabled {
    .checkbox__container {
      opacity: var(--checkbox-disabled-opacity);
    }
  }

  &__icon {
    font-size: var(--checkbox-size);
    min-width: var(--checkbox-size);
    color: var(--checkbox-color);

    &--checked {
      color: var(--checkbox-checked-color);
    }

    &--indeterminate {
      color: var(--checkbox-indeterminate-color);
    }
  }

  &__input {
    top: 0;
    left: 0;
    width: 100%;
    cursor: inherit;
    height: 100%;
    margin: 0;
    opacity: 0;
    padding: 0;
    z-index: var(--z-index-checkbox-input);
    position: absolute;

    &:focus + .checkbox__icon {
      outline: var(--checkbox-focus-outline);
    }
  }

  &__label {
    z-index: var(--z-index-checkbox-label);
    color: var(--checkbox-label-color);
    margin: var(--checkbox-label-margin);
    font-family: var(--checkbox-label-font-family);
    font-size: var(--checkbox-label-size);
    font-weight: var(--checkbox-label-font-weight);
  }

  &__inline-icon {
    display: inline-flex;
    color: var(--checkbox-inline-icon-color);
    margin: var(--checkbox-inline-icon-margin);
    font-size: var(--checkbox-inline-icon-size);
  }
}
