@import '../../../scss/global/utils/mixins';

:root {
  // General variable overrides
  --modal-border: 0;
  --modal-border-radius: 16px;
  --modal-background-color: var(--primary-900);
  --modal-backdrop-background-color: var(--backdrop-bg-color);
  --modal-backdrop-background-light-color: rgb(30 39 61 / 60%);
  --modal-dense-padding-phone: 0;
  --modal-dense-padding: 0;
  --modal-minimum-horizontal-margin: 0;
  --modal-minimum-vertical-margin: 0;
  --modal-close-btn-top: 1.5625rem;
  --modal-close-btn-right: var(--size-24);
  --modal-heading-align: left;

  // General dialog padding
  --modal-header-padding-top: var(--size-24);
  --modal-header-padding-bottom: var(--size-16);
  --modal-footer-padding-top: var(--size-16);
  --modal-footer-padding-bottom: var(--size-32);
  --modal-header-padding: var(--modal-header-padding-top) var(--size-24) var(--modal-header-padding-bottom);
  --modal-footer-padding: var(--modal-footer-padding-top) var(--size-24) var(--modal-footer-padding-bottom);
  --modal-body-padding: var(--size-8) var(--size-24);

  // responsive column configs
  --modal-phone-start-column: 1;
  --modal-phone-end-column: 5;
  --modal-tab-port-start-column: 2;
  --modal-tab-port-end-column: 8;
  --modal-tab-land-start-column: 4;
  --modal-tab-land-end-column: 10;
  --modal-desktop-start-column: 4;
  --modal-desktop-end-column: 10;
  --modal-big-desktop-start-column: 4;
  --modal-big-desktop-end-column: 10;
  --modal-extra-big-desktop-start-column: 9;
  --modal-extra-big-desktop-end-column: 17;

  // responsive dialog margins
  --modal-phone-vertical-margin: 2.5rem;
  --modal-tab-port-vertical-margin: 2.5rem;
  --modal-tab-land-vertical-margin: 5rem;
  --modal-desktop-vertical-margin: 2.5rem;
  --modal-big-desktop-vertical-margin: 5rem;
  --modal-extra-big-desktop-vertical-margin: 5rem;

  // Calculations used for overflow
  --modal-header-height: calc(
    var(--modal-header-padding-top) + var(--modal-header-padding-bottom) +
      (var(--h5-font-size) * var(--body-line-height))
  );
  --modal-footer-height: calc(
    var(--modal-footer-padding-bottom) + var(--modal-footer-padding-top) + var(--btn-large-height)
  );
}

.modal {
  overflow: initial;
  position: relative;
  top: auto;
  left: auto;
  transform: none;
  max-width: none;
  outline: 0;
  max-height: calc(100% - (var(--modal-minimum-vertical-margin) * 2));
  border-radius: var(--modal-border-radius);
  @include dark-theme-text-color;

  .button {
    &--primary {
      span {
        color: var(--primary-900);
      }
    }
  }
  .modal__container {
    overflow: hidden;
    background-color: var(--modal-background-color);
    border: var(--modal-border);
    position: relative;
    border-radius: var(--modal-border-radius);

    /*
     * Deliberately hard coded here rather than set as global z-index variable because the modal-backdrop defines the overall global modal z-index.
      *
     * Therefore, due to the nature of stacked z-index, the scope of the z-index for this container is relevant in comparison to elements which are placed within the .modal element.
     * For example, it will ensure that the ExpansionNotification will display below the modal container element.
     */
    z-index: 1;
  }

  &--padding-dense {
    .modal__container {
      padding: var(--modal-dense-padding);

      @include respond(phone) {
        padding: var(--modal-dense-padding-phone);
      }
    }
  }

  &--small,
  &--medium,
  &--large,
  &--fullscreen {
    @each $size in $breakpoint-names {
      margin: 0;
      width: 100%;
      box-shadow: -1px 0 var(--primary-900);

      @include respond($size) {
        grid-column: var(--modal-#{$size}-start-column) / var(--modal-#{$size}-end-column);
        max-height: calc(100vh - (2 * var(--modal-#{$size}-vertical-margin)));
      }
    }
  }

  &-backdrop {
    height: 100%;
    display: grid;
    align-items: center;
    background-color: var(--modal-backdrop-background-color);

    @include grid();
  }

  // Note that the modal content max-height may need setting independently for other unique modal variations
  &-body {
    position: relative;

    &__content {
      overflow-y: auto;

      @include styled-scrollbar();
    }
  }

  &-footer {
    display: flex;
    justify-content: flex-end;

    .button:not(:last-of-type) {
      margin-right: var(--size-16);
    }
  }

  &--with-expansion-shadow {
    .modal__container {
      box-shadow: 0 3px 24px rgba(0, 0, 0, 0.15);
    }
  }

  &--with-footer {
    .modal-body--overflowing {
      &:not(.modal-body--scroll-start) {
        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          height: 0;
          border-top: 1px solid var(--grey-100);
          box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.05);
        }
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 0;
        border-bottom: 1px solid var(--grey-100);
        box-shadow: 0 -4px 8px 2px rgba(0, 0, 0, 0.05);
      }
    }

    .modal-body__content {
      @each $size in $breakpoint-names {
        @include respond($size) {
          // dialog height minus header and footer with action buttons
          max-height: calc(
            100vh - (2 * var(--modal-#{$size}-vertical-margin)) - var(--modal-header-height) - var(
                --modal-footer-height
              )
          );
        }
      }
    }
  }

  &:not(.modal--with-footer) {
    .modal__container {
      padding: 0 0 var(--modal-footer-padding-bottom);
    }

    .modal-body__content {
      @each $size in $breakpoint-names {
        @include respond($size) {
          // dialog height minus header and bottom padding
          max-height: calc(
            100vh - (2 * var(--modal-#{$size}-vertical-margin)) - var(--modal-header-height) - var(
                --modal-footer-padding-bottom
              )
          );
        }
      }
    }
  }

  // CSS lint complains if this is above with other selectors.
  .modal-body__content::-webkit-scrollbar-thumb:hover {
    background: transparent;
    box-shadow: inset 0 0 var(--size-10) var(--size-10) var(--grey-500);
  }

  &__close-btn {
    position: absolute;
    font-size: var(--modal-close-btn-font-size);
    top: var(--size-16);
    right: var(--modal-close-btn-right);
    z-index: var(--z-index-overlay-content);
  }

  .modal-body {
    ::-webkit-scrollbar {
      max-width: var(--size-10);
    }
    ::-webkit-scrollbar-track {
      background-color: var(--primary-900);
    }
    &__content {
      overflow-y: auto;
      padding-bottom: 0;
    }
  }
}
