@import '../../../../scss/global/utils/mixins';

.reality-checks-edit-modal {
  &__select-form {
    .select-non-native {
      &__dropdown--bottom {
        top: calc(-1 * var(--size-64));

        @include respond(tab-port) {
          top: unset;
          position: relative;
          margin-top: var(--size-24);
        }
      }

      @include respond(phone) {
        &__ul {
          max-height: var(--size-128);
        }
      }

      @include respond(tab-port) {
        &__chevron {
          bottom: unset;
          top: var(--size-16);
        }
      }
    }

    .spinner {
      width: var(--size-32);
      top: 50%;

      @include respond(tab-port) {
        top: 65%;
      }
    }
  }

  &__action-footer {
    display: flex;
    justify-content: flex-end;
  }

  &__cancel-btn {
    margin-right: var(--size-16);
  }

  &__select-text {
    margin-bottom: var(--size-24);
  }

  .modal-footer {
    padding-top: var(--size-32);
  }
}
