:root {
  --input-feedback-icon-size: var(--font-size-20);
  --input-feedback-error-color: var(--danger-color);
  --input-feedback-dark-error-color: var(--danger-300);
  --input-feedback-warning-color: var(--warning-color);
  --input-feedback-dark-warning-color: var(--warning-400);
  --input-feedback-success-color: var(--success-color);
  --input-feedback-dark-success-color: var(--success-300);
}

.input-feedback-icon {
  display: flex;
  align-items: center;
  font-size: var(--input-feedback-icon-size);
  z-index: var(--z-index-input-feedback-icon);

  &--absolute {
    position: absolute;
    right: var(--size-32);
    top: 0;
    bottom: 0;
  }

  &--relative {
    position: relative;
  }

  &--error {
    color: var(--input-feedback-error-color);
  }

  &--success {
    color: var(--input-feedback-success-color);
  }

  &--warning {
    color: var(--input-feedback-warning-color);
  }

  &--dark {
    &.input-feedback-icon--error {
      color: var(--input-feedback-dark-error-color);
    }

    &.input-feedback-icon--success {
      color: var(--input-feedback-dark-success-color);
    }

    &.input-feedback-icon--warning {
      color: var(--input-feedback-dark-warning-color);
    }
  }
}
