:root {
  --label-margin-size: var(--size-4);
  --label-font-weight: 700;
  --label-font-size: var(--font-size-14);
  --label-color: var(--grey-700);
  --label-dark-color: var(--grey-200);
}

.label {
  display: inline-flex;
  align-items: center;
  color: var(--label-color);
  font-size: var(--label-font-size);
  font-weight: var(--label-font-weight);

  .label__input {
    display: inline-flex;
  }

  &--disabled {
    .label__text {
      opacity: var(--disabled-opacity);
    }
  }

  &--position-top {
    flex-direction: column;
    .label__text {
      margin: 0 0 var(--label-margin-size) 0;
    }
  }

  &--position-bottom {
    flex-direction: column-reverse;
    .label__text {
      margin: var(--label-margin-size) 0 0 0;
    }
  }
  &--position-left {
    flex-direction: row;
    .label__text {
      margin: 0 var(--label-margin-size) 0 0;
    }
  }

  &--position-right {
    flex-direction: row-reverse;
    .label__text {
      margin: 0 0 0 var(--label-margin-size);
    }
  }

  &--dark {
    color: var(--label-dark-color);
    &.label--disabled {
      .label__text {
        opacity: var(--label-floating-dark-disabled-opacity);
      }
    }
  }
}
