:root {
  --games-list-footer-padding: var(--size-10) 0;
  --games-list-actions-margin: var(--size-20) 0 0 0;
}

.games-list {
  &__footer {
    padding: var(--games-list-footer-padding);
    text-align: center;
  }

  &__actions {
    margin: var(--games-list-actions-margin);
  }
}
