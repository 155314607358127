:root {
  --notification-font-size: var(--font-size-14);
  --notification-font-weight: 700;
  --notification-padding: var(--size-8);
  --notification-border-radius: 4px;
  --notification-box-shadow: var(--box-shadow-1);
  --notification-icon-margin: 0 var(--size-8) var(--size-4) 0;
  --notification-icon-size: var(--font-size-20);

  // Success
  --notification-success-bg-color: transparent;
  --notification-success-color: var(--success-color);
  --notification-success-icon-color: var(--success-color);
  --notification-success-border: none;

  // Info
  --notification-info-bg-color: transparent;
  --notification-info-color: var(--grey-900);
  --notification-info-icon-color: var(--grey-900);
  --notification-info-border: none;

  // Warning
  --notification-warning-bg-color: transparent;
  --notification-warning-color: var(--warning-color);
  --notification-warning-icon-color: var(--warning-color);
  --notification-warning-border: none;

  // Error
  --notification-error-bg-color: transparent;
  --notification-error-color: var(--danger-color);
  --notification-error-icon-color: var(--danger-color);
  --notification-error-border: none;
}

.notification {
  border-radius: var(--notification-border-radius);
  padding: var(--notification-padding);

  &__message {
    text-align: center;
    font-weight: var(--notification-font-weight);
    font-size: var(--notification-font-size);
  }

  &__icon {
    margin: var(--notification-icon-margin);
    font-size: var(--notification-icon-size);
    line-height: 1;
    text-align: center;
  }

  &--with-box-shadow {
    box-shadow: var(--notification-box-shadow);
  }

  @each $type in info, error, success, warning {
    &--#{$type} {
      background-color: var(--notification-#{$type}-bg-color);
      color: var(--notification-#{$type}-color);
      border: var(--notification-#{$type}-border);
      .notification__icon {
        color: var(--notification-#{$type}-icon-color);
      }
    }
  }
}
