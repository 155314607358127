.account-limits-modal {
  &__description {
    margin-bottom: var(--size-16);
  }

  .input {
    &__label > span {
      color: var(--black);
    }

    &-validation-alerts {
      li > p {
        color: var(--error);
      }
    }

    .label--floating {
      border-radius: var(--size-4);
    }
  }
  .select-non-native {
    &__dropdown {
      z-index: 3;
    }
  }
}

.account-limits-footer {
  display: flex;
  justify-content: flex-end;

  .button:last-of-type {
    margin-left: var(--size-8);
  }
}

.deposit-limits-modal {
  &__btn-container {
    display: flex;
    justify-content: space-between;
    margin-top: var(--size-24);
  }
}
