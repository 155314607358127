@import '../../../../scss/global/utils/mixins';

.promotion-pre-deposit {
  padding-top: var(--size-24);

  &__buttons {
    text-align: center;
    margin-bottom: var(--size-28);
    margin-top: var(--size-20);

    @include respond(desktop) {
      margin-bottom: 0;
    }

    button:first-child {
      margin-right: var(--size-20);
    }
  }

  &__text,
  &__image-container,
  &__TnC-button {
    margin: var(--size-20) 0;
  }

  &__image-container {
    height: var(--size-128);
    overflow: hidden;
    border-radius: var(--size-10);

    img {
      max-width: 100%;
    }
  }

  &__heading {
    text-align: center;
  }

  &__submit-button {
    &.button {
      &.button--primary {
        &.button--large {
          width: 100%;
          margin-bottom: var(--size-20);
        }
      }
    }
  }

  &__text {
    text-align: center;
  }

  &__TnC-button {
    border: none;
    border-bottom: 1px solid var(--body-100);
    background: transparent;
    cursor: pointer;
  }

  &-TnC {
    width: 90%;
    margin: var(--size-10) auto;
  }

  .welcome-bonus-TnC-back {
    &.button {
      &.button--primary {
        width: 100%;
        margin-bottom: var(--size-10);
        height: var(--size-40);

        @include respond(desktop) {
          margin-bottom: 0;
        }
      }
    }
  }

  &__min-max-amount {
    span:nth-child(2) {
      float: right;
    }
  }
}
