:root {
  // loader
  --slide-loader-padding: var(--size-10);
  // loadingshape
  --slide-loader-shape-background: var(--white);
  --slide-loader-shape-border-radius: var(--size-10);
  --slide-loader-shape-opacity: 0.5;
}

.slide-loader {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  z-index: var(--z-index-carousel);
  pointer-events: none;
  opacity: 0;
  padding: var(--slide-loader-padding);
  gap: var(--carousel-slide-gap);

  &--show {
    opacity: 1;
  }

  &__item {
    width: 100%;
    height: 100%;
    background: var(--slide-loader-shape-background);
    border-radius: var(--slide-loader-shape-border-radius);
    opacity: var(--slide-loader-shape-opacity);
  }
}
