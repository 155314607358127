@import '../../../scss/global/utils/mixins';

.promotion-game-tags {
  &__list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    &::after {
      content: '';
      width: 33%;
    }
  }


  &__tile {
    width: 33%;

    /* min-height is needed so that if the tile does not have an image, then it will not break the grid. */
    min-height: 7.375rem;
    max-height: var(--size-96);
    overflow: hidden;

    > div {
      width: 100%;
      height: 100%;
    }

    &-image {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border: 1px solid transparent;
      border-radius: var(--size-10);
    }

    &.active-overlay {
      opacity: 0.4;
    }

    @include respond(phone) {
      width: 49%;
    }
  }
}
