@import '../../scss/betzone-uk/utils/mixins.betzone-uk';
:root {
  // Hero banner's main container
  --hero-banner-default-bg-color: var(--grey-100);
  --hero-banner-border-radius: var(--size-16);
  // Content Container
  --hero-banner-container-border-radius: 0 0 var(--size-16) var(--size-16);
  --hero-banner-container-background: linear-gradient(
    180deg,
    rgba(8, 8, 8, 0) 0%,
    rgba(8, 8, 8, 0.36) 42.57%,
    rgba(8, 8, 8, 0.8) 100%
  );
  // Hero banner's title
  --hero-banner-title-color: var(--white);
  --hero-banner-title-font-weight: 700;
  // Size specific variables
  // Desktop
  --hero-banner-desktop-height: 17.1875rem;
  --hero-banner-desktop-title-font-size: var(--font-size-32);
  --hero-banner-desktop-title-line-height: var(--size-48);
  --hero-banner-desktop-content-container-padding: 1.1875rem var(--size-32)
    1.4375rem var(--size-32);
  // Tablet
  --hero-banner-tab-port-height: 14.4375rem;
  --hero-banner-tab-port-title-font-size: var(--font-size-24);
  --hero-banner-tab-port-title-line-height: var(--size-36);
  --hero-banner-tab-port-content-container-padding: 1.5625rem var(--size-24)
    1.375rem var(--size-24);
  // Mobile
  --hero-banner-phone-height: 14.4375rem;
  --hero-banner-phone-title-font-size: var(--font-size-20);
  --hero-banner-phone-title-line-height: 1.875rem;
  --hero-banner-phone-content-container-padding: 1.8125rem var(--size-24)
    var(--size-24) var(--size-24);
}

.hero-banner {
  position: relative;
  width: 100%;
  border-radius: var(--hero-banner-border-radius);
  background-color: var(--dark-blue-100);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__content-container {
    height: auto;
    width: 100%;
    position: absolute;
    bottom: 0;
    background: var(--hero-banner-container-background);
    border-radius: var(--hero-banner-container-border-radius);
    .hero-banner__title {
      color: var(--hero-banner-title-color);
      font-weight: var(--hero-banner-title-font-weight);
    }
  }
  @each $size in 'desktop', 'tab-port', 'phone' {
    @include respond($size) {
      height: var(--hero-banner-#{$size}-height);
      &__content-container {
        padding: var(--hero-banner-#{$size}-content-container-padding);
        .hero-banner__title {
          font-size: var(--hero-banner-#{$size}-title-font-size);
          line-height: var(--hero-banner-#{$size}-title-line-height);
        }
      }
    }
  }
}
