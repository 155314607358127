.account-limits-item-status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: var(--paragraph-highlight-font-weight);
  width: 100%;
  background-color: var(--white);
  padding: var(--size-16);
  border-radius: var(--size-10);

  hr {
    border-width: 0;
    margin: var(--size-16) 0;
    border-top: 0.5px solid var(--body-10);
  }

  &__details {
    display: flex;
    justify-content: space-between;
  }

  &__container {
    display: flex;
    justify-content: space-between;
  }

  &__amount,
  &__title {
    font-size: var(--size-12);
  }
}
