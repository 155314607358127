:root {
  // Toggle
  --country-code-toggle-top: 0;
  --country-code-toggle-width: var(--size-80);
  --country-code-color: var(--input-color);

  // Filter
  --country-code-filter-top: var(--select-dropbox-position-top);
  --country-code-filter-border-radius: 0;
  --country-code-filter-box-shadow: 0 var(--size-2) var(--size-10) rgba(0, 0, 0, 0.2);

  // Filter item
  --country-code-item-background-hover: var(--select-dropbox-items-active-background-color);
  --country-code-item-color: var(--grey-700);
  --country-code-item-font-family: inherit;
  --country-code-item-font-size: var(--font-size-14);
  --country-code-item-font-weight: 500;
  --country-code-item-color-hover: var(--grey-700);
}

// Toggle button
.country-code__toggle {
  --input-bg-color: transparent;
  --input-color: var(--country-code-color);

  position: absolute;
  top: var(--country-code-toggle-top);
  width: var(--country-code-toggle-width);
  z-index: var(--z-index-country-code);

  .input,
  .input__box-container,
  .input__box {
    height: 100%;
  }

  .input__box {
    border: none !important;
    padding-right: var(--size-32);
  }

  .input__icon {
    pointer-events: none;
  }
}

// Dropdown filter
.country-code__filter {
  padding-top: var(--country-code-filter-top);
  position: absolute;
  top: 0;
  width: 100%;
  z-index: var(--z-index-country-code);

  & > div {
    border-radius: var(--country-code-filter-border-radius);
    box-shadow: var(--country-code-filter-box-shadow);
    overflow: hidden;
  }

  // Search input
  .input__box {
    --input-border: none;
    --input-border-radius: 0;
    --input-completed-border: none;
    --input-hover-border: none;
    --input-focus-border: none;
  }

  .menu {
    border: none;
    border-radius: 0;
  }

  .menu-item {
    --menu-item-active-background-color: var(--country-code-item-background-hover);

    color: var(--country-code-item-color);
    font-family: var(--country-code-item-font-family);
    font-size: var(--country-code-item-font-size);
    font-weight: var(--country-code-item-font-weight);

    &--active {
      color: var(--country-code-item-color-hover);
    }
  }
}
