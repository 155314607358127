@import '../../../scss/global/utils/mixins';

.game-card-label {
  display: flex;
  justify-content: center;
  padding: var(--size-4);
  position: absolute;
  right: 0;
  top: -0.45rem;

  @include respond(phone) {
    top: 0;
  }

  font-weight: var(--game-card-basic-title-font-weight);
  color: var(--dark-blue-100);
  background: var(--yellow-100);
  border-radius: var(--size-4);
  z-index: 1;

  @include respond(phone) {
    right: var(--size-8);
  }
}
