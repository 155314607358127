:root {
  --games-carousel-slide-padding: var(--size-10);
  --games-carousel-pagination-normal-color: black;
  --games-carousel-pagination-overlay-color: white;
  --games-carousel-pagination-drop-shadow: 0 0 var(--size-4) black;
  --games-carousel-pagination-button-size-normal: 10rem;
  --games-carousel-pagination-button-size-small: 6rem;
  --games-carousel-pagination-small-padding: 0
    calc(var(--games-carousel-pagination-button-size-small) * 0.3);
  --games-carousel-pagination-normal-padding: 0
    calc(var(--games-carousel-pagination-button-size-small) * 0.45);
}

.games-carousel {
  position: relative;

  // pure-react-carousel styles
  .carousel {
    &__slide {
      display: flex;
      align-items: center;

      &::before {
        height: auto;
      }
    }

    &__inner-slide {
      padding: var(--games-carousel-slide-padding);
    }
    &__next-button {
      right: 0;
      transform-origin: right;
    }

    &__back-button {
      left: 0;
      transform-origin: left;
    }
  }

  &__pagination-button {
    display: none;
    position: absolute;
    z-index: var(--z-index-games-list-pagination);
    top: 0;
    bottom: 0;
    border: none;
    background-color: transparent;
    align-items: center;
    font-size: var(--games-carousel-pagination-button-size-normal);
    transform: scaleX(0.3);
    opacity: 0.4;
    transition: opacity 0.3s;

    // Icon shadow
    svg {
      filter: drop-shadow(var(--games-carousel-pagination-drop-shadow));
    }

    &:hover {
      opacity: 0.7;
    }

    &:active {
      opacity: 0.9;
    }

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }

  &--pagination-normal {
    padding: var(--games-carousel-pagination-normal-padding);

    .games-carousel__pagination-button {
      display: flex;
      color: var(--games-carousel-pagination-normal-color);
    }
  }

  &--pagination-overlay {
    .games-carousel__pagination-button {
      display: flex;
      color: var(--games-carousel-pagination-overlay-color);
    }
  }

  &--size-small {
    .games-carousel__pagination-button {
      font-size: var(--games-carousel-pagination-button-size-small);
    }

    &.games-carousel--pagination-normal {
      padding: var(--games-carousel-pagination-small-padding);
    }
  }
}
