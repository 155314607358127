.account-notifications {
  min-height: var(--size-32);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  &__icon {
    margin-right: var(--size-8);

    &--info {
      color: var(--info);
    }

    &--warning {
      color: var(--warning);
    }
  }

  &__risk-assessments {
    align-items: flex-start;
    margin-top: var(--size-4);

    svg {
      color: var(--warning);
      font-size: var(--size-16);
      margin-right: var(--size-8);
    }

    .button {
      margin-top: calc(-1 * var(--size-2));
    }
  }
}
