@import '../../../../scss/global/utils/mixins';

.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  overflow: hidden;
  background-color: var(--primary-900);

  &__header-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--size-72);
    background-color: var(--primary-900);
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include respond(desktop) {
      flex-direction: row;
      padding: var(--size-20);
      margin-top: var(--size-72); /* Increased margin-top for more space */
    }
  }

  &__image-section {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--size-20);

    @include respond(desktop) {
      order: 2;
    }
  }

  &__image {
    max-width: 100%;
    height: auto;
    border-radius: var(--size-8);
  }

  &__text-section {
    flex: 1;
    padding: var(--size-20);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    @include respond(desktop) {
      text-align: left;
      padding: var(--size-20);
    }
  }

  &__title {
    margin-bottom: var(--size-20);
    font-size: var(--font-size-24);
    color: var(--body-10);

    @include respond(desktop) {
      font-size: var(--font-size-36);
    }
  }

  &__message {
    margin-bottom: var(--size-20);
    font-size: var(--font-size-16);
    color: var(--body-10);
  }

  &__cta-btn {
    align-self: center;

    @include respond(desktop) {
      align-self: flex-start;
      width: auto;
    }
  }
}
