@import '../../../scss/global/utils/mixins';

:root {
  --inner-html-section-gap: var(--size-24);
  --inner-html-list-margin: var(--size-16) 0;
  --inner-html-list-indent: var(--size-36);
  --inner-html-list-item-gap: var(--size-8);
}

.inner-html {
  @each $var in 1, 2, 3, 4, 5, 6 {
    h#{$var} {
      @include headingStyles($var);
    }

    // Gap between text section and next heading
    > *:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) + h#{$var} {
      margin-top: var(--inner-html-section-gap);
    }
  }

  p {
    @include paragraphStyles('m');
  }

  ol,
  ul {
    margin: var(--inner-html-list-margin);
    padding-left: var(--inner-html-list-indent);
    list-style-type: disc;

    li {
      margin-bottom: var(--inner-html-list-item-gap);
    }
  }

  ol {
    list-style-type: decimal;
  }
}
