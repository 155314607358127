:root {
  --link-font-weight: bold;
  --link-focus-outline: var(--focus-outline);
  --link-focus-outline-offset: var(--focus-outline-offset);
  --link-large-font-size: var(--font-size-18);
  --link-large-line-height: inherit;
  --link-large-icon-size: inherit;
  --link-large-icon-margin: 5px;
  --link-medium-font-size: var(--body-font-size);
  --link-medium-line-height: inherit;
  --link-medium-icon-size: inherit;
  --link-medium-icon-margin: 5px;
  --link-small-font-size: var(--font-size-14);
  --link-small-line-height: inherit;
  --link-small-icon-size: inherit;
  --link-small-icon-margin: 5px;
}

.link {
  color: var(--link-color);
  font-weight: var(--link-font-weight);
  cursor: pointer;

  &:focus {
    outline: var(--link-focus-outline);
    outline-offset: var(--link-focus-outline-offset);
  }

  &:active {
    color: var(--link-active-color);
    text-decoration: underline;
  }

  &:hover {
    text-decoration: underline;
    color: var(--link-hover-color);
  }

  @each $size in small, medium, large {
    &.link--#{$size} {
      font-size: var(--link-#{$size}-font-size);
      line-height: var(--link-#{$size}-line-height);
      display: inline-flex;
      align-items: center;

      .link__icon-right,
      .link__icon-left {
        font-size: var(--link-#{$size}-icon-size);
        height: var(--link-#{$size}-icon-size);
        display: inline-block;
        line-height: 1;
      }

      .link__icon-left {
        margin-right: var(--link-#{$size}-icon-margin);
      }

      .link__icon-right {
        margin-left: var(--link-#{$size}-icon-margin);
      }
    }
  }

  &--auto {
    font-size: inherit;
    line-height: inherit;

    .link__icon-right,
    .link__icon-left {
      line-height: 1;
    }

    .link__icon-left {
      margin-right: var(--link-medium-icon-margin);
    }

    .link__icon-right {
      margin-left: var(--link-medium-icon-margin);
    }
  }
}
