@import '../../../../scss/global/utils/mixins';

.my-bonus-history,
.promotion-bonus-history {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overscroll-behavior: contain;

  &__heading {
    padding: var(--size-8) var(--size-16);

    @include respond(desktop) {
      width: 31rem;
    }
  }

  &__content {
    flex-grow: 1;
    background-color: var(--body-5);
    border-radius: var(--size-16) var(--size-16) 0 0;
    min-height: max-content;
    padding: var(--size-16) 0 var(--size-6);

    .inner-html {
      color: var(--body-80);
      size: var(--size-12);
    }

    .tdl__total-results {
      padding-left: var(--size-16);
    }

    @include respond(phone) {
      overflow-y: auto;
    }

    .content-preview {
      &__content--preview {
        &::after {
          background: linear-gradient(0deg, var(--white) 0%, transparent 100%);
        }
      }

      &__buttons {
        span {
          color: var(--body-90);
          padding: var(--size-16) 0;
        }
      }
    }

    .tdl > div {
      color: var(--body-90);
      margin-bottom: var(--size-16);
    }

    .tdl-item__button {
      padding-left: var(--size-8);
    }

    svg {
      width: var(--size-12);
      height: var(--size-12);
    }
  }

  &__daterange {
    margin-top: var(--size-16);

    input,
    .input__box-container {
      border-radius: var(--size-8);
    }

    .input {
      svg {
        color: var(--sub-nav-pills-color);
      }
    }
  }

  &__summary {
    display: flex;
    flex-direction: column;

    &--heading {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: var(--font-size-16);
      line-height: var(--size-20);
      font-weight: var(--typography-highlight-font-weight);
      color: var(--body-95);
    }

    &--subheading {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: var(--font-size-10);
      line-height: var(--size-14);
      color: var(--body-60);
      margin-top: var(--size-8);
      color: var(--body-95);

      &-status {
        display: flex;
        flex-direction: row;
        align-items: baseline;

        &-icon {
          margin-left: var(--size-4);
        }
      }
    }
  }

  &__cancel-button {
    text-align: center;
    margin: var(--size-32) 0 var(--size-24);

    .button.button--text {
      font-size: var(--font-size-14);
      font-weight: var(--btn-font-weight);
      margin-right: 0;

      &:focus {
        outline: none;
      }
    }
  }

  .tdl-item__content {
    padding: 0;
  }

  &__body {
    width: 100%;
    background-color: var(--grey-010);
    border-radius: 0 0 var(--tdl-item-border-radius) var(--tdl-item-border-radius);

    &-section {
      border-radius: var(--tdl-item-border-radius);
      margin: var(--size-16);
      padding: var(--tdl-item-padding);
      background-color: var(--white);

      &--content {
        color: var(--body-60);
      }

      &--field {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      &--value {
        color: initial;
      }
    }
  }

  @include respond(phone) {
    &__no-bonuses {
      position: absolute;
      top: var(--size-384);
      width: 92%;
    }
  }
}

.promotion-bonus-history {
  margin: 0;
  overflow-y: initial;
  overscroll-behavior: none;

  @include respond(phone) {
    margin: var(--size-24) 0 var(--size-24);
  }

  @include respond(tab-port) {
    margin: var(--size-24) 0 var(--size-24);
  }

  @include respond(tab-land) {
    margin: var(--size-24) 0 var(--size-24);
  }

  @include respond(desktop) {
    padding-right: 0;
  }

  &__content {
    border-radius: var(--size-8);
    background-color: var(--body-20);
    > div:nth-child(2) {
      background-color: var(--body-20);
    }
  }

  &__heading {
    padding: var(--size-8) 0;

    .heading {
      color: var(--white);
      font-size: var(--size-16);
    }
  }

  .react-datepicker{
    padding-top: var(--size-20);
  }

  .react-datepicker__navigation{
    top: var(--size-16);

    &--previous{
      left: var(--size-16);
    }

    &--next{
      right: var(--size-16);
    }
  }
}
