.account-tile-list-item.reality-check-list-item {
  .account-tile-list-item__content {
    .paragraph {
      flex-grow: 1;
      color: var(--body-100);
      font-size: var(--font-size-14);
    }

    .button {
      color: var(--body-50);
      margin-left: var(--size-20);
      margin-right: var(--size-8);
      font-size: var(--font-size-12);
    }
  }
}
