.cancel-bonus-confirmation {
  .prompt {
    &__heading {
      padding-bottom: var(--size-10);
    }

    &__content {
      padding-bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .paragraph {
        a {
          text-decoration: underline;
          vertical-align: inherit;
        }
      }
    }
  }

  .touch-drawer {
    &__footer {
      .button.button--large {
        margin-bottom: var(--size-32);
      }
    }
  }
}
