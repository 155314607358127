@import '../../../../scss/global/utils/mixins';

$navigation-drawer-width: 16rem;

.navigation-drawer-backdrop {
  background-color: var(--backdrop-bg-color);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: var(--navigation-drawer-z-index);

  &.ReactModal__Overlay--after-open {
    opacity: 1;
  }

  &.ReactModal__Overlay--before-close {
    opacity: 0;
  }
}

.navigation-drawer {
  position: absolute;
  outline: 0;
  height: 100%;
  top: 0;
  transform: translateX(-#{$navigation-drawer-width});
  width: $navigation-drawer-width;
  transition: all 0.3s ease-in-out;
  background-color: var(--dark-blue-75);
  overflow: auto;

  @include respond(tab-port) {
    padding-top: var(--header-height);
  }

  @include respond(phone) {
    padding-top: var(--header-mobile-height);
  }

  &.ReactModal__Content--after-open {
    transform: translateX(0);
  }

  &.ReactModal__Content--before-close {
    transform: translateX(-#{$navigation-drawer-width});
  }
}
