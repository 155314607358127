@import '../../../scss/global/utils/mixins';

.game-slider-prompt {
  .modal-footer,
  .touch-drawer__footer {
    .game-slider {
      &__summary-block-button {
        display: flex;
        justify-content: space-around;
        width: 100%;

        .button {
          padding: var(--btn-medium-padding);
          width: 100%;
          &--secondary-alt {
            min-width: 45%;
          }

          @include respond(phone) {
            margin-right: var(--size-8);

            &:first-of-type {
              margin-left: calc(var(--size-3) - var(--size-2));
            }

            &:last-of-type {
              margin-right: var(--size-4);
            }
          }
        }

        &--primary {
          min-width: 45%;
        }
      }
    }
  }
  .modal-footer {
    padding: var(--size-16) calc(var(--size-27) - var(--size-2));
  }
}
