.self-exclusion-modal {
  .modal-body__content {
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      max-width: var(--size-10);
    }
    &::-webkit-scrollbar-track {
      background-color: var(--primary-900);
    }
  }
}

.self-exclusion {
  &__paragraph-1 {
    font-weight: var(--text-highlight-weight-bold);

    .button.button--text {
      text-decoration: underline;
      font-weight: var(--text-highlight-weight);
      color: var(--paragraph-color);
    }
  }

  &__rules {
    padding: var(--size-16) 0;
  }

  &__rule {
    display: flex;
    align-items: center;
    margin-bottom: var(--size-16);
  }

  &__rule-icon {
    font-size: var(--font-size-20);
    color: var(--body-40);
    margin-right: var(--size-16);
  }

  &__rule-text {
    flex: 1;
  }

  &__paragraph {
    margin-left: 1.825rem;
  }

  &__withdrawal-check {
    &--buttons {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    &-mobile--buttons {
      width: 100%;
      display: flex;
      flex-direction: column;

      button {
        width: 100%;

        &:first-child {
          margin-bottom: 1rem;
        }
      }
    }
  }

  &__confirmation-paragraph-2 {
    text-size-adjust: none;

    p {
      margin-bottom: var(--size-12);
    }
  }
}

.u-action-footer {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.self-exclusion-modal {
  .paragraph {
    margin-bottom: var(--size-8);
  }
  .rule-paragraph {
    margin-left: var(--size-8);
  }
}
