.limits-history {
  &__change {
    display: flex;
    align-items: center;
  }

  &__change-info {
    flex: 1;
  }

  &__change-time {
    color: var(--body-60);
  }

  &.modal {
    .modal {
      &__container {
        padding-bottom: 0;
        overflow: hidden;
      }
    }
  }

  &__intro-text {
    padding: 0 var(--size-24);
  }

  .touch-drawer,
  .modal-body {
    &__content {
      padding: 0;
    }
  }

  .touch-drawer {
    &__content {
      background: 0;
    }
  }

  &__list {
    padding: var(--size-24);
    background: var(--body-5);
    border-radius: var(--size-16) var(--size-16) 0 0;
  }

  &__no-results {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__no-results-text {
    position: relative;
    height: var(--size-40);
    font-family: var(--body-font-family);
    font-size: var(--font-size-14);
    font-style: normal;
    font-weight: var(--paragraph-highlight-font-weight);
    text-align: center;
    color: var(--medium-blue);
  }
}
