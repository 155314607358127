.account-limits-item {
  &:not(:last-child) {
    margin-bottom: var(--size-8);
  }

  .account-tile {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  &-content {
    display: flex;
    justify-content: space-between;
  }

  &-controls {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--size-16);

    .button,
    .button:hover {
      color: var(--black);
      font-size: var(--size-14);

      &:last-child {
        display: block;
      }
    }
  }
}
