@import '../../../../scss/global/utils/mixins';

.game-tags-card {
  &__tags {
    flex-wrap: wrap;
    display: flex;
    gap: var(--size-8);

    @include safari-grid {
      .pill {
        margin: 0 var(--size-8) var(--size-8) 0;
      }
    }
  }

  &__value {
    color: var(--pill-default-strong-color);
    font-weight: 600;

    &:not(:first-child) {
      margin-left: var(--pill-large-strong-margin-left);
    }
  }
}
