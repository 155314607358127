.account-page-wrapper {
  height: 100%;
  overflow-y: auto;
  position: absolute;
  color: var(--body-100);
  overflow-x: hidden;
  width: 100%;

  * {
    // We need to override metrics styles from https://betzone-uk.mg-ppe.ovh/betzone-current/metric-gc/styles.css
    font-family: var(--body-font-family) !important;
  }

  .heading--3 {
    color: var(--white);
  }

  &__nav-bar,
  &__footer {
    height: 3.5rem;
    width: 100%;
    z-index: var(--z-index-overlay-content);
    background-color: var(--background-account-wrapper);
    color: var(--white);
    transition: background-color 0.2s ease-in-out;
    padding: 1.125rem var(--size-16);
    display: flex;
  }

  &__footer {
    bottom: 0;
    position: sticky;
    background-color: var(--white);
    border-radius: var(--size-16) var(--size-16) 0 0;
  }

  &__back-btn {
    margin-right: auto;
  }

  &__close-btn {
    margin-left: auto;

    &.button.button--text {
      color: var(--white);
      font-weight: var(--font-weight-regular);
    }
  }

  &--with-dark-banner {
    color: var(--white);

    .account-page-wrapper__nav-bar {
      background-color: transparent;
    }
  }

  &--is-scrolled {
    .account-page-wrapper__nav-bar {
      position: sticky;
      top: 0;
      background-color: var(--background-account-wrapper);
      box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.05);
      color: var(--white);
    }
  }
}

.spin-zone-menu__landscape-mode {
  position: relative;
}
