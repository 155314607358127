@import '../../../../scss/global/utils/mixins';

$game-card-width: 9.375rem;

.recommended-game-carousel {
  margin-bottom: var(--size-12);
  margin-left: var(--size-10);
  overflow: hidden;

  .mobile-games-carousel {
    padding-left: 0;
    margin: 0;
    &__slider {
      padding: 0;
    }
  }

  .carousel-wrapper {
    &__header {
      padding-left: 0;
      height: auto;
    }
    &__title {
      color: var(--primary-900);
      padding-left: 0;
    }
    .carousel {
      margin-left: calc(-1 * var(--size-10));
      padding-left: var(--size-10);
      &__slide-inner {
        position: relative;
      }

      &-wrapper__header {
        padding-bottom: var(--size-8);
      }

      h4 {
        padding: 0;
      }
    }
  }
}

.recently-played {
  width: 100%;

  &__text {
    color: var(--white);
    font-size: var(--font-size-16);
    line-height: var(--font-size-24);
    font-weight: var(--heading-font-weight);
    @include respond(phone) {
      font-size: var(--font-size-14);
    }
  }

  &__game-cards {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: var(--size-4);

    &::-webkit-scrollbar {
      display: none;
      height: var(--size-12);

      @include respond(desktop) {
        display: block;
      }
    }

    &::-webkit-scrollbar-track {
      background: var(--body-80);
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 var(--size-10) var(--size-10) var(--grey-200);
      border: solid 3px transparent;
      border-radius: var(--size-12);
    }

    &::after {
      content: '';
      width: 6.25rem;
    }

    @include respond(tab-land) {
      &::after {
        content: '';
        width: $game-card-width;
      }
    }
  }

  &__game-card-wrap {
    flex: 0 0 auto;
    margin-top: var(--size-16);
    margin-right: var(--size-4);
    width: var(--size-112);
    height: var(--size-72);
    position: relative;
  }
}
