@import '../../../../scss/global/utils/mixins';

.navigation-link {
  flex: 1;
  color: var(--dark-blue-25);
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 0;
  justify-content: flex-start;
  border-radius: var(--border-radius-right-rounded);
  padding: var(--size-16) 0.84375rem var(--size-16) 0.84375rem;
  text-decoration: none;

  @include respond(tab-land) {
    margin-bottom: var(--size-2);
  }

  &:hover {
    background-color: var(--dark-blue-50);
    color: var(--white);
  }

  &__text {
    // TODO: Create a typography font-size/line-height mixin
    font-size: var(--font-size-10);
    line-height: var(--font-size-14);
    margin-top: var(--size-2);
    font-weight: 600;
    color: var(--body-10);

    @include respond(tab-land) {
      margin-top: var(--size-4);
      text-align: center;
    }
  }
  &__icon--withBadge {
    position: relative;
  }

  &__icon-badge {
    position: absolute;
    right: -0.25rem;
    top: -0.25rem;

    @include respond(tab-land) {
      right: -0.125rem;
      top: -0.1875rem;
    }

    .badge {
      display: block;
    }
  }

  &__icon {
    #lottie {
      height: var(--size-40);
      width: 100%;
    }

    svg,
    img {
      display: block;
      height: var(--size-20);
      width: auto;
      fill: var(--dark-blue-25);
      margin: auto;

      @include respond(tab-land) {
        height: var(--size-24);
      }
    }
  }

  &--selected {
    .navigation-link__text {
      color: var(--white);
    }

    background-color: var(--dark-blue-50);
  }

  &__chevron-right {
    display: none;
  }

  &__external-link {
    margin-right: var(--size-16);
    svg {  
      fill-opacity: 0;
      height: var(--size-16);
    }
  }

  &--expanded {
    width: 15rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: var(--size-16) 0 var(--size-16) var(--size-32);

    .navigation-link {
      &__wrapper {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: space-between;
      }

      &__text {
        margin-left: var(--size-16);
        font-size: var(--font-size-14);
        line-height: var(--font-size-20);
        margin-top: 0;
        color: var(--body-10);
      }

      &__chevron-right {
        display: flex;
        align-items: center;
        padding-right: var(--size-8);
        svg {
          height: var(--size-10);
        }
      }
    }
  }

  .u-position-relative {
    .navigation-link__icon {
      display: flex;
      justify-content: center;
    }
  }

  &-top-wrapper {
    > div {
      @include navigation-top;

      flex-direction: column;
    }

    &--expanded {
      + .navigation-top {
        &__divider {
          @include navigation-divider;
        }
      }

      > div {
        @include navigation-top;

        flex-direction: row;
      }

      @include respond(phone) {
        height: var(--size-80);
      }

      .navigation-link {
        &--expanded {
          padding: 0;
          @include navigation-link-expanded;
        }
      }
    }

    + .navigation-top {
      &__divider {
        @include navigation-divider;
      }
    }

    .navigation-link {
      padding: var(--size-16) 0 0;

      @include navigation-link;
    }
  }
}
