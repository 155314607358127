.game-menu-tile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--size-8);
  background-color: var(--body-80);
  color: var(--white);
  padding: var(--size-16);
  margin-bottom: var(--size-12);
  width: 100%;

  &--no-padding {
    padding: 0;
  }

  &--no-margin {
    margin: 0;
  }
}
