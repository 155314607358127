@import '../../../../../scss/global/utils/media-queries';

:root {
    // Requirements Section
    --password-requirements-container-margin: var(--size-8) 0 0 0;
    --password-requirements-msg-font-weight: bold;
    --password-requirements-msg-font-size: var(--font-size-10);
    --password-requirements-msg-margin: 0 0 var(--size-16) 0;
    --password-requirements-list-font-size: var(--font-size-12);
    --password-requirements-list-title-color: var(--primary-900);
    --password-requirements-list-title-font-weight: bold;
    --password-requirements-list-title-margin: 0 0 var(--size-4) 0;
    --password-requirements-list-item-padding: 0.125rem 0;
    --password-requirements-lists-stacked-gap: var(--size-12);
    --password-requirements-border: 0.0625rem solid var(--body-20);
    --password-requirements-border-radius: 0.125rem;
    --password-requirements-container-padding: 0.875rem var(--size-12) 0.6875rem var(--size-12);
    --password-requirements-list-item-line-height: 1.125rem;
    --password-requirements-list-item-paragraph-margin: 0 0 0 0.5625rem;
    --password-requirements-list-item-icon-width: var(--size-12);
    --password-requirements-list-item-icon-height: var(--size-12);
    --password-requirements-list-item-icon-color-success: var(--success);
    --password-requirements-list-item-icon-color-error: var(--error);
    --password-requirements-dark-paragraph-color: var(--body-30);
    --password-requirements-dark-bg-color: var(--dark-blue-100);
    --password-requirements-dark-border: 0.0625rem solid #666;
    --password-requirements-dark-icon-error-height: var(--size-10);
}

.password-requirements {
  padding: var(--password-requirements-container-padding);
  margin: var(--password-requirements-container-margin);
  border: var(--password-requirements-border);
  border-radius: var(--password-requirements-border-radius);

  &__requirements-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: var(--password-requirements-list-item-line-height);
    padding: var(--password-requirements-list-item-padding);

    .paragraph {
      margin: var(--password-requirements-list-item-paragraph-margin);
    }
  }

  &__icon {
    height: var(--password-requirements-list-item-icon-height);
    width: var(--password-requirements-list-item-icon-width);

    &--success {
      color: var(--password-requirements-list-item-icon-color-success);
    }

    &--error {
      color: var(--password-requirements-list-item-icon-color-error);
    }
  }

  &--dark {
    background-color: var(--password-requirements-dark-bg-color);
    border: var(--password-requirements-dark-border);

    .paragraph {
      color: var(--password-requirements-dark-paragraph-color);
    }

    .password-requirements__icon--error {
      height: var(--password-requirements-dark-icon-error-height);
    }
  }

  &__requirements-message {
    font-weight: var(--password-requirements-msg-font-weight);
    margin: var(--password-requirements-msg-margin);
    font-size: var(--password-requirements-msg-font-size);
  }

  &__lists-container {
    font-size: var(--password-requirements-list-font-size);

    @include respond(tab-land) {
      display: flex;
    }
  }

  &__requirements-list {
    &:first-child {
      margin-bottom: var(--password-requirements-lists-stacked-gap);
    }

    @include respond(tab-land) {
      margin-bottom: 0;
      flex: 1;
    }
  }

  &__requirements-list-title {
    color: var(--password-requirements-list-title-color);
    font-weight: var(--password-requirements-list-title-font-weight);
    margin: var(--password-requirements-list-title-margin);
    line-height: var(--password-requirements-list-title-line-height);
  }
}
