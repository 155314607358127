@import '../../../scss/global/utils/media-queries';

.notification-wrapper {
  &__bar {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: var(--size-12);
    font-weight: var(--link-font-weight);
    background-color: var(--primary-900);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1.2rem;
    z-index: var(--z-index-over-all);
  }

  &__img {
    font-size: var(--size-16);
    margin-right: var(--size-8);
    margin-bottom: var(--size-8);
    line-height: 0;
  }

  &__secondary-btn {
    text-transform: uppercase;
    margin-right: var(--size-16);

    &.button {
      color: var(--white);

      &:hover {
        color: var(--grey-500);
      }
    }
  }

  &__content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 8px;
  }

  &__buttons {
    margin-left: auto;
  }

  &__button {
    text-transform: uppercase;
  }

  &--disabled-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--modal-backdrop-background-light-color);
    z-index: var(--z-index-touch-drawer);

    &-soft {
      background-color: transparent;
    }
  }

  @include respond(phone) {
    &__buttons {
      width: 100%;
      display: flex;
      margin-top: 1.2rem;
      align-items: center;
      justify-content: space-between;

      button {
        flex-basis: 100%;
      }
    }

    &__secondary-btn {
      font-size: var(--font-size-12);
      padding: var(--size-10) var(--size-16);
    }
  }
}
