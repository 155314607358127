.reality-checks {
	margin-top: var(--size-24);

	&__undo-btn {
		&.button--text,
		&.button--text:hover {
			color: var(--white);
			text-decoration: none;
		}
	}
}
