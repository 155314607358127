:root {
  --form-control-switch-flex-direction: row;
  --form-control-switch-padding: var(--size-16);
  --form-control-switch-border-radius: var(--size-4);
  --form-control-switch-background: var(--grey-200);
  --form-control-switch-gap: var(--size-16);
  --form-control-switch-border: none;
}

.form-control-switch {
  display: flex;
  align-items: center;
  flex-direction: var(--form-control-switch-flex-direction);
  padding: var(--form-control-switch-padding);
  border: var(--form-control-switch-border);
  border-radius: var(--form-control-switch-border-radius);
  background: var(--form-control-switch-background);
  gap: var(--form-control-switch-gap);

  &__children {
    flex: 1;
  }

  &__switch,
  &__children {
    display: flex;
    align-items: center;
  }
}
