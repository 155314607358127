@import '../../../../scss/global/utils/mixins';

$constant-prompt-height: 27.5rem;

#cashier-container {
  max-height: 60vh;
  width: 100%;
  overflow: scroll;
  background-color: var(--white);
  -ms-overflow-style: none;

  @include respond(tab-land) {
    top: 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.cashier__drawer {
  .touch-drawer__viewport {
    padding: 0;
  }
  .touch-drawer__content {
    background-color: var(--white);
    min-height: $constant-prompt-height;
    padding: 0;
    -ms-overflow-style: none;

    @include respond(phone-land) {
      min-height: 15rem;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.cashier {
  &__content {
    padding: var(--size-20);
    text-align: center;
    border-radius: var(--size-8);
  }

  &__modal {
    box-shadow: none;
    &.modal {
      .modal__container {
        border-radius: var(--size-18);
        padding-bottom: 0;
      }
    }

    .modal-header {
      background-color: var(--dark-blue-100);
      padding: var(--size-20);

      &__heading {
        color: white;
        text-align: left;
      }
    }

    .modal-body {
      background-color: var(--white);

      &__content {
        min-height: $constant-prompt-height;
        padding: 0;

        @include flex-center-all;
      }
    }

    .custom-backdrop {
      background: rgba(var(--dark-blue-100-rgb), 0.5);
    }
  }

  &__success-message {
    @include messageColor(var(--success-dark));
  }

  &__error-message {
    @include messageColor(var(--error));
  }

  &__info-message {
    @include messageColor(var(--info));
  }
}

.pof-prompt {
  .heading {
    text-align: center;
  }
  .pof-li {
    margin-left: var(--size-6);
  }
  .paragraph {
    text-align: center;
    margin-bottom: var(--size-4);
  }
}
