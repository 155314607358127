@import '../../../../../../scss/global/utils/mixins';

.modal-header {
  .heading {
    font-size: var(--size-14);
    line-height: var(--size-20);
  }
}

.balance-details {
  &__card-body {
    border-radius: var(--size-8);
    display: flex;
    flex-direction: column;
    gap: var(--size-8);
  }

  &__item {
    display: flex;
    align-items: center;
    gap: var(--size-8);
    background-color: var(--primary-500);
    padding: var(--size-12) var(--size-16);
    border-radius: var(--size-8);

    .promotion-progress-bar {
      background-color: var(--body-5);
      &__summary-heading {
        padding-top: 0;
        padding-bottom: var(--size-2);
      }

      &__summary-title,
      &__summary-amount,
      &__summary-amount-wager {
        color: var(--body-5);
      }

      &__summary-title {
        font-size: var(--size-16);
      }

      &__summary-subheading {
        font-weight: var(--text-highlight-weight-thin);
        color: var(--body-20);
      }

      &__summary-amount,
      &__summary-amount-wager {
        font-size: var(--size-14);
        font-family: var(--body-secondary-font-family);
        font-weight: var(--typography-highlight-font-weight);
      }

      &__summary-expire {
        color: var(--body-20);
        padding-top: var(--size-2);
      }
    }
  }

  &__pop-up {
    @include respond(phone) {
      .touch-drawer {
        &__container {
          .touch-drawer__viewport {
            .touch-drawer__content {
              background-repeat: repeat;
            }
          }
        }

        &__footer {
          .button {
            &:not(.button--text) {
              &:not(.button--text-alt).button--medium {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
