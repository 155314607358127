:root {
  // tag
  --chip-tag-font-size: var(--font-size-10);
  --chip-tag-font-weight: 700;
  --chip-tag-border-radius: 0.25rem;
  --chip-tag-border: 1px solid var(--primary-500);
  --chip-tag-padding: 0.25rem 0.3125rem;
  --chip-tag-color: var(--grey-500);
  --chip-tag-background: inherit;
  // tag hover
  --chip-tag-hover-color: var(--grey-800);
  --chip-tag-hover-background: inherit;
  // rounded
  --chip-rounded-font-size: var(--font-size-12);
  --chip-rounded-font-weight: 600;
  --chip-rounded-border: 1px solid var(--primary-500);
  --chip-rounded-border-radius: var(--size-20);
  --chip-rounded-padding: 0.35rem 1rem;
  --chip-rounded-color: var(--grey-500);
  // rounded hover
  --chip-rounded-hover-color: var(--white);
  --chip-rounded-hover-background: var(--grey-800);
}

.chip {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--chip-tag-background);

  &--tag {
    font-weight: var(--chip-tag-font-weight);
    font-size: var(--chip-tag-font-size);
    border-radius: var(--chip-tag-border-radius);
    padding: var(--chip-tag-padding);
    border: var(--chip-tag-border);
    color: var(--chip-tag-color);
  }

  &--rounded {
    font-weight: var(--chip-rounded-font-weight);
    font-size: var(--chip-rounded-font-size);
    border-radius: var(--chip-rounded-border-radius);
    border: var(--chip-rounded-border);
    padding: var(--chip-rounded-padding);
    color: var(--chip-rounded-color);
  }

  &--clickable {
    cursor: pointer;
  }

  &--clickable-rounded {
    &:hover {
      color: var(--chip-rounded-hover-color);
      background: var(--chip-rounded-hover-background);
    }
  }

  &--clickable-tag {
    &:hover {
      color: var(--chip-tag-hover-color);
      background: var(--chip-tag-hover-background);
    }
  }

  &__category {
    &--new {
      background-color: var(--switch-lock-error-colour);
      color: var(--white);
      border: none;
    }
  }
}
