// Phone
// 0 to 599px
$phone-viewport: 37.5em;
$phone-column-gutter: var(--size-8);

// Tab Portrait
// 600px - 959px
$tab-port-row-gutter: var(--size-24);
$tab-port-column-gutter: var(--size-12);

// Tab Landscape
// 960px -1024px
$tab-land-viewport: 48em;
$tab-land-column-gutter: var(--size-16);

// Desktop
// 1025px - 1919px
$desktop-viewport: 62em;
$desktop-column-gutter: var(--size-16);

// Larger Desktop
// 1920px >
$big-desktop-viewport: 75em;
$extra-big-desktop-viewport: 120em;
$extra-big-desktop-column-gutter: var(--size-16);

/* maximum amount of grid cells to be provided */
$viewport-offset: 0.0625em;
$max_col: 12;

@mixin generate-grid($device, $col_num, $column_gap) {
  $offset_col_num: $col_num - 1;
  @if ($device == default) {
    .content-wrapper {
      display: grid;
      width: 100%;
      box-sizing: border-box;
      grid-template-columns: repeat(1, 1fr);
      column-gap: $column_gap;

      > * {
        box-sizing: border-box;
      }

      /* Handle column numbers */
      @for $i from 1 through $col_num {
        &--#{$device}--#{$i} {
          grid-template-columns: repeat($i, 1fr);
        }
      }

      /* Handle column numbers */
      @for $i from 1 through $col_num {
        .content-column {
          grid-column: span $i;
        }
      }
    }
  } @else {
    .content-wrapper {
      column-gap: $column_gap;

      /* Handle device column numbers */
      @for $i from 1 through $col_num {
        &--#{$device}--#{$i} {
          grid-template-columns: repeat($i, 1fr);
        }
      }

      /* Handle column numbers */
      @for $i from 1 through $col_num {
        .content-column {
          grid-column: span $i;
        }
      }
    }
  }
}

/* default breakpoint (desktop) */
@include generate-grid(default, $max_col, $desktop-column-gutter);

/* Extra small devices (phones and down) */
@media only screen and (max-width: $phone-viewport) {
  @include generate-grid(phone, $max_col, $phone-column-gutter);
}

/* Small devices (portrait tablets and large phones and up) */
@media only screen and (min-width: $phone-viewport) and (max-width: $tab-land-viewport) {
  @include generate-grid(tablet, $max_col, $tab-port-column-gutter);
}

/* Medium devices (landscape tablets and up) */
@media only screen and (min-width: $tab-land-viewport) and (max-width: $desktop-viewport) {
  @include generate-grid(tablet, $max_col, $tab-land-column-gutter);
}
