.auto-withdrawal-card {
  width: 100%;

  &__info {
    margin: 0 0 0 1.65rem;
  }

  &__edit-button {
    margin-left: 0.5em;

    svg {
      color: var(--body-40);
    }
  }
}

.auto-withdrawal-card__toggle {
  display: flex;

  .checkbox__container {
    display: flex;
    margin-bottom: var(--paragraph-margin);
  }
  .checkbox__inline-icon {
    order: 1;
    margin: 0;
  }
  .checkbox__label {
    order: 2;
    flex-grow: 1;
    margin-left: 0;
    font-weight: var(--paragraph-highlight-font-weight);
    color: var(--body-80);
  }
  .switch {
    order: 3;
  }
}
