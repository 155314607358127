@import '../../../scss/global/utils/mixins';

.welcome-bonus-banner {
  aspect-ratio: 430 / 320;
  border-radius: var(--size-10);
  max-width: 350px;
  width: 100%;
  background: url(../../../assets/Banners/Betzone-430x320@1x.jpg) no-repeat center / contain;
  &__title {
    font-size: var(--h4-font-size);
    line-height: var(--h4-line-height);
  }

  &__subtitle {
    line-height: var(--h2-line-height);
    color: #f4f4de;
    font-weight: var(--font-weight-semi-bold);
    padding: var(--size-6) 0;
  }

  &--mobile {
    aspect-ratio: 720 / 260;
    margin: 0 var(--size-16) var(--size-9);
    border-radius: var(--size-10);
    background: url(../../../assets/Banners/Betzone-720x260@1x.jpg) no-repeat center / cover;


    @include respond(tab-port) {
      background-position: top;
      background-size: cover;
      padding: var(--size-32) var(--size-16);
    }
  }
}
