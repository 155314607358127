@import '../../../../scss/global/utils/mixins';

:root {
  --account-limits-pending-info-color: var(--info);
}

.account-limits {
  &:not(:first-child) {
    margin-top: var(--size-24);
  }

  @include respond(phone) {
    &:first-child {
      .account-limits {
        &__heading-container {
          & + .paragraph {
            .button {
              padding: 0;
            }
          }
        }
      }
    }
  }

  &__heading-container {
    display: flex;

    .button,
    .heading {
      color: var(--white);
    }

    .button {
      &:hover {
        color: var(--body-20);
      }
    }

    & + .paragraph {
      margin-bottom: var(--size-16);
      color: var(--white);

      .button {
        color: var(--white);
        padding: 0 var(--size-5);

        span {
          font-size: var(--size-10);
          line-height: var(--size-10);
          font-weight: var(--paragraph-font-weight);
          text-decoration: underline;
          margin-bottom: var(--size-2);
        }
      }
    }

    .heading {
      flex: 1;
      margin-bottom: var(--size-12);
    }
  }

  &-remove-modal__limit-details {
    display: flex;
    justify-content: space-between;
    padding: var(--size-20) var(--size-16);
    border-radius: var(--size-8);
    margin: var(--size-24) 0 var(--size-20);
    font-size: var(--size-14);
  }

  &-remove-modal__limit-value {
    font-weight: var(--text-highlight-weight);

    svg {
      margin-left: var(--size-10);
      cursor: pointer;
    }
  }

  .account-tile-list-item__content {
    justify-content: space-between;

    .account-tile-list-item__icon {
      margin-right: var(--size-4);
    }

    .paragraph {
      color: var(--body-80);
      font-size: var(--font-size-14);
      font-family: var(--body-font-family);
    }
  }

  .touch-drawer__footer {
    display: flex;
    justify-content: flex-end;

    .button:not(:last-child) {
      margin-right: var(--size-16);
    }
  }

  &-footer {
    .button.button--secondary {
      border-color: var(--white);
    }
  }

  &__sub-heading {
    margin-top: var(--size-32);
    margin-bottom: var(--size-16);
  }

  &__spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      min-width: var(--size-24);
      min-height: var(--size-24);
    }
  }
}
