.bonus-bar {
    &__background {
      fill: var(--body-5);
  
    }
  
    &__amount {
      fill: var(--success);
    }
}
