@import '../../../../../scss/global/utils/mixins';

.cookies-preferences-portal {
  &__account {
    &--not-full-height {
      bottom: var(--size-96);
      padding-top: var(--size-96);
    }
  }

  &__cookie-content {
    padding: 0 var(--size-16);

    .cookie-preference-control {
      &__categories {
        border-radius: 1rem 1rem 0 0;
        margin: 0 calc(-1 * var(--size-16));
        padding: 0 var(--size-16) var(--size-16);
      }
    }
  }

  &__prompt {
    > div:first-of-type {
      margin-bottom: 0;
    }

    .cookie-preference-control {
      &__categories {
        border-radius: 1rem;
        margin: 0 calc(-1 * var(--size-24));
        padding: 0 var(--size-24) var(--size-16);
      }
    }

    .cookie-details {
      margin-top: 0;
    }
  }
}
