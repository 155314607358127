@import '../../../scss/global/utils/mixins';

:root {
  // game-card
  --game-card-border-radius: var(--size-10);
  // info
  --game-card-info-transition: opacity 0.2s;
}

.game-card {
  position: relative;
  display: block;
  width: 100%;
  transition: var(--game-card-transition-duration);
  z-index: var(--game-card-z-index);

  &-content {
    &__info {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: var(--game-card-info-z-index);
      border-radius: var(--game-card-border-radius);
      transition: var(--game-card-info-transition);
    }
  }

  &-content,
  &-content__inner {
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: var(--game-card-border-radius);
  }

  &-zoomed,
  &-popup {
    .game-card {
      &-basic__title,
      &-basic__gradient {
        display: flex;
      }
    }
  }

  &--wiggle {
    animation: wiggle 0.7s;
  }
  @keyframes wiggle {
    0% {
      transform: translate(5px, 0);
    }
    50% {
      transform: translate(-5px, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }

  &-excluded-drawer {
    .prompt {
      &__content {
        text-align: left;

        .heading {
          margin-bottom: var(--size-24);
        }
      }
    }
  }
}

.game-card-popup {
  &--loading {
    pointer-events: none;
    touch-action: none;
  }

  .touch-drawer {
    &__touchpad {
      position: absolute;
      z-index: 1;
      opacity: 0.6;
    }

    &__content {
      border-top-right-radius: var(--size-10);
      border-top-left-radius: var(--size-10);
    }

    &__content--bottom {
      max-height: none;
    }

    &__bars-wrapper {
      background-color: transparent;
    }

    &__footer {
      div {
        .button {
          &--secondary {
            border: var(--btn-border-width) solid var(--energy-blue-700);
          }
        }
      }
    }
  }

  .touch-drawer__content,
  .modal-body__content {
    padding: 0;
  }


  .touch-drawer__bars {
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.4);
    background: white;
  }

  &.modal:not(.modal--with-footer) .modal__container {
    padding: 0;
  }
}

.zoomed-game-card {
  box-shadow: 0 0 32px 8px #0000001a;
}
