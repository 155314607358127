:root {
  --form-error-alert-border: none;
  --form-error-alert-padding: none;
  --form-error-alert-icon-size: var(--font-size-30);
  --form-error-alert-font-weight: 700;
  --form-error-alert-list-item-padding: var(--size-12) 0;
  --form-error-alert-content-border-top: 2px solid var(--primary-800);
  --form-error-alert-content-padding: var(--size-16) 0;
  --form-error-alert-header-min-height: var(--size-128);
  --form-error-alert-header-padding: var(--size-16) 0;
}

.form-error-alert {
  color: var(--danger-color);
  font-weight: var(--form-error-alert-font-weight);
  border: var(--form-error-alert-border);
  padding: var(--form-error-alert-padding);

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    min-height: var(--form-error-alert-header-min-height);
    padding: var(--form-error-alert-header-padding);
  }

  &__icon {
    font-size: var(--form-error-alert-icon-size);
  }

  &__content {
    padding: var(--form-error-alert-content-padding);
  }

  &__header + .form-error-alert__content {
    border-top: var(--form-error-alert-content-border-top);
  }

  &__list-item {
    padding: var(--form-error-alert-list-item-padding);
  }
}
