@import '../../../../scss/global/utils/mixins';

.unauthenticated-header-min-height {
  height: var(--header-mobile-height);

  @include respond(tab-port) {
    height: var(--header-height);
  }
}

.unauthenticated-header {
  @include respond(phone) {
    &__login-btn,
    &__register-btn {
      &.button.button.button--small {
        line-height: normal;
      }
    }
  }
  .button {
    height: var(--size-40);
  }

  .hamburger-logo {
    @include respond(tab-port) {
      margin-right: var(--size-24);
    }

    @include respond(desktop) {
      margin-right: var(--size-64);
    }
  }

  .search-bar {
    caret-color: auto;
  }

  &__login-btn {
    margin-right: var(--size-8);

    span {
      white-space: normal;
    }

    @include respond(tab-port) {
      margin-right: var(--size-16);
    }

    @include respond(tab-land) {
      margin-left: var(--size-32);
    }
  }

  &__register-btn {
    margin-right: var(--size-4);

    span {
      white-space: normal;
      color: var(--black);
    }

    @include respond(tab-port) {
      margin-right: 0;
    }
  }
}
