.questionnaires-container {
  .select-non-native__ul {
    position: static;
    display: block;
    height: auto;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  color: var(--body-10);

  .heading {
    color: var(--body-10);
  }

  .form {
    width: auto;
    position: relative;
  }

  .question-text {
    padding-top: var(--size-4);
    padding-bottom: var(--size-4);
    color: var(--black);
  }

  .accordion {
    box-shadow: none;
    border-radius: var(--size-8);
    position: relative;

    &__collapse-container {
      padding: 0;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-content {
      padding: 0;
      position: relative;
      overflow-y: scroll;
    }

    p {
      color: black;
      padding-bottom: 8px;
    }

    &-header {
      border-radius: var(--size-8);
    }

    span {
      color: var(--black);
    }
  }

  .select-non-native {
    &__container {
      position: relative;
    }

    &__dropdown {
      position: absolute;
      width: 100%;
      height: auto;
      display: block;

      li {
        position: static;
        display: block;
      }
    }
  }

  .select-non-native__input {
    &--has-value {
      color: var(--black);
    }
    .input {
      color: var(--select-placeholder-color);
      &--has-value {
        color: var(--black);
      }
    }
  }
}

.questionnaires-modal {
  max-width: 600px;
  .modal-body--overflowing {
    &::after {
      display: none !important;
    }
  }
  .modal__container {
    padding-bottom: 0 !important;
  }
  .modal-body__content {
    min-height: 120px;
    padding-bottom: 28px;
    border-radius: var(--size-8);
    overflow-y: scroll;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
