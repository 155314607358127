@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;700&display=swap');

:root {
  // Default font options, e.g. Article Text
  --body-font-family: arial, 'sans-serif';
  --body-text-color: var(--grey-800);
  --body-font-size: var(--font-size-16);
  --body-line-height: 1.5; // Warning: changing this may affect spacing/sizing of elements which inherit line-height
  --text-highlight-weight-tabular: 500;
  --text-highlight-weight: 700;
  // e.g. Headline
  --primary-content-color: var(--primary-800);
  // e.g. Article date
  --secondary-content-color: var(--grey-500);
  // e.g. Copyright notice
  --tertiary-content-color: var(--grey-100);

  // Heading styles. Can be applied by using the Heading component or the headingStyles mixin
  --heading-font-family: arial, 'sans-serif';
  --heading-font-weight: 700;
  --heading-color: var(--primary-content-color);
  --heading-text-transform: none;
  --heading-letter-spacing: 0;
  --heading-line-height: 1.5;
  --heading-margin: 0.3em 0;
  --h1-font-size: var(--font-size-48);
  --h2-font-size: var(--font-size-36);
  --h3-font-size: var(--font-size-30);
  --h4-font-size: var(--font-size-24);
  --h5-font-size: var(--font-size-20);
  --h6-font-size: var(--font-size-16);
  --h1-letter-spacing: var(--heading-letter-spacing);
  --h2-letter-spacing: var(--heading-letter-spacing);
  --h3-letter-spacing: var(--heading-letter-spacing);
  --h4-letter-spacing: var(--heading-letter-spacing);
  --h5-letter-spacing: var(--heading-letter-spacing);
  --h6-letter-spacing: var(--heading-letter-spacing);
  --h1-line-height: var(--heading-line-height);
  --h2-line-height: var(--heading-line-height);
  --h3-line-height: var(--heading-line-height);
  --h4-line-height: var(--heading-line-height);
  --h5-line-height: var(--heading-line-height);
  --h6-line-height: var(--heading-line-height);

  // Typography variables used in Typography component but can be used directly if needed.
  --typography-color: var(--body-text-color);
  --typography-line-height: var(--body-line-height);
  --typography-highlight-font-weight: 600;
  --typography-xl-font-size: var(--font-size-24);
  --typography-l-font-size: var(--font-size-20);
  --typography-m-font-size: var(--font-size-16);
  --typography-s-font-size: var(--font-size-14);
  --typography-xs-font-size: var(--font-size-12);
  --typography-xxs-font-size: var(--font-size-10);
  --typography-xl-line-height: var(--typography-line-height);
  --typography-l-line-height: var(--typography-line-height);
  --typography-m-line-height: var(--typography-line-height);
  --typography-s-line-height: var(--typography-line-height);
  --typography-xs-line-height: var(--typography-line-height);
  --typography-xxs-line-height: var(--typography-line-height);

  // Paragraph styles. Can be applied by using the Paragraph component or the paragraphStyles mixin
  // TODO: Remove paragraph specific sizing when consumer repos are updated
  --paragraph-font-weight: 500;
  --paragraph-highlight-font-weight: var(--typography-highlight-font-weight);
  --paragraph-text-transform: none;
  --paragraph-color: var(--body-text-color);
  --paragraph-margin: 0 0 var(--size-16);
  --paragraph-xl-font-size: var(--typography-xl-font-size);
  --paragraph-l-font-size: var(--typography-l-font-size);
  --paragraph-m-font-size: var(--typography-m-font-size);
  --paragraph-s-font-size: var(--typography-s-font-size);
  --paragraph-xs-font-size: var(--typography-xs-font-size);
  --paragraph-xxs-font-size: var(--typography-xxs-font-size);
  --paragraph-xl-line-height: var(--typography-xl-line-height);
  --paragraph-l-line-height: var(--typography-l-line-height);
  --paragraph-m-line-height: var(--typography-m-line-height);
  --paragraph-s-line-height: var(--typography-s-line-height);
  --paragraph-xs-line-height: var(--typography-xs-line-height);
  --paragraph-xxs-line-height: var(--typography-xxs-line-height);

  // URL link colours. Placed here so they can be used for Text Buttons as well.
  --link-color: #2b85ec;
  --link-hover-color: #2b85ec;
  --link-active-color: #0e54a4;
}

body {
  font-family: var(--body-font-family);
  color: var(--body-text-color);
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
}

strong,
b {
  font-weight: var(--text-highlight-weight);
}
