@import '../../../../../scss/global/utils/mixins';

.drawer-cookie-preference{
 .touch-drawer__content{
  padding-bottom: 0;
 } 

 .modal-body-content{
  padding-bottom: 0;
 }
}