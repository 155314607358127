.content-prompt {
  color: var(--body-10);
  h1, h2, h3, h4, h5, h6, p {
    min-height: 5px;
    margin-bottom: 10px;
  }
  li {
    margin-left: 2rem;
    list-style: circle;
  }
  a {
    font-weight: bold;
    color: var(--body-10);
    text-decoration: underline;
  }
  .modal {
    height: calc(100vh - 5rem);

    .modal__container {
      height: 100%;
    }

    .modal-body {
      &,
      &__content {
        height: 100%;
      }

      p,
      ul,
      ol {
        margin-bottom: var(--size-10);
      }
    }
  }

  .touch-drawer {
    .touch-drawer__content {
      p,
      ul,
      ol {
        margin-bottom: var(--size-16);
      }
    }
  }

  .spinner {
    height: 3rem;
    width: 3rem;
    position: relative;
    display: block;
    margin: auto;
    top: calc(50% - 3rem / 2);
  }
}
