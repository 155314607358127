.account-wallet-text-content {
  overflow: hidden;

  &__balance-type {
    color: var(--body-60);
    margin-top: var(--size-8);
    margin-bottom: var(--size-2);
  }

  &__balance {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
