:root {
  --select-chevron-color: var(--accent-900);
  --z-index-select-chevron: 0;
  --z-index-select-input: 1;
  --z-index-select-options: 2;
  --z-index-carousel: 1;
  --z-index-carousel-active: 2;
  --z-index-carousel-button: 3;
  --z-index-progress-bar: 1;
  --z-index-radio-input: 1;
  --z-index-checkbox-input: 1;
  --z-index-switch-lock: 1;
  --z-index-input-box: 1; // Places below the input box so clicking will still focus input
  --z-index-country-code: 2; // Places above inputs so it can appear as part of the input
  --z-index-input-feedback-icon: 1;
  --z-index-game-overlay: 1;
  --z-index-games-list-pagination: 1; // Places pagination buttons above games in overlay mode
  --z-index-sticky-node: 1;
  --z-index-checkbox-label: 1;
  --z-index-input-icon: 1;
  --z-index-input-floating-label: 2; // Allows label to be above input's border
  --z-index-fixed-action-panel: 3;
  --z-index-input-icon-button: 2; // Places above input box so it's clickable
  --z-index-datepicker-popper: 2; // Prevent picker displaying below input
  --z-index-autocomplete-menu-open: 3;
  --z-index-popup-menu-closed: -1;
  --z-index-popup-menu-open: 3;
  --z-index-custom-select-dropdown: 2; // Places dropdown above error messages within form
  --z-index-overlay-content: 10000000;
  --z-index-overlay: calc(
    var(--z-index-overlay-content) + 1
  ); // Sportsbook elements have z-index 999999 and header has 1000000, we should put the higher number than both of them
  --z-index-tooltip: var(--z-index-overlay);
  --z-index-touch-drawer: calc(var(--z-index-overlay) + 1); // The biggest z-index to show drawer on mobile devices
  --z-index-snackbar: var(--z-index-touch-drawer);
  --z-index-over-all: calc(var(--z-index-touch-drawer) + 1);
  --z-index-over-all-second: calc(var(--z-index-over-all) + 1);
  --z-index-over-all-third: calc(
    var(--z-index-over-all-second) + 1
  ); // Above all content to ensure feedback is displayed
  --z-index-game-video-tile: 2;
}
