@import '../../../../scss/global/utils/mixins';

.header .search-bar-search-icon {
  display: none;

  @include respond(tab-land) {
    display: block;
    height: var(--size-24);
    margin-bottom: 0;
    padding: 0;
    flex: initial;

    svg {
      fill: var(--white);
      display: block;
    }

    &:hover {
      background-color: transparent;
    }
  }
}

.navigation {
  @include respond(tab-land) {
    .search-bar-search-icon {
      display: none;
    }
  }
}

.search-bar-container {
  min-width: var(--size-320);
  width: 100%;
}

.search-bar {
  display: inline-flex;
  width: 100%;
  left: 0;

  &--dark {
    svg {
      fill: var(--white);
    }
  }

  &--light {
    svg {
      fill: var(--dark-blue-25);
    }
  }

  &__input {
    width: 98%;

    @include respond(tab-port) {
      margin: 0;
    }

    .spinner {
      position: absolute;
      top: var(--size-12);
      width: var(--size-24);
      height: var(--size-24);
    }

    svg {
      transform: rotateY(180deg);
    }
  }

  @include respond(desktop) {
    background-color: inherit;
    position: initial;
    height: 100%;
    margin: 0;

    &__input {
      margin: 0;
    }
  }

  &__close-button {
    margin-left: var(--size-8);
    text-decoration: none;
    span {
      color: var(--white);
    }
  }

  @include respond(phone) {
    margin-top: var(--size-4);
    margin-bottom: var(--size-8);
  }
}

.game-search {
  &__result {
    margin-top: var(--size-16);

    @include respond(tab-port) {
      display: flex;
      flex-flow: row wrap;
    }

    @include respond(phone) {
      display: flex;
      flex-flow: row wrap;
    }
  }

  &__card {
    margin-bottom: var(--size-16);

    @include respond(tab-port) {
      width: 31%;
      margin-right: 2%;
    }

    @include respond(phone) {
      margin-bottom: 0;
      flex-basis: calc(100% / 2);
      padding: var(--size-4);
    }
  }
}
.search-results {
  &__games-list {
    width: 100%;
  }
  &__card-wrap {
    flex-basis: calc(100% / 6);
    @include respond(phone) {
      flex-basis: calc(100% / 2);
    }
  }

  &__overlay {
    &--secondary-search {
      top: var(--size-160);

      @include respond(phone) {
        top: 0;
        background-color: transparent;
      }

      &--mobile-landscape {
        position: relative;
        top: 0;
        min-height: 35vh;
        overflow-y: auto;
        margin-bottom: var(--size-16);
      }
    }
  }

  @include respond(phone) {
    padding-bottom: var(--size-60);

    &--pb-mobile-ios {
      padding-bottom: var(--size-112);
    }
  }

  @include respond(phone) {
    background-color: var(--white);
    padding: var(--size-14);
    margin-bottom: var(--size-14);
    height: 60vh;
    overflow-y: scroll;
  }
}
