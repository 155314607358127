@import '../../../../scss/global/utils/mixins';

.navigation-logout-link {
  cursor: pointer;
  color: var(--dark-blue-25);

  &:hover {
    background-color: var(--dark-blue-50);
    color: var(--white);
  }

  @include respond(tab-land) {
    border-radius: var(--border-radius-right-rounded);
  }

  &__expanded {
    padding: var(--size-10) var(--size-32);

    &-tile {
      font-size: var(--size-12);
      margin-bottom: var(--size-2);
    }

    &-icon {
      width: var(--size-12);
      height: var(--size-12);
      margin-right: var(--size-4);
      color: var(--dark-blue-25);
    }

    &-login-date {
      display: block;
      font-size: var(--size-10);
      line-height: var(--size-14);
    }
  }

  &__collapsed {
    display: flex;
    align-items: center;
    flex-direction: column;

    @include respond(tab-land) {
      padding: var(--size-16);
    }

    &-icon {
      width: var(--size-24);
      height: var(--size-24);
      color: var(--dark-blue-25);
    }

    &-paragraph {
      font-size: var(--font-size-10);
      line-height: var(--font-size-14);
      margin-top: var(--size-2);
      font-weight: var(--typography-highlight-font-weight);

      @include respond(tab-land) {
        margin-top: var(--size-4);
      }
    }
  }
}
