:root {
  // Primary-selected
  --btn-primary-selected-bg-color: var(--primary-900);
  --btn-primary-selected-border-color: var(--primary-900);
  --btn-primary-selected-color: #fff;
  // Primary Alt-selected
  --btn-primary-alt-selected-bg-color: var(--accent-200);
  --btn-primary-alt-selected-border-color: var(--accent-200);
  --btn-primary-alt-selected-color: var(--grey-900);
  // Secondary-selected
  --btn-secondary-selected-bg-color: var(--primary-900);
  --btn-secondary-selected-border-color: var(--primary-900);
  --btn-secondary-selected-color: #fff;
  // Secondary Alt-selected
  --btn-secondary-alt-selected-bg-color: var(--white);
  --btn-secondary-alt-selected-border-color: var(--white);
  --btn-secondary-alt-selected-color: var(--grey-900);
}

.toggle-button {
  &--selected {
    @each $variant in primary, primary-alt, secondary, secondary-alt {
      &.button--#{$variant} {
        background-color: var(--btn-#{$variant}-selected-bg-color);
        color: var(--btn-#{$variant}-selected-color);
        border-color: var(--btn-#{$variant}-selected-border-color);
      }
    }
    &.button--text {
      text-decoration: underline;
    }
  }
}
