@import '../../../scss/global/utils/mixins';

:root {
  // > Mobile (Tab port)
  --navigation-tab-port-padding-top: var(--size-24);
  --navigation-tab-port-padding-left: var(--size-24);
  --navigation-tab-port-padding-right: var(--size-24);
  --navigation-tab-port-height: calc(100vh - var(--header-height) - var(--navigation-tab-port-padding-top));
  --navigation-tab-land-width: auto;
  --navigation-tab-port-height: 4rem;
  --navigation-sidebar-expanded-width: var(--size-256);

  // Mobile
  --navigation-mobile-padding-top: var(--size-16);
  --navigation-mobile-height: 3.5rem;
  --navigation-mobile-width: 100%;
  --navigation-mobile-padding-left: var(--size-16);
  --navigation-mobile-padding-right: var(--size-16);

  // Divider
  --navigation-divider-height: var(--navigation-mobile-height);

  // Subnav
  --navigation-subnav-min-height: var(--size-64);
  --navigation-subnav-min-height-tab-port: var(--size-88);
}

.navigation {
  background-color: var(--primary-900);
  z-index: var(--navigation-z-index);
  position: relative;
  display: block;
  width: var(--navigation-tab-land-width);
  min-width: var(--navigation-tab-land-width);

  &--with-padding {
    padding-bottom: var(--size-12);
    padding-top: var(--size-12);
    padding-right: var(--size-16);
  }

  &__content-container {
    overflow: hidden;
    background-color: var(--primary-900);

    // Do not apply "hidden" value to Sportsbook
    &:has(#mg-sportsbook) {
      overflow: visible;
      width: 100%
    }
  }

  &__subnav-search-bar {
    z-index: 6;
    padding-left: var(--size-12);
    transition:
      visibility 0.5s ease,
      opacity 0.5s;

    @include respond(phone) {
      flex-shrink: 0;
      width: 100%;
      padding-right: var(--size-12);
    }

    .input__box-container {
      transition: background-color 0.75s ease;
    }

    &--hidden {
      visibility: hidden;
      opacity: 0;

      .input__box-container {
        background-color: var(--primary-900);
        color: var(--primary-900);
      }
    }
  }

  &__subnav-search {
    margin-left: var(--size-12);
  }

  &__children {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: var(--primary-900);

    &--dark {
      background-color: var(--primary-900);
    }

    &--with-padding {
      padding-bottom: var(--navigation-mobile-height);
      padding-left: var(--navigation-mobile-padding-left);
      padding-right: var(--navigation-tab-port-padding-right);

      @include respond(tab-port) {
        margin: 0;
        padding-left: var(--navigation-tab-port-padding-left);
      }

      @include respond(tab-land) {
        padding-right: var(--navigation-tab-port-padding-right);
      }

      @include respond(phone) {
        padding-right: 0;
      }
    }
  }

  &__category-title {
    &:not(.navigation__category-title--expanded) {
      display: none;
    }
  }

  &__category-title--expanded {
    padding: var(--size-16) 0 var(--size-16) var(--size-32);
    font-weight: var(--paragraph-font-weight);
    color: var(--white);
    margin: 0;
  }

  .navigation-link {
    @include respond(tab-land) {
      color: var(--body-5);

      &--selected {
        .navigation-link {
          &__text {
            color: var(--white);
          }

          &__icon svg {
            fill: var(--white);
          }
        }

        background-color: var(--body-80);
      }

      &__icon {
        svg {
          fill: var(--soft-brown);
        }
      }

      &:hover {
        background-color: var(--body-80);
      }
    }

    .notification-limit-reached {
      width: var(--size-16);
      display: flex;
      justify-content: center;
    }

    &-top-wrapper {
      &--expanded {
        .navigation-link {
          .navigation-link__wrapper {
            p.navigation-link__text {
              margin: var(--size-4) 0 0;
              text-align: center;
            }
          }
        }
      }
    }
  }

  .navigation-link--expanded + &__divider {
    @include navigation-divider;
  }

  &__navigation_links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-scrollbar {
      height: 90%;
      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: var(--size-4);
      }

      &::-webkit-scrollbar-thumb {
        background: var(--grey-600);
      }

      &::-webkit-scrollbar-track {
        background: var(--grey-400);
      }
    }
    &--authenticated {
      height: calc(100vh - var(--size-80) - var(--size-20));
      @media screen and (min-color-index: 0) and (-webkit-min-device-pixel-ratio: 0) {
        @media screen and (phone) {
          height: calc(85vh - var(--size-24));
        }
      }
    }

    &--notification {
      height: calc(100vh - var(--size-80) - var(--size-40) - var(--size-20));

      @include respond(phone) {
        height: calc(100vh - var(--size-80) - var(--size-20));
      }
    }

    @include respond(tab-land) {
      position: sticky;
      top: calc(var(--header-height) + var(--size-16));
    }

    &--ios {
      height: calc(var(--viewport-height) - var(--size-96));
    }
  }

  @include respond(tab-port) {
    height: 100%;
    padding-bottom: var(--size-16);
  }

  @include respond(tab-land) {
    width: var(--navigation-tab-land-width);
    min-width: var(--navigation-tab-land-width);
    position: relative;
    display: block;
    padding-right: var(--size-16);
    padding-bottom: 0;
    padding-top: var(--size-16);
  }

  &__subnav-wrapper {
    display: flex;
    align-items: center;
    background-color: var(--primary-900);
    transition: background-color 0.75s ease;
    border-bottom: 1px solid var(--primary-800);

    &--hidden {
      background-color: var(--primary-900);
      border-color: var(--primary-900);
    }
  }

  &__subnav {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;
    gap: var(--category-navigation-gap);
    padding: 0 var(--size-12);
    transition:
      visibility 0.5s ease,
      opacity 0.5s;
    left: 0;

    &--hidden {
      visibility: hidden;
      opacity: 0;
    }

    .search-bar {
      width: 100%;
      margin-bottom: 0;
    }

    &-search {
      padding: var(--size-8);
      width: var(--size-32);
      height: var(--size-32);
      border: 1px solid var(--dark-blue-25);
      border-radius: 50%;

      svg {
        position: relative;
        bottom: var(--size-3);
        right: var(--size-2);
        transform: rotateY(180deg);
      }

      &.navigation-link {
        flex: none;
        border-radius: 50%;
        justify-content: center;
        flex-direction: column;
      }
    }
  }

  &__pills {
    @include respond(desktop) {
      background-color: var(--primary-900);
      margin-bottom: calc(-1 * var(--size-16));
    }
    background-color: var(--primary-800);
    padding-left: var(--size-24);

    @include respond(phone) {
      padding-left: var(--size-16);
    }
  }
}

// To prevent navigation bar endless growing
html {
  .navigation {
    // use higher value than Metric's bottom bar
    z-index: 9999999;

    &__content-container {
      min-width: 0;

      @include respond(tab-land) {
        width: 100%;
      }

      &--expanded {
        width: calc(100% - var(--navigation-sidebar-expanded-width));

        .mg-feature-card-img {
          min-width: unset;
          object-fit: contain;
          max-width: calc(105% - var(--navigation-sidebar-expanded-width));
        }

        @include respond(desktop) {
          .mg-feature-card-img {
            max-width: 100%;
            min-width: 100%;
            object-fit: unset;
          }
        }
      }
    }

    &__children {
      width: 100%;
    }

    @include respond(tab-port) {
      min-height: calc(100vh - var(--navigation-tab-port-height) - var(--header-height));
      height: auto;

      &__children,
      &__content-container {
        min-height: calc(100vh - var(--navigation-tab-port-height) - var(--header-height));
        height: auto;
      }
    }
  }
}

.navigation-container {
  display: flex;

  .navigation__children {
    &--with-padding {
      padding-top: var(--navigation-mobile-padding-top);

      @include respond(phone) {
        > div {
          &:last-child {
            padding-right: var(--navigation-mobile-padding-right);
          }
        }
      }
    }

    .spinner {
      @include align-by-center;
      width: var(--size-48);
      height: var(--size-48);
    }
  }

  @include respond(tab-port) {
    .navigation__children {
      &--with-padding {
        padding-top: var(--navigation-tab-port-padding-top);
      }
    }
  }
}
