$offset: 187;
$duration: 1.4s;

//Colors
$White: var(--dark-blue-25);
$Black: var(--dark-blue-100);
$Light01: var(--blue-75);
$Light03: var(--blue-25);

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

@keyframes lightColors {
  0% {
    stroke: $Black;
  }
  25% {
    stroke: $Light01;
  }
  50% {
    stroke: $Light03;
  }
  75% {
    stroke: $Light01;
  }
  100% {
    stroke: $Black;
  }
}
@keyframes darkColors {
  0% {
    stroke: $White;
  }
  25% {
    stroke: $Light01;
  }
  50% {
    stroke: $Light03;
  }
  75% {
    stroke: $Light01;
  }
  100% {
    stroke: $White;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: calc(#{$offset} / 4);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}

.spinner {
  animation: rotator $duration linear infinite;

  .spinner__path {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash $duration ease-in-out infinite,
      lightColors ($duration * 4) ease-in-out infinite;
    &--dark {
      animation: dash $duration ease-in-out infinite,
        darkColors ($duration * 4) ease-in-out infinite;
    }
  }
}
