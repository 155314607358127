@import "../../../../../scss/global/utils/mixins";

:root {
  // zindex
  --carousel-slide-zindex-inactive: var(--z-index-carousel);
  --carousel-slide-zindex-active: var(--z-index-carousel-active);
  --carousel-close-zindex-btn: var(--z-index-carousel-button);
  // slide
  --carousel-slide-transform: 0;
  --carousel-slide-background: var(--white);
  --carousel-slide-scale-factor: 1.25;
  --carousel-slide-shift-factor: 9%;
  --carousel-slide-gap: var(--size-10);
  --carousel-slide-disabled-opacity: 0.5;
  // slide zoom
  --carousel-slide-zoom-delay: 0.5s;
  --carousel-slide-zoom-transition: 100ms;
  // buttons
  --carousel-button-padding: 0 var(--size-16);
  --carousel-button-size: var(--size-40);
  --carousel-button-background: var(--white);
  --carousel-button-color-inactive: var(--grey-300);
  --carousel-button-color-active: var(--primary-500);
  --carousel-button-shadows: 3px 1px 7px rgb(0 0 0 / 10%);
  --carousel-button-margin: var(--size-30);
  // dot group
  --carousel-dotgroup-padding: var(--size-10) 0;
  // dots
  --carousel-dots-height: var(--size-2);
  --carousel-dots-width: var(--size-24);
  --carousel-dots-color: var(--white);
  --carousel-dots-color-selected: var(--grey-300);
  --carousel-dots-margin: var(--size-2);
  // close btn
  --carousel-slide-close-btn-top: var(--size-10);
  --carousel-slide-close-btn-right: var(--size-10);
  --carousel-slide-close-btn-color: var(--grey-300);
  // defaults set here, but will be changed in JS to achieve offset
  --carousel-slider-tray-width: 0;
  --carousel-slider-tray-left: 0;
  --carousel-slide-alignment: center;
}

@keyframes CarouselZoomIn {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(var(--carousel-slide-scale-factor));
  }
}

@keyframes CarouselZoomOut {
  from {
    transform: scale(var(--carousel-slide-scale-factor));
  }
  to {
    transform: scale(1) translateX(0);
  }
}

@keyframes CarouselZoomInLeft {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(var(--carousel-slide-scale-factor))
      translateX(calc(var(--carousel-slide-shift-factor)));
  }
}

@keyframes CarouselZoomOutLeft {
  from {
    transform: scale(var(--carousel-slide-scale-factor))
      translateX(calc(var(--carousel-slide-shift-factor)));
  }
  to {
    transform: scale(1) translateX(0);
  }
}

@keyframes CarouselZoomInRight {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(var(--carousel-slide-scale-factor))
      translateX(calc(-1 * var(--carousel-slide-shift-factor)));
  }
}
@keyframes CarouselZoomOutRight {
  from {
    transform: scale(var(--carousel-slide-scale-factor))
      translateX(calc(-1 * var(--carousel-slide-shift-factor)));
  }
  to {
    transform: scale(1) translateX(0);
  }
}

.carousel-wrapper {
  &__slide-wrapper {
    position: relative;
    outline: none;
    overflow: visible;
  }

  &__slider {
    position: relative;
  }

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__slider-slides {
    opacity: 0;
    outline: none;
    overflow: visible;

    &--show {
      opacity: 1;
    }

    &--disabled {
      cursor: not-allowed;
      opacity: var(--carousel-slide-disabled-opacity);
      pointer-events: none;
    }
  }

  &__buttons {
    position: absolute;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: var(--carousel-button-zindex);
    padding: var(--carousel-button-padding);

    &--back {
      top: 0;
      left: 0;
      padding-right: var(--size-4);
      margin-right: var(--carousel-button-margin);
    }
    &--next {
      top: 0;
      right: 0;
      padding-left: var(--size-4);
      margin-left: var(--carousel-button-margin);
    }
  }

  .carousel {
    &__slider-tray-wrapper {
      overflow: visible;
    }

    &__slider {
      overflow: visible;
    }

    &__slider-tray div {
      outline: none;

      .inner-html {
        p {
          font-size: var(--size-8);
          line-height: var(--size-12);
          color: var(--white);
        }
      }
    }

    &__slide {
      position: relative;
      outline: none;
      border: none;
      margin-right: var(--size-10);
      z-index: var(--carousel-slide-zindex-inactive);
      pointer-events: auto;

      &:focus {
        border: none;
      }

      &--zoom-on-hover {
        &:hover {
          z-index: var(--carousel-slide-zindex-active);
        }
      }

      &--zoom-on-hover-first {
        transform: scale(1) translateX(0);
        transition: var(--carousel-slide-zoom-transition);
        &:hover {
          transform: scale(var(--carousel-slide-scale-factor))
            translateX(calc(var(--carousel-slide-shift-factor)));
          transition-delay: var(--carousel-slide-zoom-delay);
        }
      }

      &--zoom-on-hover-middle {
        transform: scale(1);
        transition: var(--carousel-slide-zoom-transition);
        &:hover {
          transform: scale(var(--carousel-slide-scale-factor));
          transition-delay: var(--carousel-slide-zoom-delay);
        }
      }

      &--zoom-on-hover-last {
        transform: scale(1) translateX(0);
        transition: var(--carousel-slide-zoom-transition);
        &:hover {
          transform: scale(var(--carousel-slide-scale-factor))
            translateX(calc(-1 * var(--carousel-slide-shift-factor)));
          transition-delay: var(--carousel-slide-zoom-delay);
        }
      }

      &--zoom-on-first {
        animation: CarouselZoomInLeft var(--carousel-slide-zoom-transition)
          forwards;
        z-index: var(--carousel-slide-zindex-active);
      }

      &--zoom-on-middle {
        animation: CarouselZoomIn var(--carousel-slide-zoom-transition) forwards;
        z-index: var(--carousel-slide-zindex-active);
      }

      &--zoom-on-last {
        animation: CarouselZoomInRight var(--carousel-slide-zoom-transition)
          forwards;
        z-index: var(--carousel-slide-zindex-active);
      }
    }

    &__slide-inner {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: var(--carousel-slide-alignment);

      &--center {
        justify-content: center;
      }

      &--left {
        justify-content: flex-start;
      }

      &--right {
        justify-content: flex-end;
      }
    }

    &__slide-close-btn {
      border: none;
      outline: none;
      background: none;
      cursor: pointer;
      position: absolute;
      top: var(--carousel-slide-close-btn-top);
      right: var(--carousel-slide-close-btn-right);
      color: var(--carousel-slide-close-btn-color);
    }

    &__slider-tray {
      &--horizontal {
        position: relative;
        left: var(--carousel-slider-tray-left);

        @include respond(phone) {
          width: 100%;
        }

        @include respond(desktop) {
          width: var(--carousel-slider-tray-width) !important; // do not change
        }
      }
    }

    &__dot-group {
      padding: var(--carousel-dotgroup-padding);
    }

    &__dot {
      margin-right: var(--carousel-dots-margin);
      background: var(--carousel-dots-color);
      width: var(--carousel-dots-width);
      height: var(--carousel-dots-height);
      border-radius: var(--size-10);
      border: none;
      outline: none;
      &--selected {
        background: var(--carousel-dots-color-selected);
      }
    }

    &__next-btn,
    &__back-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: 0;
      border-radius: 50%;
      outline: none;
      border: none;
      width: var(--carousel-button-size);
      height: var(--carousel-button-size);
      color: var(--carousel-button-color-active);
      background: var(--carousel-button-background);
      box-shadow: var(--carousel-button-shadows);

      &:hover {
        color: var(--carousel-button-color-inactive);
      }
    }
  }
}

.ease-out {
  transition: transform 0.5s;
  transition-timing-function: ease-out;
  will-change: transform;
}
