.overlay-backdrop {
  z-index: var(--z-index-over-all-third);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

// prevents body from scrolling when react modal is open
body.ReactModal__Body--open {
  overflow: hidden;
}

.fixed-body {
  position: fixed;
  top: var(--scroll-top);
}
