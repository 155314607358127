@import '../../../scss/global/utils/mixins';

.takeabreak-modal {
  .modal-body__content {
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      max-width: var(--size-10);
    }
    &::-webkit-scrollbar-track {
      background-color: var(--primary-900);
    }
  }
}

.takeabreak {
  h6 {
    margin-top: var(--size-8);
  }
  &--disabled {
    opacity: 0.5;
  }

  &__rules {
    padding: var(--size-16) 0;
  }

  &__rule {
    display: flex;
    align-items: center;
    margin-bottom: var(--size-16);
  }

  &__rule-icon {
    font-size: var(--font-size-20);
    color: var(--body-40);
    margin-right: var(--size-16);
  }

  &__rule-text {
    flex: 1;
  }

  &__time {
    margin-top: var(--size-8);
  }

  &__paragraph-2 {
    margin: var(--size-16) 0;
  }

  &__link {
    cursor: pointer;
    font-family: var(--body-font-family);
    font-size: var(--paragraph-m-font-size);
    color: var(--white);
    text-decoration: underline;
    outline: none;
    background: none;
    border: none;
  }

  &__footer {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .takeabreak {
      &__secondary-btn {
        outline: none;
      }
    }
  }

  &__footer-btn {
    display: flex;
    gap: var(--size-10);
    justify-content: flex-end;
  }

  &__condition-list {
    padding: var(--size-8) 0;

    p {
      margin-bottom: var(--size-12);
    }
  }

  &__confirm-btn {
    &.button.button--primary {
      background-color: var(--btn-primary-bg-color);
      color: var(--btn-primary-color);

      &:focus {
        border-color: var(--btn-primary-bg-color);
        background-color: var(--btn-primary-bg-color);
        color: var(--btn-primary-color);
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: var(--btn-primary-hover-bg-color);
          border-color: var(--btn-primary-hover-border-color);
          color: var(--btn-primary-hover-color);
        }
      }
    }
  }
}
