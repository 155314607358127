@import '../../../../scss//global/utils/media-queries';
@import '../../../../scss//global/utils/mixins';

.registration-introduction-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__banner-link {
    display: block;
    margin-bottom: var(--size-16);

    img {
      width: 100%;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;

    button:first-child {
      margin-right: var(--size-8);
      border-color: var(--white);
      color: var(--white);

      @include respond(desktop){
        border-color: var(--btn-secondary-color);
        color: var(--btn-secondary-color);;
      }
    }
  }

  .heading--6, .paragraph {
    color: var(--white);

    @include respond(desktop){
      color: var(--paragraph-color);
    }
  }

  @include lotties-animation();
}
