:root {
  --input-validation-alert-error-color: var(--danger-color);
  --input-validation-alert-warning-color: var(--warning-color);
  --input-validation-alert-success-color: var(--success-color);
  --input-validation-alert-info-color: var(--info);
  --input-validation-alert-dark-error-color: var(--danger-300);
  --input-validation-alert-dark-warning-color: var(--warning-400);
  --input-validation-alert-dark-success-color: var(--success-300);
  --input-validation-alert-dark-info-color: var(--info);
}

.input-validation-alert {
  &--error {
    color: var(--input-validation-alert-error-color);
  }

  &--warning {
    color: var(--input-validation-alert-warning-color);
  }

  &--success {
    color: var(--input-validation-alert-success-color);
  }

  &--info {
    color: var(--input-validation-alert-info-color);
  }

  &--dark {
    &.input-validation-alert--error {
      color: var(--input-validation-alert-dark-error-color);
    }
    &.input-validation-alert--warning {
      color: var(--input-validation-alert-dark-warning-color);
    }
    &.input-validation-alert--success {
      color: var(--input-validation-alert-dark-success-color);
    }
    &.input-validation-alert--info {
      color: var(--input-validation-alert-dark-info-color);
    }
  }
}
