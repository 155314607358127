@import '../../scss/betzone-uk/utils/mixins.betzone-uk';

$banner-height: 11.25rem;
$tab-port-banner-height: 17.5rem;
$desktop-banner-height: 18.75rem;
$big-desktop-banner-height: 22.5rem;
$border-radius: var(--size-8);
$big-desktop-border-radius: var(--size-16);
$custom-desktop-breakpoint: 80.0625rem;

@mixin heroBannerSkeleton {
  min-height: 20rem;
  @include skeleton;
  @include respond('phone') {
    min-height: 10rem;
  }
}

// Styles for the disabled (greyed out) slides
.hero-carousel-slide,
.hero-banner-carousel__static-banner {
  &--disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  // keep events on the parent div but prevent on links as buttons
  &--partial-account {
    pointer-events: all;

    a {
      pointer-events: none;
    }
  }

  &:hover {
    cursor: pointer;
  }
}
.carousel-wrapper {
  z-index: 0;
}
.hero-banner-carousel {
  $controls-bottom: var(--size-16);

  width: 100%;

  @each $size in 'phone', 'tab-port', 'desktop' {
    @include respond($size) {
      margin-bottom: var(--hero-banner-margin-bottom-#{$size});
    }
  }

  @include respond(desktop) {
    .carousel-wrapper__buttons {
      right: var(--size-16);
      bottom: var(--size-16);
    }
  }

  @include respond(big-desktop) {
    .carousel-wrapper {
      .carousel-wrapper__buttons {
        right: var(--size-24);
        bottom: var(--size-24);

        &--back {
          right: 4.7rem;
          left: auto;
        }
      }
    }
  }

  &__carousel {
    overflow: hidden;
    &--skeleton {
      @include heroBannerSkeleton;
    }

    @include respond(desktop) {
      border-radius: $border-radius;
    }

    @include respond(phone) {
      overflow: visible;
    }
  }

  &--desktop {
    .carousel-wrapper .carousel__slide {
      margin: 0;
    }
  }

  &--mobile {
    /* Apply 2 blocks below to move hero banner carousel to the left screen edge outside page padding with
    slider visible full width */
    width: calc(100% + var(--size-40));
    margin-left: calc(-1 * var(--size-32));

    @include respond(phone) {
      width: calc(100% + var(--size-16));
      margin-left: calc(-1 * var(--size-16));
    }

    // Reset carousel shift
    @include respond(tab-land) {
      width: 100%;
      margin-left: 0;
    }

    .carousel-wrapper {
      .carousel__slide {
        margin: 0;
      }
    }
  }

  &--with-static-banner {
    @media only screen and (min-width: $custom-desktop-breakpoint) {
      display: grid;
      grid-template-columns: repeat(var(--grid-desktop-columns), 1fr);
      gap: var(--grid-desktop-gutter);
    }

    @include respond(big-desktop) {
      grid-template-columns: repeat(var(--grid-big-desktop-columns), 1fr);
      gap: var(--grid-big-desktop-gutter);
    }

    .hero-banner-carousel {
      &__static-banner {
        display: none;
        width: 100%;
        height: $desktop-banner-height;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center right;
        &--skeleton {
          @include heroBannerSkeleton;
        }
      }

      @media only screen and (min-width: $custom-desktop-breakpoint) {
        &__carousel {
          grid-column: 1 / 9;
        }

        &__static-banner {
          display: block;
          grid-column: 9 / 13;
          padding: var(--size-16);
          border-radius: $border-radius;
        }

        &__static-banner-tag {
          display: inline-block;
          padding: var(--size-4) var(--size-12);
          font-weight: 600;
          font-size: var(--size-12);
          min-height: var(--size-28);
        }
      }

      @include respond(big-desktop) {
        &__carousel {
          grid-column: 1 / 17;
        }

        &__static-banner {
          grid-column: 17 / 25;
          padding: var(--size-24);
          border-radius: $big-desktop-border-radius;
          height: $big-desktop-banner-height;
        }
      }
    }
  }

  .carousel-wrapper .carousel__dot-group {
    display: none;
  }

  .carousel-wrapper__header {
    padding: 0;
    @each $size in 'phone', 'tab-port', 'tab-land' {
      @include respond($size) {
        display: none;
      }
    }
    @include respond(desktop) {
      display: block;
      position: absolute;
      z-index: 1;
      right: var(--size-124);
      left: auto;
      bottom: var(--size-27);
    }

    @include respond(big-desktop) {
      right: 8.25rem;
    }

    button:last-of-type {
      margin-right: var(--size-16);
    }
  }

  @include respond(tab-port) {
    .carousel-wrapper .carousel__dot-group {
      z-index: 20;
      position: absolute;
      bottom: var(--size-32);
      right: 7.25rem;
      display: flex;
      padding: 0;
    }

    .carousel-wrapper__buttons {
      display: block;
      padding: 0;
      height: auto;
      top: auto;
      left: auto;
      margin: 0;

      &--back {
        right: 4.1875rem;
        left: auto;
      }
    }
  }

  @include respond(big-desktop) {
    border-radius: $big-desktop-border-radius;
  }

  @include circular-carousel-dots();

  .hero-banner {
    margin-bottom: 0;
  }
}

.hero-carousel-slide {
  height: 100%;
  cursor: pointer;

  &__link-wrapper {
    &:hover {
      text-decoration: none;
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 11.25rem 0 var(--size-16);
  }

  &__heading {
    margin: 0;

    @include headingStyles(5);

    h1,
    h2 {
      color: var(--white);
      font-size: var(--size-24);
    }

    @include respond(phone) {
      h1,
      h2 {
        font-size: var(--size-20);
        margin-bottom: var(--size-12);
      }
    }
  }

  &__sub-heading {
    margin: var(--size-4) 0 0 0;
  }

  &__sub-heading,
  &__link {
    &--disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  &__sub-heading {
    display: none;
  }

  &__hero-banner {
    height: $banner-height;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-position: center right;
    border-radius: $border-radius;
  }

  &__footer {
    @include typographySize(xxs);

    display: none;
  }

  &__mobile-footer {
    @include typographySize(xxs);

    padding: var(--size-4) var(--size-8) var(--size-8);
    background: var(--backdrop-bg-color);
  }

  @include respond(tab-port) {
    cursor: unset;

    &__hero-banner {
      display: block;
      height: $tab-port-banner-height;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
      padding: 2.75rem 17.5rem 2.75rem var(--size-24);
    }

    &__link {
      display: block;
      .button {
        height: var(--size-48);
      }

      &.button--submitting {
        pointer-events: none;

        a {
          span {
            opacity: 0;
          }
        }

        .spinner {
          height: var(--size-32);
          margin-top: var(--size-8);
        }
      }
    }

    &__heading {
      @include headingStyles(4);

      width: 30.5rem;
      margin: 0;

      .heading {
        width: 21rem;

        &.heading--1 {
          font-size: var(--size-24);
          margin-bottom: 0;
        }
      }
    }

    &__sub-heading {
      @include headingStyles(6);

      margin: var(--size-4) 0 var(--size-16) 0;
      display: block;
    }

    &__footer {
      display: block;
      width: 30.5rem;
      margin-top: var(--size-16);

      &:hover {
        text-decoration: none;
        color: var(--white);
      }
    }

    &__mobile-footer {
      display: none;
    }
  }

  @include respond(phone) {
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
      padding: var(--size-24) 11.25rem var(--size-24) var(--size-16);
    }

    &__heading {
      @include headingStyles(2);

      margin: 0;

      .heading {
        width: 9.1875rem;

        &.heading--1 {
          font-size: var(--size-20);
          margin-bottom: 0;
          line-height: var(--size-24);
        }
        &.heading--2 {
          line-height: var(--size-20);
        }
      }
    }
  }

  // Custom desktop value here by figma designs
  @media only screen and (min-width: $custom-desktop-breakpoint) {
    &__heading {
      @include headingStyles(2);

      width: 29.5rem;
      margin: 0;

      .heading {
        &.heading--1,
        &.heading--2 {
          font-size: var(--size-32);
          margin-bottom: 0;
          width: 29.5rem;
        }
      }
    }

    &__sub-heading {
      @include headingStyles(5);

      margin: var(--size-4) 0 var(--size-16) 0;
    }

    &__content {
      padding: var(--size-36) 18.75rem var(--size-36) var(--size-24);
    }

    &__hero-banner {
      height: $desktop-banner-height;
    }

    &__footer {
      width: 29.5rem;
      margin-top: var(--size-16);
    }
  }

  @include respond(big-desktop) {
    &__hero-banner {
      border-radius: $big-desktop-border-radius;
      height: $big-desktop-banner-height;
    }

    &__heading {
      @include headingStyles(1);

      margin: 0;

      .heading {
        width: 37rem;

        &.heading--1 {
          font-size: var(--size-32);
          margin-bottom: 0;
          line-height: 3.75rem;
          text-shadow: 0 0 var(--black)
        }
      }
    }

    &__sub-heading {
      @include headingStyles(4);

      margin: var(--size-8) 0 0 0;
      font-size: var(--size-20);
      line-height: var(--size-30);
    }

    &__content {
      padding: 2.5rem 22.5rem 2.5rem 2.5rem;
    }

    &__footer {
      @include typographySize(xs);

      width: 37rem;
      margin-top: var(--size-16);
    }

    &__link {
      margin-top: var(--size-24);
    }
  }

  &__heading,
  &__sub-heading,
  &__footer,
  &__mobile-footer {
    color: var(--white);

    strong {
      color: var(--yellow-100);
      font-weight: 700;
    }
  }
}
