@import '../../../scss/global/utils/mixins';

:root {
  --side-drawer-backdrop-bg-color: var(--backdrop-bg-color);
  --side-drawer-mobile-width: 100%;
  --side-drawer-desktop-width: 32.5rem;
}

.side-drawer-backdrop {
  background-color: var(--side-drawer-backdrop-bg-color);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: var(--z-index-overlay);

  &.ReactModal__Overlay--after-open {
    opacity: 1;
  }

  &.ReactModal__Overlay--before-close {
    opacity: 0;
  }
}

.side-drawer {
  position: absolute;
  outline: 0;
  height: 100%;
  top: 0;
  transform: translateX(calc(-1 * var(--side-drawer-mobile-width)));
  width: var(--side-drawer-mobile-width);
  background-color: var(--white);
  transition: all 0.3s ease-in-out;
  overflow: hidden;

  &.ReactModal__Content--after-open {
    transform: translateX(0);
  }

  &.ReactModal__Content--before-close {
    transform: translateX(calc(-1 * var(--side-drawer-mobile-width)));
  }

  @include respond(tab-port) {
    transform: translateX(calc(-1 * var(--side-drawer-desktop-width)));
    width: var(--side-drawer-desktop-width);

    &.ReactModal__Content--before-close {
      transform: translateX(calc(-1 * var(--side-drawer-desktop-width)));
    }
  }
}
