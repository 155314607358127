@import '../../../../scss/global/utils/mixins';

.game-features {
  &__list-item {
    display: flex;
    gap: var(--size-12);
    align-items: center;
    padding: 0.7rem 0;
    font-size: var(--font-size-14);
    line-height: var(--size-24);
    color: var(--black);

    &:not(:last-child) {
      border-bottom: 1px solid var(--body-5);
    }

    @include safari-grid {
      img,
      dt {
        margin-right: var(--size-12);
      }
    }
  }

  &__list-item-tooltip {
    margin-left: auto;
    font-size: var(--font-size-14);

    svg {
      fill: var(--body-30);
    }
  }

  &__list-item-img {
    display: flex;
    justify-content: center;
    min-width: var(--size-60);
  }
}
