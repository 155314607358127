@import '../../../../scss/global/utils/mixins';

.cancel-wrapper {
  &__cancel-btn {
    width: auto;
    text-transform: uppercase;
    margin-right: var(--size-16);

    &.button.button--secondary-alt {
      color: var(--black);
      border-color: var(--black);

      &:hover {
        color: var(--body-90);
        border-color: var(--body-90);
      }
    }

    @include respond(extra-big-desktop) {
      max-width: 12.25rem;
    }
  }
}
