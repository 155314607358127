@import '../../../../scss/global/utils/mixins';

:root {
  --select-native-input-color: var(--body-text-color);
  // dropbox container
  --select-native-dropbox-disabled-background-color: var(--grey-300);
  --select-native-dropbox-disabled-color: var(--grey-500);
}

.select-native {
  background-color: var(--select-background);
  border-radius: var(--select-border-radius);

  &__select {
    position: relative;
    appearance: none;
    padding: var(--select-padding);
    min-width: var(--select-min-width);
    width: var(--select-width);
    border-radius: var(--select-border-radius);
    border: var(--select-border);
    z-index: var(--z-index-select-input);
    background-color: transparent;
    font-family: var(--select-font-family);
    font-size: var(--select-font-size);
    font-weight: var(--select-font-weight);
    line-height: var(--select-line-height);
    color: var(--select-native-input-color);

    &--is-placeholder {
      color: var(--select-placeholder-color);
    }

    &:hover {
      color: var(--select-border-hover-color);
      border: var(--select-border-hover-border);
    }
  }

  &__chevron {
    @include select-chevron;
  }

  &--dark {
    .select-native__select {
      color: var(--white) !important;
    }
    .select-native__option {
      background: var(--select-dark-bg-color-accent);
      color: var(--select-dark-color);
    }
    .select-native__chevron {
      color: var(--white) !important;
    }
  }

  &--disabled {
    opacity: var(--select-disabled-opacity);
    background: var(--select-native-dropbox-disabled-background-color);
    border: none;

    .select-native__select {
      color: var(--select-native-dropbox-disabled-color);
      cursor: not-allowed;
    }

    .select-native__chevron {
      color: var(--select-disabled-chevron-color);
    }
  }

  &--has-image {
    .select-native__select {
      text-indent: var(--select-has-image-padding);
    }
  }

  &--has-value {
    .select-native__select {
      color: var(--select-has-value);
      border: var(--select-completed-border);
    }
  }

  &--error {
    .select-native__select {
      border: var(--select-error-border);
    }

    .select-native__chevron {
      color: var(--select-error-chevron-color) !important;
    }
  }

  &--warning {
    .select-native__select {
      border: var(--select-warning-border);
    }

    .select-native__chevron {
      color: var(--select-warning-chevron-color) !important;
    }
  }

  &--success {
    .select-native__select {
      border: var(--select-success-border);
    }

    .select-native__chevron {
      color: var(--select-success-chevron-color) !important;
    }
  }

  select:focus {
    outline: var(--select-focus-outline);
  }
}
