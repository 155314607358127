.account-wallet {
  position: absolute;
  top: -3.5rem;
  left: 0;

  &__wrapper {
    padding: 0 var(--size-16);
    display: flex;
    width: 100%;
  }

  &__tile {
    height: 5rem;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;

    &:first-child {
      margin-right: var(--size-8);
    }
  }

  &__tile-icon {
    align-self: center;
    svg {
      display: block;
    }
  }
}
