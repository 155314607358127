.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  svg {
    min-width: var(--size-48);
    min-height: var(--size-48);
  }
}
