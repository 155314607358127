@import '../../../../scss/global/utils/media-queries';
@import '../../../../scss/global/utils/mixins';

:root {
  --touch-drawer-gutter: var(--size-16);
  //  touchdrawer
  --touch-drawer-transition-duration: 0.3s;
  //  viewport
  --touch-drawer-viewport-padding: var(--size-10);
  --touch-drawer-viewport-background: var(--white);
  --touch-drawer-viewport-background-dark: var(--primary-900);
  --touch-drawer-viewport-max-width: 37.5rem;
  --touch-drawer-viewport-border-radius: var(--size-36);
  //  backdrop
  --touch-drawer-backdrop-background: rgba(0, 0, 0, 0.5);
  --touch-drawer-backdrop-background-lance: rgba(3, 30, 86, 0.75);
  // content
  --touch-drawer-content-overflow-shadow-color: rgba(0, 0, 0, 0.15);
  --touch-drawer-content-overflow-shadow-size: 10px;
  //  touchpad
  --touch-drawer-touchpad-padding: var(--size-20);
  --touch-drawer-touchpad-bar-width: var(--size-48);
  --touch-drawer-touchpad-bar-height: var(--size-4);
  --touch-drawer-touchpad-bar-color: var(--grey-300);
  --touch-drawer-touchpad-bar-color-dark: var(--white);
  --touch-drawer-touchpad-bar-border-radius: 2px;
  //  header/content/footer
  --touch-drawer-content-padding: var(--size-16) var(--size-16);
  --touch-drawer-header-padding: var(--size-20) var(--size-16);
  --touch-drawer-subtitle-padding: var(--size-20) var(--size-16);
  --touch-drawer-footer-padding: var(--size-20) var(--size-16);
  --touch-drawer-content-yscroll-threshold: var(--size-384);
  //  close button
  --touch-drawer-closebtn-color: var(--white);
  --touch-drawer-closebtn-color-inner: #a6a6a6;
  --touch-drawer-closebtn-size: var(--size-24);
  --touch-drawer-closebtn-top: var(--size-20);
  --touch-drawer-closebtn-right: var(--size-20);
  // scrollbar
  --touch-drawer-overflow-scrollbar-width: var(--size-4);
  --touch-drawer-overflow-scrollbar-track: var(--grey-100);
  --touch-drawer-overflow-scrollbar-thumb: var(--grey-300);
  --touch-drawer-overflow-scrollbar-thumb-hover: var(--grey-500);
  //  closing
  --touch-drawer-closing-height: var(--size-20);
  --touch-drawer-closing-background-bottom: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  --touch-drawer-closing-background-left: linear-gradient(
    -90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  --touch-drawer-closing-background-top: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  //  text color
  --touch-drawer-text-color--dark: var(--white);
  --touch-drawer-text-color-primary--dark: var(--primary-900);
}


.touch-drawer {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  touch-action: none;
  pointer-events: none;
  z-index: var(--z-index-touch-drawer);
  overflow: hidden;
  &--dark {
    @include dark-theme-text-color;
  }

  &__container {
    position: absolute;
    outline: 0;
    left: 0;

    &--bottom {
      bottom: 0;
    }

    &--bottom,
    &--top {
      width: 100%;
      right: 0;
    }

    &--left {
      top: 0;
    }
    @media #{$phone-landscape} {
      height: 85%;
    }
  }

  &__close-btn {
    position: fixed;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    top: var(--touch-drawer-closebtn-top);
    right: var(--touch-drawer-closebtn-right);
    width: var(--touch-drawer-closebtn-size);
    height: var(--touch-drawer-closebtn-size);
    &:focus {
      outline: var(--focus-outline);
    }
    &-inside {
      color: var(--touch-drawer-closebtn-color-inner);
    }
    &-outside {
      color: var(--touch-drawer-closebtn-color);
    }
  }

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
    z-index: var(--z-index-touch-drawer);
    background: var(--touch-drawer-backdrop-background);
    transition: var(--touch-drawer-transition-duration);

    &:focus {
      outline: var(--focus-outline);
    }

    &--fade-in {
      opacity: 1;
    }

    &--fade-out {
      opacity: 0;
    }
  }

  &__viewport {
    position: relative;
    background: var(--touch-drawer-viewport-background);
    max-height: 100%;
    display: flex;

    &--dark {
      color: var(--white);
      background: var(--touch-drawer-viewport-background-dark);
    }

    &--bottom {
      left: 0;
      width: 100%;
      flex-direction: column;
      padding: 0 0 var(--touch-drawer-viewport-padding) 0;
      border-radius: var(--touch-drawer-viewport-border-radius)
        var(--touch-drawer-viewport-border-radius) 0 0;
      @include respond(desktop) {
        max-width: var(--touch-drawer-viewport-max-width);
        left: calc(50% - var(--touch-drawer-viewport-max-width) / 2);
      }

      .touch-drawer__content {
        padding: var(--size-8) var(--size-16) var(--size-16) var(--size-16);
      }
    }

    &--top {
      left: 0;
      width: 100%;
      flex-direction: column;
      padding-bottom: var(--touch-drawer-viewport-padding);
      border-radius: 0 0 1rem 1rem;
      @include respond(desktop) {
        max-width: var(--touch-drawer-viewport-max-width);
        left: calc(50% - var(--touch-drawer-viewport-max-width) / 2);
      }
    }

    &--left {
      top: 0;
      left: 0;
      height: 100%;
      flex-direction: row;
      padding: 0 var(--touch-drawer-viewport-padding);
      border-radius: 0 var(--touch-drawer-viewport-border-radius)
        var(--touch-drawer-viewport-border-radius) 0;
      max-width: var(--touch-drawer-viewport-max-width);

      .touch-drawer__content {
        padding: var(--size-16) 0 var(--size-16) var(--size-16);
      }
    }

    @media #{$phone-landscape} {
      overflow: auto;
    }
  }

  &__touchpad {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.3;

    &:focus {
      outline: var(--focus-outline);
    }

    &--bottom {
      width: 100%;
      order: 0;
      padding: var(--size-16) 0;
    }

    &--top {
      width: 100%;
      order: 1;
      padding: 1rem 0;
    }

    &--left {
      width: auto;
      order: 2;
      padding: 0 var(--touch-drawer-touchpad-padding);
    }
  }

  &__bars-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &__bars {
    border-radius: var(--touch-drawer-touchpad-bar-border-radius);
    background: var(--touch-drawer-touchpad-bar-color);
    position: relative;
    transform: translateY(-50%);
    top: 25%;
    margin: auto;

    &--dark {
      background: var(--touch-drawer-touchpad-bar-color-dark);
    }

    &--bottom,
    &--top {
      width: var(--touch-drawer-touchpad-bar-width);
      height: var(--touch-drawer-touchpad-bar-height);
    }

    &--left {
      width: var(--touch-drawer-touchpad-bar-height);
      height: var(--touch-drawer-touchpad-bar-width);
    }
  }

  &__header {
    &--dark {
      @include dark-theme-text-color;
    }
    padding: var(--touch-drawer-header-padding);

    .input-feedback-icon {
      display: inline-block;
      padding-left: var(--size-8);
      top: var(--size-4);
    }
  }

  &__subtitle {
    padding: var(--touch-drawer-subtitle-padding);

    .heading {
      font-weight: var(--paragraph-font-weight);
    }
  }

  &__footer {
    padding: var(--touch-drawer-footer-padding);
    &--dark {
      @include dark-theme-text-color;
    }
  }

  &__content {
    padding: var(--touch-drawer-content-padding);
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
    background-repeat: no-repeat;
    background-color: var(--touch-drawer-viewport-background);
    background-size: 100% 10px, 100% 10px,
      100% var(--touch-drawer-content-overflow-shadow-size),
      100% var(--touch-drawer-content-overflow-shadow-size);
    background-attachment: local, local, scroll, scroll;
    &--dark {
      @include dark-theme-text-color;
      background: var(--touch-drawer-viewport-background-dark);
    }

    &--bottom,
    &--top {
      max-height: var(--touch-drawer-content-yscroll-threshold);
    }

    &--left {
      height: 100%;
    }

    &::-webkit-scrollbar {
      width: var(--touch-drawer-overflow-scrollbar-width);
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--touch-drawer-overflow-scrollbar-track);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--touch-drawer-overflow-scrollbar-thumb);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: var(--touch-drawer-overflow-scrollbar-thumb-hover);
    }
  }

  &__closing {
    position: absolute;
    transition: var(--touch-drawer-transition-duration);
    pointer-events: none;
    touch-action: none;

    &--bottom,
    &--top {
      background: var(--touch-drawer-closing-background-bottom);
      height: var(--touch-drawer-closing-height);
      width: 100%;
      left: 0;
    }

    &--top {
      background: var(--touch-drawer-closing-background-top);
    }

    &--bottom-show {
      opacity: 1;
      bottom: 0;
    }
    &--top-show {
      top: 0;
      opacity: 1;
    }

    &--bottom-hide {
      opacity: 0;
      bottom: -100%;
    }

    &--top-hide {
      opacity: 0;
      top: -100%;
    }

    &--left {
      background: var(--touch-drawer-closing-background-left);
      width: var(--touch-drawer-closing-height);
      height: 100%;
      bottom: 0;
    }
    &--left-show {
      opacity: 1;
      left: 0;
    }
    &--left-hide {
      opacity: 0;
      left: -100%;
    }
  }
}
