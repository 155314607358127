@import '../utils/media-queries';

// Accepted $size values are xl, l, m, s, xs, xxs
@mixin paragraphStyles($size) {
  font-weight: var(--paragraph-font-weight);
  text-transform: var(--paragraph-text-transform);
  color: var(--paragraph-color);
  margin: var(--paragraph-margin);

  @include typographySize($size);
}

@mixin typographySize($size) {
  font-size: var(--paragraph-#{$size}-font-size);
  line-height: var(--paragraph-#{$size}-line-height);
}

// Acceptable $level values are 1, 2, 3, 4, 5, 6
@mixin headingStyles($level) {
  color: var(--heading-color);
  margin: var(--heading-margin);

  @include headingTypography($level);
}

@mixin headingTypography($level) {
  font-size: var(--h#{$level}-font-size);
  letter-spacing: var(--h#{$level}-letter-spacing);
  line-height: var(--h#{$level}-line-height);
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  text-transform: var(--heading-text-transform);
}

@mixin truncateText() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin switch-list {
  .checkbox {
    display: flex;
    border-bottom: 1px solid var(--body-5);
    padding-bottom: var(--size-16);

    &__label {
      flex: 1;
      font-size: var(--size-12);
      margin: 0;
    }

    &__container {
      flex-direction: row-reverse;
    }

    &__inline-icon {
      margin: 0 var(--size-8) 0 0;
    }
  }
}

@mixin circular-carousel-dots($selectedColor: var(--yellow-100)) {
  .carousel-wrapper .carousel__dot {
    height: var(--size-8);
    width: var(--size-8);
    margin-right: var(--size-8);
    background: var(--white);

    &--selected {
      background: $selectedColor;
    }
  }
}

@mixin grid {
  @each $size in $breakpoint-names {
    @include respond($size) {
      padding: var(--modal-#{$size}-vertical-margin) var(--grid-#{$size}-horizontal-margin);
      grid-template-columns: repeat(var(--grid-#{$size}-columns), 1fr);
      gap: var(--grid-#{$size}-gutter);
    }
  }
}

@mixin rounded-page-content {
  border-top-right-radius: var(--size-16);
  border-top-left-radius: var(--size-16);
  background-color: var(--primary-900);
  padding: 0 var(--size-16);
}

@mixin align-vertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin align-by-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin align-by-center-with-dimensions($dimension) {
  $dimension-centered: calc(50% - #{$dimension} / 2);

  position: absolute;
  width: $dimension;
  height: $dimension;
  top: $dimension-centered;
  left: $dimension-centered;
}

@mixin styled-scrollbar {
  &::-webkit-scrollbar {
    width: var(--size-16);
  }

  &::-webkit-scrollbar-track {
    background: var(--white);
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 var(--size-10) var(--size-10) var(--grey-200);
    border: solid 3px transparent;
    border-radius: 16px;
  }
}

@mixin modal-item-bold {
  font-weight: var(--paragraph-font-weight-bolder);
  color: var(--body-100);
}

@mixin succes-confirmation-icon {
  display: inline-block;
  vertical-align: sub;
  margin-left: var(--size-16);
  background-image: url($assets-url + '/images/prompts/success.png');
  background-position: center;
  background-repeat: no-repeat;
  width: var(--size-24);
  height: var(--size-24);
  content: '';
}

@mixin promotions-block-responsive {
  $padding-default: 1rem;

  @include respond(phone) {
    padding: 1rem 1rem 1rem;
  }

  @include respond(tab-port) {
    padding: 1rem 1.5rem 1rem;
  }

  @include respond(desktop) {
    padding: 1rem 2rem 2.3rem;
  }

  @include respond(extra-big-desktop) {
    padding: 3rem 3.3rem 2rem;
  }

  &__title {
    width: 100%;
    margin-bottom: 0;
    @include respond(desktop) {
      margin-bottom: 0;
    }
  }
}

@mixin cashier-iframe {
  overflow: auto;

  @include respond(phone) {
    height: auto;
  }

  &__iframe {
    height: 99%;
    width: 100%;
    border-radius: var(--size-16) var(--size-16) 0 0;
    overflow-y: auto;

    @include respond(phone) {
      height: 75vh;
    }

    @media #{$phone-landscape} {
      height: 92vh;
    }
  }

  .error-messages {
    position: absolute;
  }
}

@mixin cashier-prompt {
  &__modal,
  &__drawer {
    width: 45rem;
    &-smallFontSize {
      width: 60rem;

      .deposit-content {
        height: 56rem;
      }

      .withdrawal-content {
        height: 30rem;
      }
    }
  }

  &__modal {
    place-self: center;
  }

  &__drawer {
    .touch-drawer {
      &__container,
      &__content {
        width: 100%;

        // Fix for low drawer on phones with big height
        @include respond(phone) {
          min-height: 75vh;
          max-height: 90vh;
        }
      }
    }
  }
}

@mixin navigation-top {
  &:first-of-type {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--size-4);

    > div {
      &:nth-child(1),
      &:nth-child(2) {
        width: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
}

@mixin navigation-divider {
  height: var(--navigation-divider-height);

  &::after {
    position: relative;
    top: calc(var(--navigation-divider-height) / 2);
    content: '';
    height: 0.0625rem;
    width: calc(100% - var(--size-32));
    background-color: var(--dark-blue-25);
    display: block;
    margin: 0 auto;
  }
}

@mixin navigation-link {
  &:nth-child(1),
  &:nth-child(2) {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include respond(phone) {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      padding: var(--size-16) 0 var(--size-16) var(--size-32);
    }

    .navigation-link {
      &__wrapper {
        display: flex;
        flex-direction: column;

        .u-position-relative {
          @include respond(phone) {
            padding-bottom: 0;
          }
        }

        .navigation-link {
          &__text {
            margin: var(--size-6);
            padding: 0;

            @include respond(phone) {
              margin: 0 0 0 var(--size-16);
            }
          }
        }
      }
    }
  }
}

@mixin navigation-link-expanded {
  &:nth-child(1),
  &:nth-child(2) {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include respond(phone) {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      padding: var(--size-16) 0 var(--size-16) var(--size-32);
    }

    .navigation-link {
      &__wrapper {
        display: flex;
        flex-direction: column;

        .u-position-relative {
          @include respond(phone) {
            padding-bottom: var(--size-8);
          }
        }

        .navigation-link {
          &__text {
            margin: var(--size-6);
            padding: 0;

            @include respond(phone) {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

@mixin registration-header-nav-centered {
  .header {
    &__nav {
      .header__left {
        flex-grow: 1;
        display: flex;
        justify-content: center;
      }

      .header__right {
        flex-grow: 0;
        margin-left: auto;
      }
    }
  }
}

@mixin scroll-container-no-scrollbars {
  overflow-x: scroll;
  white-space: nowrap;
  -ms-overflow-style: none; /* Internet Explorer, Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }
}

// Safari on iOS < 15 does not support flexbox grid
// https://ppuzio.medium.com/flexbox-gap-workaround-for-safari-on-ios-14-13-and-lower-ffcae589eb69
// https://ishadeed.com/article/flexbox-gap/
@mixin safari-grid {
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    @content;
  }
}

@mixin icon_large {
  img {
    height: 2.5rem;
    width: auto;
    display: block;
    margin: 0.5rem;
  }
}
@mixin icon_small {
  img {
    height: 1rem;
    width: auto;
    display: block;
  }
}

@mixin typography_list($line-height-value, $font-weight-value, $font-size-value) {
  font-size: $font-size-value;
  font-weight: $font-weight-value;
  line-height: $line-height-value;
  &::before {
    content: counters(item, '.');
    counter-increment: item;
    margin-left: calc(-1 * var(--size-24));
    font-size: var(--font-size-12);
    font-weight: 600;
  }
}

@-webkit-keyframes gradient {
  0% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

@-moz-keyframes gradient {
  0% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

@keyframes gradient {
  0% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

@mixin skeleton {
  display: inline-block;
  height: 100%;
  width: 100%;
  font-size: var(--size-16);
  background: linear-gradient(135deg, var(--body-100) 45%, var(--dark-blue-50), var(--body-100) 54%);
  background-size: 300% 300%;
  border-radius: var(--size-8);
  &--animated {
    -webkit-animation: gradient 2s linear infinite;
    -moz-animation: gradient 2s linear infinite;
    animation: gradient 2s linear infinite;
  }
}

@mixin flex-center-all {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin icon-form {
  background-color: var(--white);
  border-radius: 50%;
}

@mixin venue-phone-button {
  background-color: var(--body-10);
  color: var(--dark-blue-100);
  border: none;
  font-size: var(--size-10);
}

@mixin drawer-tiles-properties {
  background-color: var(--white);
  margin-bottom: var(--size-8);
  border-radius: 0.5rem;
}

@mixin id-and-address-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--size-32);

  @include respond(phone) {
    margin-top: 0;
  }

  &__content {
    padding: var(--size-16) 0 var(--size-32) 0;

    .heading {
      text-align: center;
      color: var(--body-100);
      padding-bottom: var(--size-20);

      @include respond(phone) {
        padding-top: var(--size-8);
      }
    }

    .paragraph {
      text-align: center;
    }
  }

  &__btn-container {
    display: flex;
    width: 100%;
    justify-content: space-around;

    @include respond(phone) {
      flex-direction: column;
    }
  }

  &__secondary-btn,
  &__primary-btn {
    min-width: 40%;

    @include respond(phone) {
      min-width: 100%;
      margin-bottom: var(--size-16);
    }
  }
}

@mixin lotties-animation {
  #lottie {
    height: 12.625rem;
    width: 13.75rem;
  }
}

@mixin select-chevron {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  padding: 0 var(--size-10);
  z-index: var(--z-index-select-chevron);
  color: var(--select-chevron-color);
}

@mixin casino-pages-general {
  .navigation {
    &__children {
      &--with-padding {
        padding-bottom: 0;
      }
    }
  }

  .inner-html {
    p,
    h2 {
      color: var(--white);
    }

    p {
      margin: var(--paragraph-margin);
      font-size: var(--paragraph-s-font-size);
      font-weight: var(--paragraph-font-weight);
      line-height: var(--paragraph-s-line-height);
    }

    h2 {
      font-size: var(--size-16);
      line-height: var(--size-24);
      font-weight: var(--heading-font-weight);
      letter-spacing: var(--h2-letter-spacing);
    }
  }
}

@mixin dark-theme-text-color {
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  legend,
  span:not(.input-feedback-icon) {
    color: var(--white);
  }

  .input-validation-alert {
    &--error {
      color: var(--input-validation-alert-error-color);
    }
  }

  .toggle-button,
  .button {
    &.button--primary {
      span {
        color: var(--primary-900);
      }
    }
    &--selected span,
    &--secondary-alt:active span,
    &--secondary-alt:focus span,
    &--secondary-alt:hover span {
      color: var(--primary-900);
    }
  }
}

@mixin messageColor($color) {
  color: $color;

  &.notification-message {
    span {
      color: $color;
    }
  }
}
