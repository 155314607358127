:root {
  // Button overrides
  --btn-xx-large-width: auto;
  --btn-xx-large-font-size: 1.5rem;
  --btn-xx-large-line-height: 3.5rem;
  --btn-xx-large-border-radius: var(--btn-border-radius);
  --btn-xx-large-padding: 0.9375rem 2rem;
  --btn-xx-large-icon-size: var(--btn-x-large-font-size);
  --btn-xx-large-icon-margin: var(--btn-icon-margin);

  .button {
    &.button--xx-large {
      font-size: var(--btn-xx-large-font-size);
      line-height: var(--btn-xx-large-line-height);

      .button__icon-right,
      .button__icon-left {
        font-size: var(--btn-xx-large-icon-size);
        height: var(--btn-xx-large-icon-size);
        line-height: 1;
      }

      .button__icon-left {
        margin-right: var(--btn-xx-large-icon-margin);
      }

      .button__icon-right {
        margin-left: var(--btn-xx-large-icon-margin);
      }
    }

    &:not(.button--text):not(.button--text-alt) {
      // Text buttons don't have padding, a border or size options.
      &.button--auto {
        width: auto;
        padding: var(--btn-medium-padding);
        border-radius: var(--btn-medium-border-radius);
      }

      &.button--xx-large {
        width: var(--btn-xx-large-width);
        border-radius: var(--btn-xx-large-border-radius);
        padding: var(--btn-xx-large-padding);
      }
    }
  }
}
