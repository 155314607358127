.grid-item {
  display: flex;
  overflow: visible;

  > * {
    flex: 1;
    width: 100%;
  }

  &:focus {
    outline: var(--focus-outline);
  }

  &--overflow-hidden {
    overflow: hidden;
  }

  @for $startCol from 1 through 12 {
    @for $endCol from ($startCol + 1) through 12 {
      &--column-#{$startCol}-#{$endCol} {
        grid-column: #{$startCol} / #{$endCol + 1};
      }
    }
  }

  @for $row from 1 through 20 {
    &--row-#{$row} {
      grid-row: #{$row};
    }
  }
}
