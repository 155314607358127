@import '../../../../../scss/global/utils/mixins';

$search-results-margin-top-mobile: var(--size-12);

.search-results {
  @include respond(phone) {
    margin-top: $search-results-margin-top-mobile;
  }

  &__overlay {
    position: absolute;
    width: inherit;
    left: 0;
    border-radius: var(--size-8);
    background-color: var(--white);
    overflow: auto;
    outline: none;
    padding: 0;
    @include scroll-container-no-scrollbars;

    @include respond(tab-port) {
      height: auto;
      width: 100vw;
      left: initial;
      z-index: 3;
      box-shadow: 0 var(--size-2) var(--size-20) rgba(0, 0, 0, 0.15);
      border-radius: var(--size-8);
      top: calc(var(--header-height) + var(--navigation-subnav-min-height));
      padding: var(--size-16);
    }

    @include respond(desktop) {
      max-width: 1082px;
      overflow: hidden;
    }

  }

  &__backdrop {
    top: 0;
    width: 100%;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    z-index: 5;
    background-color: var(--backdrop-bg-color);

    .carousel-wrapper {
      &__empty {
        color: var(--black);
      }
    }

    @include respond(phone) {
      position: absolute;
      top: calc(var(--header-mobile-height) + var(--navigation-subnav-min-height));
    }

    &--secondary-search {
      &--mobile-landscape {
        z-index: var(--z-index-search-results);
        top: calc(
          var(--header-height) + var(--navigation-subnav-min-height-tab-port)
        );
        overflow-y: auto;
      }
    }

    &.ReactModal__Overlay--after-open {
      opacity: 1;
    }

    &.ReactModal__Overlay--before-close {
      opacity: 0;
    }
  }

  &__header {
    font-weight: var(--basic-title-font-weight);
  }

  &__number {
    color: var(--dark-blue-100);
  }

  &__header-buttons {
    display: flex;
    float: right;

    button {
      color: var(--dark-blue-25);
      font-size: var(--size-16);
      margin: 0;

      &:first-child {
        margin-right: var(--size-8);
      }

      &.active {
        color: var(--dark-blue-100);
      }
    }
  }

  &__card-wrap {
    position: relative;
    width: 49%;
    padding-bottom: var(--size-16);
    margin-right: var(--size-8);

    @include respond(desktop) {
      width: 15%;
    }
    @include respond(tab-port) {
      margin-right: 0;
      padding: var(--size-4);
    }
    @include respond(phone) {
      padding: var(--size-4);
      margin-right: 0;
    }
  }

  &__games-list {
    display: flex;

    .game-item {
      background: var(--body-10);
      border-radius: var(--size-8);
      margin-bottom: var(--size-8);
      display: flex;

      &__game-image {
        display: flex;

        img {
          width: 100%;
        }
      }

      &__game-container {
        flex: 70%;
        display: flex;
        flex-direction: column;
        padding: var(--size-8);
        overflow: hidden;

        span {
          background-color: var(--dark-blue-100);
          width: var(--size-16);
          height: var(--size-16);
          border-radius: 50%;
          position: relative;
          display: inline-block;
          cursor: pointer;
          vertical-align: top;
          float: right;

          &::after {
            content: 'i';
            position: absolute;
            left: 6px;
            bottom: -3px;
            color: #c4c4c4;
          }
        }
      }

      &__game-header,
      &__game-controls {
        flex: 1;
        overflow: hidden;
      }

      &__game-controls {
        text-align: right;
      }

      &__game-name {
        color: var(--dark-blue-100);
        overflow: hidden;
        line-height: var(--size-16);
        width: 88%;
        display: inline-block;
        margin-right: 5px;
      }
    }

    &_list {
      flex-flow: row wrap;

      .game-item {
        height: var(--size-80);

        &__game-image {
          flex: 30%;

          img {
            border-radius: var(--size-8) 0 0 var(--size-8);
            object-fit: cover;
            object-position: top;
          }
        }
      }
    }

    @include respond(tab-port) {
      &_list {
        .game-item {
          width: 49%;

          &:nth-child(odd) {
            margin-right: var(--size-8);
          }
        }
      }
    }

    @include respond(desktop) {
      &_list {
        .game-item {
          width: 100%;
          margin-right: 0;

          &:nth-child(odd) {
            margin-right: 0;
          }
        }
      }
    }

    &_gallery {
      flex-flow: row wrap;
      flex-direction: row;

      .game-item {
        min-width: 48%;
        max-width: 49%;
        height: 272px;
        flex-direction: column;
        flex: 1;

        &:nth-child(odd) {
          margin-right: var(--size-8);
        }

        &__game-image {
          height: 50%;

          img {
            border-radius: var(--size-8) var(--size-8) 0 0;
            object-fit: cover;
            object-position: top;
          }
        }

        &__game-container {
          height: 50%;
        }

        &__game-header {
          flex: 2;

          .game-item__game-name {
            width: 81%;

            @include respond(phone) {
              width: 86%;
            }
          }
        }

        &__game-controls .game-item__play-button {
          width: 100%;
        }
      }
    }

    @include respond(tab-port) {
      &_gallery .game-item {
        max-width: 32%;
        min-width: 32%;
        margin-right: var(--size-8);

        &:nth-child(3n + 3) {
          margin-right: 0;
        }
      }
    }

    @include respond(tab-land) {
      &_gallery .game-item {
        max-width: 24%;
        min-width: 24%;

        &:nth-child(3n + 3) {
          margin-right: var(--size-8);
        }

        &:nth-child(4n + 4) {
          margin-right: 0;
        }
      }
    }

    @include respond(desktop) {
      &_gallery .game-item {
        min-width: 48%;
        max-width: 49%;
        margin-right: 0;

        &:nth-child(3n + 3) {
          margin-right: 0;
        }

        &:nth-child(odd) {
          margin-right: var(--size-8);
        }
      }
    }
  }
}
