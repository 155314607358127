// Useful wrapper for PopupHandler footers if it's a standard footer containing right aligned actions
.u-action-footer {
  display: flex;
  justify-content: flex-end;
  gap: var(--size-16);
  
  @include respond(phone) {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    > *:not(:last-child) {
      margin-right: var(--size-16);
    }
  }
}
