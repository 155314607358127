:root {
  --card-background-color: var(--white);
  --card-border-radius: 1rem;
  --card-border: none;
  --card-box-shadow: var(--box-shadow-1);
  --card-content-padding: var(--size-10);
  --card-header-padding: var(--size-10);
  --card-header-background-color: var(--white);
}

.card {
  position: relative;
  background-color: var(--card-background-color);
  border-radius: var(--card-border-radius);
  border: var(--card-border);

  &--with-drop-shadow {
    box-shadow: var(--card-box-shadow);
  }

  &__content {
    padding: var(--card-content-padding);
  }

  &__header {
    padding: var(--card-content-padding);
    background-color: var(--card-header-background-color);

    & + .card__content {
      padding-top: 0;
    }
  }
}
