@import '../../../scss/global/utils/media-queries';

:root {
  --game-launcher-background-color: var(--primary-900);
  --game-launcher-game-background-color: var(--game-launcher-background-color);
  --game-launcher-header-color: var(--white);
  --game-launcher-header-link-margin: var(--size-36);
  --game-launcher-menu-button-border-radius: 4px;
  --game-launcher-text-transform: uppercase;
}

.game-launcher {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  background: var(--game-launcher-background-color);
  z-index: var(--z-index-game-overlay);

  &__footer {
    display: none;
    justify-content: space-around;
    color: var(--game-launcher-header-color);
    align-items: center;
    padding: var(--size-8) var(--size-36);

    @include respond(desktop) {
      display: flex;
      line-height: 1;
    }
  }

  &__game-container-content {
    width: 100%;
    height: 100%;
  }

  &--mobile-channel {
    .game-launcher__iframe {
      height: 100% !important;
    }
  }

  &__header,
  &__footer,
  &__heading,
  .button,
  .link {
    text-transform: var(--game-launcher-text-transform);
  }

  &__nav-links {
    display: flex;
    align-items: center;
    > * {
      margin-left: var(--game-launcher-header-link-margin);
      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__back-link {
    display: inline-flex;
  }

  &__header-actions {
    flex: 1;
  }

  &__game-container {
    display: flex;
    align-items: center;
    flex: 1;
    background-color: var(--game-launcher-game-background-color);
  }

  &__iframe {
    width: 100%;
    height: 100%;

    html {
      overflow: hidden;
    }
  }

  &__game-container-inner {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    iframe {
      border: none;
    }
  }

  &__game-mode-container {
    flex: 1;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  &__game-type {
    padding: var(--size-4) var(--size-10);
    font-size: var(--font-size-12);
    background-color: var(--game-launcher-header-color);
    color: var(--game-launcher-game-background-color);
    margin-right: var(--size-16);
    border-radius: var(--game-launcher-menu-button-border-radius);
  }

  &--desktop-channel {
    .game-launcher__game-container-content {
      @include respond(desktop) {
        margin: 0 auto;
      }
    }
  }

  &--mobile-channel {
    .game-launcher__iframe {
      height: 100% !important;
    }
  }

  &__game-name {
    text-transform: none;
  }

  &__divider {
    position: relative;

    &::after {
      position: absolute;
      content: ' ';
      border-right: 1px solid var(--game-launcher-header-color);
      display: block;
      right: calc(var(--game-launcher-header-link-margin) / -2);
      height: 50%;
      top: 25%;
    }
  }

  &__heading {
    color: var(--game-launcher-header-color);
    margin: 0;
  }

  &__link {
    color: var(--game-launcher-header-color);
    display: inline-flex;
    align-items: center;

    &:hover,
    &:active {
      color: var(--game-launcher-header-color);
    }
  }

  .icon-button {
    color: var(--game-launcher-header-color);
  }

  .tooltip {
    height: var(--size-24);
  }
}
