@import '../../../scss/global/utils/mixins';

.heading {
  @each $var in 1, 2, 3, 4, 5, 6 {
    &--#{$var} {
      @include headingStyles($var);
    }
  }

  &--no-margin {
    margin: 0;
  }
}
