@import '../../../scss/global/utils/mixins';

$registration-footer-mobile-height: 9.8rem;
$header-desktop-height: 3.75rem;
$body-desktop-padding-bottom: $header-desktop-height;

.registration {
  min-height: 100vh;

  &__header {
    display: grid;
    align-items: center;
    height: var(--header-mobile-height);
    @include registration-header-nav-centered;

    @include respond(tab-port) {
      height: var(--header-height);
    }

    @include respond(desktop) {
      height: #{$header-desktop-height};
      padding: 0 var(--size-56);
      align-items: flex-end;
      background-color: var(--primary-900);

      .header {
        display: none;
      }
    }

  }

  &__logo {
    &--no-click {
      pointer-events: none;
    }

    @include respond(desktop) {
      height: 100%;
    }
  }

  &__body {
    display: grid;

    @include respond(desktop) {
      grid-template-columns: repeat(var(--grid-desktop-columns), 1fr);
      gap: var(--grid-desktop-gutter);
      padding-bottom: #{$body-desktop-padding-bottom};
      align-items: center;
      min-height: calc(100vh - 3.75rem);
      background-color: var(--primary-900);
    }

    @include respond(tab-port) {
      min-height: calc(100vh - var(--header-height));
    }
  }

  &--header-hidden {
    .card__content {
      padding-top: var(--header-mobile-height);

      @include respond(tab-port) {
        padding-top: var(--header-height);
      }
    }

    .registration__body {
      min-height: 100vh;
    }
  }

  .card {
    border-radius: 0;
    height: 100%;
    overflow: hidden;
    min-height: calc(100vh - var(--header-mobile-height) - #{$registration-footer-mobile-height});
    background-color: var(--primary-900);

    @include respond(desktop) {
      border-radius: var(--card-border-radius);
      grid-column: 6 / 12;
      min-height: auto;
      background-color: var(--white);
    }

    // TODO: Think this should be using columns but there's zero description on designs..
    // Asked about it and got no answer.  Fix when somebody complains.
    .card__content {
      @include respond(tab-port) {
        padding: var(--size-32) var(--size-128);
      }

      @include respond(desktop) {
        padding: var(--size-32) var(--size-56);
      }

      @include respond(big-desktop) {
        padding: var(--size-40) 7rem;
      }
    }
  }

  &__desktop-header {
    display: none;

    svg {
      path {
        fill: var(--white);
      }
    }

    @include respond(desktop) {
      display: block;
    }
  }

  &__close {
    color: var(--white);

    @include respond(phone) {
      margin-right: var(--size-16);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    bottom: 0;
    width: 100%;
    z-index: 1;
    background-color: var(--primary-900);

    .logo {
      display: none;

      &-mobile {
        display: none;
      }
    }

    @include respond(desktop) {
      order: -1;
      display: grid;
      grid-column: 2 / 5;
      position: static;
      padding: var(--size-56) 0;

      .logo {
        display: block;
      }
    }

    .welcome-bonus-banner {
      @include respond(desktop) {
        height: 100%;
        margin: var(--size-56) 0;

        &__subtitle {
          font-size: var(--size-116);
          line-height: var(--size-100);
          text-transform: uppercase;
          font-weight: var(--font-weight-bolder);
        }
      }

      &__title {
        font-size: var(--size-24);
        line-height: var(--size-36);
        margin: 0;

        @include respond(desktop) {
          font-size: var(--size-48);
          line-height: var(--size-54);
        }
      }
    }
  }

  &__sign-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    padding: var(--size-8) var(--size-16) var(--size-24);
    background-color: var(--primary-900);

    @include respond(desktop) {
      background-color: var(--white);
    }

    @include respond(tab-port) {
      justify-content: center;
    }

    span {
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-12);
      line-height: var(--size-24);
      padding-right: var(--size-2);
      height: fit-content;
      color: var(--white);

      @include respond(desktop) {
        color: var(--body-80);
      }
    }
  }

  &__banner-heading {
    display: none;
    color: var(--white);

    @include respond(desktop) {
      display: block;
    }
  }

  #account-limits-form {
    margin-bottom: var(--size-20);
  }

  .password-requirements {
    &__requirements-list-item p {
      color: var(--white);

      @include respond(desktop) {
        color: var(--body-80);
      }
    }
  }
}
