@import '../../../../../scss/global/utils/mixins';

.game-tags {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  gap: var(--size-8);

  .chip {
    font-size: var(--size-12);
    margin-bottom: var(--size-8);
    padding: var(--size-4) var(--size-8);
    color: var(--body-5);
    border: none;
    background-color: var(--dark-yellow);

    @include safari-grid {
      margin-right: var(--size-8);
    }

    &__category {
      &--new {
        background-color: var(--orange-200);
      }
      &--exclusive {
        background-color: var(--cyan-100);
      }
      &--provider-name {
        background-color: var(--white);
        color: var(--dark-blue-75);
      }
      &--custom-field {
        color: var(--white);
        background-color: var(--orange-100);
      }
      &--custom-field-additional {
        background-color: var(--blue-75);
      }
    }

    &:nth-child(1) {
      background-color: var(--orange-200);
    }
    
    &:nth-child(2) {
      background-color: var(--cyan-100);
    }

    &:nth-child(3) {
      color: var(--dark-blue-75);
      background-color: var(--white);
    }

    &:nth-child(4) {
      color: var(--white);
      background-color: var(--orange-100);
    }

    &:nth-child(5) {
      background-color: var(--blue-75);
    }
  }
}
