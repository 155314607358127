@import '../../../../../scss/global/utils/mixins';

.game-menu-button {
  &.button--text {
    width: 50%;
    display: flex;
    position: relative;
    color: var(--white);
    align-items: flex-start;
    line-height: var(--font-size-20);
    @include icon_small();

    @include respond(phone) {
      font-size: var(--font-size-12);
    }

    &:hover {
      color: var(--white);
      text-decoration: none;
    }
  }

  &.button {
    .button__icon-left {
      height: 100%;
    }
  }

  &--divider {
    &::after {
      content: '';
      right: 0;
      width: 1px;
      height: 20px;
      display: block;
      position: absolute;
      background-color: var(--white);
    }
  }
}
