.account-details {
  &__actions {
    float: right;

    .button__container {
      svg {
        width: var(--size-16);
        height: var(--size-16);
      }
    }
  }
}