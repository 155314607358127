:root {
  --input-icon-btn-focus-outline-width: var(--focus-outline-width);
  --input-icon-btn-focus-outline-offset: var(--focus-outline-offset);
  --input-icon-btn-disabled-opacity: var(--disabled-opacity);
}

.input-icon-button {
  color: inherit;
  border: 0;
  cursor: pointer;
  background-color: transparent;
  font-size: inherit;
  height: 1em;
  width: 1em;

  &:focus {
    outline-offset: var(--input-icon-btn-focus-outline-offset);
    outline: var(--input-icon-btn-focus-outline-width) solid
      var(--focus-outline-color);
  }

  &--disabled {
    opacity: var(--input-icon-btn-disabled-opacity);
  }
}
