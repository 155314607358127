@import '../../../scss/global/utils/media-queries';

:root {
  --react-datepicker-maxwidth: 25rem;
  --react-datepicker-padding: var(--size-5) 0 var(--size-20) 0;
  // month
  --react-datepicker-current-month-background: white;
  // day name container
  --react-datepicker-current-daynames-background: white;
  // day name
  --react-datepicker-current-dayname-color: var(--grey-300);
  // navigation
  --react-datepicker-button-top: 1.375rem;
  // current month
  --react-datepicker-current-mounth-padding: var(--size-20) 0;
  // header
  --react-datepicker-header-background: white;
  --react-datepicker-header-padding: 0;
  // today button config
  --react-datepicker-today-padding: var(--size-5) 0;
  --react-datepicker-today-width-desktop: var(--size-56);
  --react-detepicker-today-width-mobile: var(--size-45);
  // button background colors
  --react-datepicker-today-current-background-color: var(--primary-100);
  --react-datepicker-today-background-color: var(--primary-300);
  --react-datepicker-today-hover-background-color: var(--primary-500);
  // button colors
  --react-datepicker-today-today-color: var(--grey-300);
  --react-datepicker-dropdown-background: #80879a;
  --react-datepicker-dropdown-scrollbar: #bfc3cd;
  // hover border size and color
  --react-datepicker-date-border: 2px solid #e1aefe;

  //sizes
  --size-108: 6.75rem;
  --size-128: 8rem;
  --size-148: 9.25rem;
  --size-168: 10.5rem;
  --size-188: 11.75rem;
  --size-208: 13rem;
  --size-228: 14.25rem;
  --size-240: 15rem;
  --size-248: 15.5rem;
  --size-260: 16.25rem;
  --size-268: 16.75rem;
  --size-280: 17.5rem;
  --size-288: 18rem;
  --size-308: 19.25rem;
  --size-320: 20rem;
  --size-328: 20.5rem;
}

.react-datepicker-input {
  width: 100%;
}

.react-datepicker-wrapper {
  width: 100%;
}

// Prevent picker displaying below input
.react-datepicker-popper {
  z-index: var(--z-index-datepicker-popper);
}

.react-datepicker {
  border: none;
  padding: var(--react-datepicker-padding);
  width: 100%;

  &__date-header-wrapper {
    margin: var(--size-10);
    display: flex;
    justify-content: space-between;

    // Quick fix for arrow margin from common buttons styles
    .button__container {
      .button__icon-left {
        margin: 0;
      }
    }

    .button {
      &--text {
        color: var(--yellow-100);

        &:hover {
          color: var(--yellow-100);
        }
      }
    }
  }

  &__increase-month-btn,
  &__decrease-month-btn {
    margin-top: var(--size-8);

    &--hidden {
      visibility: hidden;
    }

    svg {
      color: var(--sub-nav-pills-color);
    }
  }

  &__increase-month-btn {
    margin-right: var(--size-12);
  }

  &__decrease-month-btn {
    margin-left: var(--size-12);
  }

  &__date-select {
    width: var(--size-80);
    font-weight: var(--text-highlight-weight);
    margin-right: var(--size-12);

    .select {
      width: 100%;
    }

    .select__control {
      display: flex;
    }

    .select-native__select {
      width: 100%;
      min-width: var(--size-80);

      li {
        top: var(--size-80);
        background: var(--primary-800);
        font-weight: var(--text-highlight-weight);
      }
    }

    .select-non-native__input {
      min-width: var(--size-80);
      width: var(--size-80);
      padding-left: var(--size-16);
      border: none;
      border-radius: var(--size-16);
      background-color: var(--sub-nav-pills-color);
      color: var(--white);

      li {
        top: var(--size-80);
        background: var(--yellow-50);
        font-weight: var(--text-highlight-weight);
      }
    }

    .select-non-native__chevron {
      z-index: 1;
      svg {
        color: var(--white);
      }
    }
  }

  &__date-selects-wrapper {
    display: flex;
    position: relative;

    .select-non-native {
      &__input {
        height: var(--size-36);
        width: var(--size-80);

        &:hover {
          color: var(--select-input-color-has-value);
        }
      }

      &__dropdown {
        width: var(--size-96);
        padding: var(--size-4);

        ::-webkit-scrollbar {
          width: var(--size-12);
        }

        ::-webkit-scrollbar-track {
          background: transparent;
        }

        span {
          justify-content: center;
        }

        ::-webkit-scrollbar-thumb {
          background-color: var(--react-datepicker-dropdown-scrollbar);
          border: var(--size-5) solid transparent;
          background-clip: content-box;
        }

        &--bottom {
          top: var(--size-40);
          margin-bottom: 0;
          background-color: var(--body-15);
          border-radius: var(--size-4);

          ul {
            padding: 0 var(--size-4);
          }
        }
      }

      &--dark {
        box-shadow: none;
      }

      &__ul {
        padding: 0;
        max-height: var(--size-240);
      }

      &__li {
        color: var(--white);
        min-height: var(--size-36);
        margin: var(--size-4) auto;
        border-radius: var(--size-16);
        background: var(--sub-nav-pills-color);
        padding: 0 auto;

        &:hover {
          background: var(--body-95);
        }
      }

      &--active-light {
        background: none;
      }
    }
  }

  &__month {
    margin: 0;
    padding-top: var(--size-6);

    &--begin {
      padding-top: var(--size-40);
    }

    &--end {
      padding-bottom: var(--size-40);
    }
  }

  &__month-container {
    width: 100%;

    &,
    input {
      text-transform: capitalize;
    }
  }

  &__header {
    background: var(--react-datepicker-header-background);
    padding: var(--react-datepicker-header-padding);
    border: none;
  }

  &__navigation {
    &--next {
      top: var(--react-datepicker-button-top);
    }
    &--previous {
      top: var(--react-datepicker-button-top);
    }
  }

  &__current-month {
    padding: var(--react-datepicker-current-mounth-padding);
    background: var(--react-datepicker-current-month-background);
  }

  &__day-names {
    background: var(--react-datepicker-current-daynames-background);
  }

  &__day-name {
    padding: var(--react-datepicker-today-padding);
    color: var(--react-datepicker-current-dayname-color);
    width: var(--react-detepicker-today-width-mobile);
    @include respond(tab-port) {
      width: var(--react-datepicker-today-width-desktop);
    }
  }

  &__day {
    font-size: var(--size-14);
    height: var(--size-36);
    padding: var(--react-datepicker-today-padding);
    text-align: center;
    width: var(--react-detepicker-today-width-mobile);

    @include respond(tab-port) {
      width: var(--react-datepicker-today-width-desktop);
    }

    &--excluded {
      opacity: 0.3;
    }

    @include respond(desktop) {
      width: var(--react-datepicker-today-width-desktop);
    }

    &--keyboard-selected,
    &--selected {
      background: var(--react-datepicker-today-today-color);
    }

    &--today {
      background-color: var(--body-5);
      border-radius: var(--size-5);

      &:hover {
        background-color: var(--body-5);
      }
    }

    &--in-range {
      background-color: var(--orange-65);
      border-radius: unset;
      color: var(--black);

      &:hover {
        background-color: var(--body-5);
        border-radius: unset;
      }
    }

    // Fix for incorrect background cells color in the date picker outside selected range
    &--in-selecting-range:not(.react-datepicker__day--in-range) {
      background-color: var(--white);
      color: var(--black);

      &:hover {
        background-color: var(--primary-100);
      }
    }

    &--range-start,
    &--range-start:hover {
      border-radius: var(--size-10) 0 0 var(--size-10);
    }

    &--range-end,
    &--range-end:hover {
      border-radius: 0 var(--size-10) var(--size-10) 0;
    }
  }
}
