@import '../../../../../scss/global/utils/mixins';

.promotions-block {
  padding: 0;

  &__usual {
    background: none;
  }

  &__bonus-history {
    text-decoration: underline;
  }

  &__no-active-info {
    color: var(--white);
  }

  &__empty {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 9.625rem;
    align-items: center;
    height: var(--size-180);

    h4 {
      width: 11.875rem;
    }
  }

  &__cards {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    @include respond(tab-port) {
      width: calc(100% - var(--navigation-tab-port-padding-right));
    }
    @include respond(tab-land) {
      width: 100%;
    }
    @include respond(extra-big-desktop) {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
      grid-column-gap: var(--size-16);
      grid-row-gap: var(--size-16);
    }
    .promotion-card {
      box-shadow: 5px 5px 20px -10px rgba(0, 10, 45, 0.4),
        -5px -5px 20px -10px rgba(0, 10, 45, 0.4);

      @include respond(extra-big-desktop) {
        width: unset;
        flex: unset;
        margin: 0;
      }
    }
  }
}
// Hot fix for first and last promotion's block styles
@include respond(desktop) {
  .navigation__children {
    .aem-GridColumn {
      &:first-child {
        .promotions-block {
          padding-top: 3rem;
        }
      }
    }
    .promotions-block__title {
      font-size: var(--font-size-20);
    }
  }
}
