@import '../../../../../scss/global/utils/mixins';

.cookie-preference-footer {
  &__footer-btn {
    display: flex;
    gap: var(--size-10);
    justify-content: flex-end;

    .button:not(:last-of-type) {
      margin-right: 0;
    }

    &--hidden {
      display: none;
    }

    &--space-between {
      width: 100%;
      justify-content: space-between;
    }
  }

  &__case {
    text-transform: uppercase;
  }
}
