body {
  box-sizing: border-box;
  background-color: var(--primary-900);
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
