:root {
  // component
  --tdl-padding: var(--size-16);
  --tdl-margin: var(--size-16);
  --tdl-more-margin-top: var(--size-16);
  --tdl-background: var(--grey-300);
  // total
  --tdl-total-color: var(--white);
  // morebtn
  --tdl-more-btn-height: var(--size-48);
  --tdl-more-btn-background: var(--white);
  --tdl-more-btn-border-radius: var(--size-10);
}

.tdl {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--tdl-padding);
  background: var(--tdl-background);

  &__heading {
    margin-bottom: var(--tdl-margin);
    color: var(--tdl-total-color);
    font-size: var(--size-12);
  }

  &__total-results {
    color: var(--tdl-total-color);
  }

  &__more-btn {
    &--disabled {
      cursor: not-allowed;
    }

    outline: none;
    border: none;
    width: 100%;
    cursor: pointer;
    border-radius: var(--tdl-more-btn-border-radius);
    height: var(--tdl-more-btn-height);
    background: var(--tdl-more-btn-background);
  }
  &__image-container {
    width: 100%;
    margin: 0 auto;
  }

  &__image {
    width: 100%;
  }
}
