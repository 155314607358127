@import '../../../scss/global/utils/mixins';

:root {
  --field-set-margin: 0 0 var(--size-16);
}

.field-set {
  margin: var(--field-set-margin);
  &__legend {
    @each $var in 1, 2, 3, 4, 5, 6 {
      &--#{$var} {
        @include headingStyles($var);
      }
    }
  }
}
