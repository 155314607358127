@import '../../../scss/global/utils/mixins';

:root {
  --footer-gap: var(--size-16);
  --footer-brand-color: var(--body-60);
  --footer-link-gap: var(--size-24) var(--size-24) 0 0;
  --nav-border: 1px solid rgba(255, 255, 255, 0.2);
  --footer-nav-bg-color-mobile: #e5e5e5;
}

@include respond(phone) {
  body {
    z-index: 2;
    min-height: 100vh;
    position: relative;
    background-attachment: fixed;
  }
}

.footer {
  background-color: var(--black);

  @include respond(phone) {
    overscroll-behavior: none;
    overflow: hidden;
  }

  &__links-container {
    padding: var(--footer-gap);
    padding-bottom: 0;

    &-item {
      padding-bottom: var(--footer-gap);
    }
  }

  h5 {
    color: var(--white);
  }

  position: relative;

  &__regulatory-copy,
  &__copyright {
    padding: var(--footer-gap);
    color: var(--dark-blue-25);
  }

  &__general-bar,
  &__copyright {
    display: flex;
    font-size: var(--size-12);
  }

  &__copyright {
    align-items: baseline;
    flex-direction: column;

    @include respond(tab-port) {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__general-bar {
    color: var(--white);
    align-items: center;

    &--mobile {
      padding: 0;
      flex-wrap: wrap;
      color: var(--white);
    }
  }

  &__language,
  &__time {
    margin: var(--size-16) 0;

    @include respond(desktop) {
      margin: 0;
    }
  }

  &__language {
    padding: 0 var(--footer-gap);
    border-right: var(--nav-border);

    @include respond(desktop) {
      padding-left: 0;
    }
  }

  &__time {
    padding-left: var(--footer-gap);

    @include respond(desktop) {
      border-left: var(--nav-border);
    }

    span {
      padding-left: var(--size-4);
    }
  }

  &__copyright-text,
  &__regulatory-text {
    color: var(--dark-blue-25);
  }

  &__regulatory-text {
    a {
      text-decoration: underline;
      color: var(--dark-blue-25);

      &:hover {
        color: var(--footer-brand-color);
      }
    }
  }
}
