:root {
  // Colors
  --badge-bg-color-default: var(--grey-100);
  --badge-text-color-default: var(--grey-800);
  --badge-bg-color-error: var(--danger-color);
  --badge-text-color-error: white;

  // Font
  --badge-font-size: var(--size-8);
  --badge-font-weight: 600;
  --badge-line-height: var(--size-12);

  // Rectangular vars
  --badge-rectangle-padding: var(--size-2) var(--size-4);
  --badge-rectangle-border-radius: var(--size-2);
}

.badge {
  display: inline-block;
  font-size: var(--badge-font-size);
  line-height: var(--badge-line-height);
  font-weight: var(--badge-font-weight);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;

  &--rectangle {
    padding: var(--badge-rectangle-padding);
    border-radius: var(--badge-rectangle-border-radius);
  }

  &--circle {
    border-radius: 50%;
    width: var(--badge-line-height);
    height: var(--badge-line-height);
  }

  &--color-default {
    background-color: var(--badge-bg-color-default);
    color: var(--badge-text-color-default);
  }

  &--color-error {
    background-color: var(--badge-bg-color-error);
    color: var(--badge-text-color-error);
  }
}
