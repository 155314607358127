@import '../../../scss/global/utils/mixins';

:root {
  --registration-step-actions-margin: var(--size-24) 0 0;
  --registration-step-actions-padding: var(--size-24) 0;
  --registration-step-actions-item-margin: var(--size-10);
  --registration-step-actions-border-top: var(--default-horizontal-rule);
}

.registration-error-step,
.registration-success-step {
  text-align: center;

  .heading.heading--4 {
    color: var(--white);

    @include respond(desktop) {
      color: var(--heading-color);
    }
  }

  &__actions {
    border-top: var(--registration-step-actions-border-top);
    margin: var(--registration-step-actions-margin);
    padding: var(--registration-step-actions-padding);
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * {
      margin: var(--registration-step-actions-item-margin);
    }
  }
}
