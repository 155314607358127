@import '../../../../scss/global/utils/mixins';

.my-account-settings {
  background-color: var(--body-5);

  &__header {
    padding: 0 var(--size-16) var(--size-24);
    background-color: var(--background-account-wrapper);

    h3 {
      color: var(--white);
    }
  }

  &__wrapper {
    border-radius: var(--size-16) var(--size-16) 0 0;
    background-color: var(--primary-900);
    min-height: calc(100% - 100px);
  }

  &__content {
    @include rounded-page-content();

    padding: var(--size-24) var(--size-16);
    width: 100%;
    margin-top: -1rem;
  }

  &__section-heading {
    color: var(--white);
    margin-bottom: var(--size-8);
  }

  &__tile {
    padding-top: var(--size-24);
    padding-bottom: var(--size-24);
    display: flex;
    align-items: center;
  }

  &__tile-icon {
    font-size: var(--font-size-20);
    margin-right: var(--size-8);
    color: var(--body-60);
  }

  .account-details {
    width: 100%;

    &__button-text {
      display: none;
    }
  }

  &__close-heading {
    margin-top: var(--size-24);
    color: var(--body-60);
    margin-bottom: var(--size-8);
  }
  &__account-closure {
    .account-tile {
      margin-bottom: var(--size-8);

      &-list-item__content {
        justify-content: space-between;

        svg {
          font-size: var(--size-12);
        }
      }
    }
  }
}

#marketing-details-fieldset {
  p {
    margin-bottom: var(--size-12);
  }
}
