:root {
  --menu-background-color: var(--white);
  --menu-border-radius: 4px;
  --menu-border: 2px solid var(--grey-200);
  --menu-max-height: var(--size-192);
  --menu-item-padding: var(--size-8) var(--size-12);
  --menu-item-active-background-color: var(--primary-100);
  --menu-item-disabled-opacity: var(--disabled-opacity);
}

.menu {
  &--open {
    max-height: var(--menu-max-height);
    padding: 0;
    z-index: var(--z-index-autocomplete-menu-open);
    background-color: var(--menu-background-color);
    overflow: auto;
    border: var(--menu-border);
    border-radius: var(--menu-border-radius);
  }
}

.menu-item {
  padding: var(--menu-item-padding);
  margin: 0;
  cursor: pointer;

  &--active {
    background-color: var(--menu-item-active-background-color);
  }
  &--disabled {
    opacity: var(--menu-item-disabled-opacity);
    cursor: not-allowed;
  }
}
