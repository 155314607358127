:root {
  --account-details-form-margin: var(--size-16) 0 0;
  --account-details-form-actions-margin: var(--size-24) 0;
  --account-details-form-intro-margin: 0 0 var(--size-24);
}

.account-details-form {
  &__actions {
    display: flex;
    justify-content: space-between;
    margin: var(--account-details-form-actions-margin);
  }

  &__intro-text {
    margin: var(--account-details-form-intro-margin);
  }

  &__form {
    margin: var(--account-details-form-margin);
  }

  .grid:first-of-type {
    margin-top: 0;
  }
}
