@import '../../../../scss/global/utils/media-queries';

:root {
  --video-tile-width-mobile: var(--size-160);
  --video-tile-width-desktop: var(--size-200);
  --video-tile-height-mobile: var(--size-96);
  --video-tile-height-desktop: var(--size-124);
  --video-tile-border-radius: var(--size-5);
  --video-tile-z-index: var(--z-index-game-video-tile);
  --video-tile-play-button-height: 3.719rem;
  --video-tile-play-button-width: 3.719rem;
  --video-tile-play-button-icon-size: var(--size-27);
  --video-tile-play-button-color: var(--white);
  --video-tile-progress-bar-height: var(--size-3);
  --video-tile-progress-bar-color: linear-gradient(
    90deg,
    transparent,
    var(--primary-300),
    transparent
  );
  --video-tile-secondary-color: var(--white);
  --video-tile-secondary-button-hover-background: var(--primary-900);
  --video-tile-secondary-button-hover-color: var(--white);
  --video-tile-secondary-button-width: var(--size-96);
  --video-tile-secondary-button-height: var(--size-20);
  --video-tile-secondary-button-font-size: var(--size-16);
}

.game-video-tile {
  width: var(--video-tile-width-mobile);
  position: relative;
  border-radius: var(--video-tile-border-radius);
  overflow: hidden;

  @include respond(desktop) {
    width: var(--video-tile-width-desktop);
  }

  &__secondary-actions {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    margin-bottom: 11px;
    justify-content: space-between;
    z-index: var(--video-tile-z-index);
    transition: 1s ease-in-out;

    @include respond(mobile) {
      display: none;
    }

    .button {
      color: var(--video-tile-secondary-color);
      height: var(--video-tile-secondary-button-height);
      width: var(--video-tile-secondary-button-width);
      background: transparent;
      border: none;
      font-size: var(--video-tile-secondary-button-font-size);
      font-weight: 700;
      line-height: 15px;
      text-transform: uppercase;

      &:hover {
        background: var(--video-tile-secondary-button-hover-background);
        color: var(--video-tile-secondary-button-hover-color);
      }
    }
  }

  &:hover {
    .clickable-container .no-vid.hidden,
    .game-video-tile__secondary-actions {
      opacity: 1;
    }
  }

  &__button-container {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    height: var(--video-tile-play-button-height);
    width: var(--video-tile-play-button-width);
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.5);
    transition: 1s ease-in-out;
    transform: translate(-50%, -50%);
    cursor: pointer;
    transform-origin: center;
    z-index: var(--video-tile-z-index);

    @include respond(mobile) {
      display: none;
    }

    &:hover {
      transform: translate(-50%, -50%) scale(0.95);
      background: var(--primary-900);
    }

    .play-button {
      color: var(--video-tile-play-button-color);
      height: var(--video-tile-play-button-icon-size);
      width: var(--video-tile-play-button-icon-size);
      // margin applied to adjust offcentre icon
      margin-left: 5px;
    }
  }

  &__progress-bar {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    align-items: center;
    position: absolute;
    top: 0;
    z-index: 100;
    opacity: 1;

    &__hidden {
      opacity: 0;
    }

    .bar-indicator {
      animation: indicatorAnim 3s infinite;
      height: var(--video-tile-progress-bar-height);
      width: 100%;
      background: var(--video-tile-progress-bar-color);
    }

    @keyframes indicatorAnim {
      0% {
        width: 100%;
      }
      50% {
        width: 30%;
      }
      100% {
        width: 100%;
      }
    }
  }
  .clickable-container {
    position: relative;
    height: var(--video-tile-height-mobile);
    width: var(--video-tile-width-mobile);
    border-radius: var(--video-tile-border-radius);
    overflow: hidden;

    @include respond(desktop) {
      height: var(--video-tile-height-desktop);
      width: var(--video-tile-width-desktop);
    }
    .image {
      height: 100%;
      width: 100%;
      position: absolute;
      transition: 1s ease-in-out;
      border-radius: var(--video-tile-border-radius);
      opacity: 0;
      object-fit: cover;
    }

    .video {
      width: 100%;
      height: auto;
      transition: 1s ease-in-out;
      border-radius: var(--video-tile-border-radius);
      overflow: hidden;

      @include respond(mobile) {
        display: none;
      }
    }

    .shown {
      opacity: 1;
    }
    .hidden {
      opacity: 0;
    }
  }
}
