:root {
  --radio-group-label-margin: 0 0 var(--size-4) 0;
  --radio-group-inline-margin: 0 var(--size-16) 0 0;
  --radio-group-margin: 0;
}

.radio-group {
  &__label {
    margin: var(--radio-group-label-margin);
  }

  .radio-group__buttons {
    display: flex;
    flex-direction: column;

    > * {
      margin: var(--radio-group-margin);
    }

    &--inline {
      flex-direction: row;

      > * {
        margin: var(--radio-group-inline-margin);
      }
    }
  }
}
