@import '../../../../scss/global/utils/media-queries';

:root {
  --game-item-border-radius: var(--size-8);
  --game-item-height-m: 10rem;
  --game-item-height-s: 7rem;
  --game-item-transition: all 0.3s;
  --game-item-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
  --game-item-hover-box-shadow: 0 7px 6px 0 rgba(0, 0, 0, 0.5);
  --game-item-transform: translateY(-5px);
  --game-item-overlay-background-color: rgba(0, 0, 0, 0.7);
  --game-item-actions-padding: 0 var(--size-48);
  --game-item-actions-transition: opacity 0.3s;
  --game-item-actions-btn-margin: var(--size-4) 0;
  --game-item-footer-margin: var(--size-10);
  // Info icon link positioning
  --game-item-actions-info-vert: var(--size-4);
  --game-item-actions-info-top: var(--game-item-actions-info-vert);
  --game-item-actions-info-bottom: var(--game-item-actions-info-vert);
  --game-item-actions-info-right: var(--size-8);
  // custom tag styles
  --game-item-tag-featured-color: var(--white);
  --game-item-tag-featured-background-color: var(--primary-500);
  --game-item-tag-featured-border-color: var(--white);
  --game-item-tag-limited-color: var(--white);
  --game-item-tag-limited-background-color: rgba(0, 0, 0, 0.8);
  --game-item-tag-limited-border-color: var(--white);
  --game-item-tag-new-color: var(--white);
  --game-item-tag-new-background-color: rgba(0, 0, 0, 0.8);
  --game-item-tag-new-border-color: var(--white);
  --game-item-tag-unavailable-color: var(--white);
  --game-item-tag-unavailable-background-color: rgba(0, 0, 0, 0.4);
  --game-item-tag-unavailable-border-color: var(--grey-500);
  --game-item-tag-last-chance-color: var(--white);
  --game-item-tag-last-chance-background-color: var(--danger-600);
  --game-item-tag-last-chance-border-color: var(--white);
  // General tag variables
  --game-item-tag-right: var(--size-12);
  --game-item-tag-border-radius: 0 0 4px 4px;
  --game-item-tag-border: 1px solid var(--grey-500);
  --game-item-tag-padding: var(--size-8) var(--size-16);
  --game-item-tag-font-size: var(--font-size-14);
  --game-item-tag-weight: 700;
  --game-item-tag-box-shadow: var(--box-shadow-2);
  --game-item-tag-text-transform: uppercase;
  --game-item-tag-color: var(--grey-200);
  --game-item-tag-background-color: rgba(0, 0, 0, 0.5);
  --game-item-tag-small-font-size: var(--font-size-10);
  --game-item-tag-small-padding: var(--size-4) var(--size-8);
  // Jackpot overlay variables
  --game-item-jackpot-color: var(--white);
  --game-item-jackpot-font-weight: 700;
  --game-item-jackpot-background-color: var(--primary-500);
  --game-item-jackpot-border-radius: 4px;
  --game-item-jackpot-border: 1px solid var(--white);
  --game-item-jackpot-box-shadow: 1px solid var(--white);
  --game-item-jackpot-padding: var(--size-8) var(--size-10);
  --game-item-jackpot-horizontal-margin: var(--size-24);
  --game-item-jackpot-small-font-size: var(--font-size-10);
  --game-item-jackpot-small-padding: var(--size-4) var(--size-8);
}

.game-item {
  overflow: visible;

  &__overlay,
  &__mobile-link,
  &__actions {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__jackpot {
    position: absolute;
    bottom: 0;
    left: var(--game-item-jackpot-horizontal-margin);
    right: var(--game-item-jackpot-horizontal-margin);
    padding: var(--game-item-jackpot-padding);
    text-align: center;
    color: var(--game-item-jackpot-color);
    font-weight: var(--game-item-jackpot-font-weight);
    background-color: var(--game-item-jackpot-background-color);
    border-top-left-radius: var(--game-item-jackpot-border-radius);
    border-top-right-radius: var(--game-item-jackpot-border-radius);
    border: var(--game-item-jackpot-border);
    border-bottom: none;
    box-shadow: var(--game-item-jackpot-box-shadow);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__game-tag {
    position: absolute;
    right: var(--game-item-tag-right);
    top: 0;
    border-radius: var(--game-item-tag-border-radius);
    border: var(--game-item-tag-border);
    border-top: none;
    background-color: var(--game-item-tag-background-color);
    color: var(--game-item-tag-color);
    padding: var(--game-item-tag-padding);
    text-transform: var(--game-item-tag-text-transform);
    font-size: var(--game-item-tag-font-size);
    font-weight: var(--game-item-tag-weight);
    box-shadow: var(--game-item-tag-box-shadow);

    &--featured {
      color: var(--game-item-tag-featured-color);
      background-color: var(--game-item-tag-featured-background-color);
      border-color: var(--game-item-tag-featured-border-color);
    }

    &--last-chance {
      color: var(--game-item-tag-last-chance-color);
      background-color: var(--game-item-tag-last-chance-background-color);
      border-color: var(--game-item-tag-last-chance-border-color);
    }

    &--limited {
      color: var(--game-item-tag-limited-color);
      background-color: var(--game-item-tag-limited-background-color);
      border-color: var(--game-item-tag-limited-border-color);
    }

    &--new {
      color: var(--game-item-tag-new-color);
      background-color: var(--game-item-tag-new-background-color);
      border-color: var(--game-item-tag-new-border-color);
    }

    &--unavailable {
      color: var(--game-item-tag-unavailable-color);
      background-color: var(--game-item-tag-unavailable-background-color);
      border-color: var(--game-item-tag-unavailable-border-color);
    }
  }

  &__mobile-link {
    display: block;

    @include respond(desktop) {
      display: none;
    }

    &--desktop {
      @include respond(desktop) {
        display: block;
      }
    }
  }

  &__overlay {
    display: none;

    @include respond(desktop) {
      display: block;
    }
  }

  &__content {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: var(--game-item-border-radius);
    box-shadow: var(--game-item-box-shadow);
    height: var(--game-item-height-m);
    overflow: visible;
    transition: var(--game-item-transition);

    &:hover {
      box-shadow: var(--game-item-hover-box-shadow);
      transform: var(--game-item-transform);
    }
  }

  &__actions {
    border-radius: var(--game-item-border-radius);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: var(--game-item-actions-padding);
    background-color: var(--game-item-overlay-background-color);
    opacity: 0;
    transition: var(--game-item-actions-transition);

    > * {
      margin: var(--game-item-actions-btn-margin);
    }

    @include respond(tab-land) {
      &:hover {
        opacity: 1;
      }

      &:focus-within,
      &:active {
        opacity: 1;
      }
    }
  }

  &__footer {
    display: none;
    margin-top: var(--game-item-footer-margin);

    @include respond(desktop) {
      display: block;
    }
  }

  &__footer-link--disabled {
    cursor: text;
    color: var(--grey-500);
    text-decoration: none;
  }

  &__actions-info {
    position: absolute;
    right: var(--game-item-actions-info-right);
    // TODO: Perhaps we need an IconLink component
    color: var(--btn-primary-color);
    font-size: var(--font-size-24);
    margin: 0;

    &--top {
      top: var(--game-item-actions-info-top);
    }

    &--bottom {
      bottom: var(--game-item-actions-info-bottom);
    }
  }

  &--size-small {
    .game-item__content {
      height: var(--game-item-height-s);
    }

    .game-item__actions-info {
      font-size: var(--font-size-16);
    }

    .game-item__actions {
      padding: 0 var(--size-16);

      .button {
        transform: scale(0.8);
      }
    }

    .game-item__jackpot {
      font-size: var(--game-item-jackpot-small-font-size);
      padding: var(--game-item-jackpot-small-padding);
      font-weight: 500;
    }

    .game-item__game-tag {
      font-size: var(--game-item-tag-small-font-size);
      padding: var(--game-item-tag-small-padding);
      font-weight: 500;
    }
  }

  &:hover,
  &:focus-within {
    .game-item__game-tag,
    .game-item__jackpot {
      display: none;
    }
  }
}
