.footer-links-list {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  padding: var(--size-8) 0;
  color: var(--dark-blue-25);

  &__item {
    margin-right: var(--size-16);

    .link {
      display: flex;
      gap: var(--size-6);
      align-items: center;
      color: var(--white);
      text-decoration: none;
      padding: var(--size-8) 0;
      font-size: var(--size-12);
      font-weight: var(--text-highlight-weight-normal);
    }

    img {
      height: var(--size-28);
      max-width: var(--size-168);
    }

    &--disabled {
      pointer-events: none;
    }
  }
}
