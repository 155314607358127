.cookie-preference-control {
  &__links {
    display: flex;
    padding: var(--size-16) 0;
  }

  &__link,
  &__categories,
  &__description,
  &__explanation {
    font-size: var(--size-12);
    color: var(--grey-050);
  }

  &__link {
    text-decoration: none;
    color: var(--body-80);
    margin-right: var(--size-32);
    svg {
      fill: var(--white);
    }

    > * {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__link-name {
    color: var(--grey-050);
    margin-left: 0.2875rem;
  }

  &__head-button {
    margin: var(--size-24) 0;
  }

  &__categories {
    background-color: var(--grey-1000);
  }

  &__categories-header {
    font-size: var(--font-size-14);
    padding-top: var(--size-24);
  }

  &--busy {
    opacity: 0.5;
  }

  &__select-all {
    background: var(--body-5);
    padding: var(--size-16);
    margin: var(--size-24) 0;
    border-radius: var(--size-10);
  }

  &__split {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-weight: var(--paragraph-highlight-font-weight);
  }

  &__section {
    margin-top: var(--size-36);
  }

  &__section-content {
    margin-top: var(--size-16);
    color: var(--grey-050);

    .button {
      color: var(--btn-text-color);
    }
  }

  &__case {
    text-transform: uppercase;
  }
}
