@import '../../../../scss/global/utils/media-queries';

.snackbar {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: var(--z-index-snackbar);

  @include respond(tab-port) {
    width: calc((100% / 8) * 6);
  }

  @include respond(desktop) {
    width: calc((100% / 12) * 6);
  }

  @include respond(extra-big-desktop) {
    width: calc((100% / 24) * 8);
  }
}
