.game-info-card {
  background-color: var(--white);
  border-radius: var(--size-8);
  padding: var(--size-24);

  &__heading {
    margin: 0 0 var(--size-16) 0;
  }
  
  .accordion-header {
    background: none;
    border-bottom: 1px var(--body-5) solid;
  }
}
