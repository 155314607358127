.doc-uploader__modal {
 .modal__container {
   padding-bottom: 0 !important;
 }
  .modal-body__content {
    &:has(.spinner-wrapper) {
      display: flex;
      align-items: center;
    }
    padding: 0 !important;
    iframe {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }
  iframe {
    min-height: 515px;
    width: 100%;
    height: 60vh;
  }
  .touch-drawer {
    &__content {
      padding: 0;
      height: auto;
    }
    &__viewport {
      padding: 0;
    }
  }
  .doc-uploader__complete {
    padding: 24px;
    .button__container {
      text-decoration: underline;
    }
  }
  .spinner-wrapper {
    width: 100%;
    height: 100%;
  }
}

.address-verification-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.spinner-wrapper--overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}