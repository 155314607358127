.kambi-odds-format {
  &__label {
    font-weight: var(--paragraph-font-weight);
    font-size: var(--font-size-10);
    margin-bottom: var(--size-16);
    color: var(--body-80);
  }

  .radio {
    &:not(:last-child) {
      margin-bottom: var(--size-16);
    }
  }
  
}
